/** @format */

import React, { useState, useEffect } from "react";
import { GrPrevious, GrNext } from "react-icons/gr";
import usePermissions from "../../../components/permissions/permission";
import { Link, useNavigate } from "react-router-dom";

const View_Branch = () => {
  const { hasPermission } = usePermissions();
  const navigate = useNavigate();
  const [branch, setBranch] = useState([]);
  const [fetchKey, setFetchKey] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [branch_name, setBranch_name] = useState("");
  const [page, setPage] = useState("");
  const [page1, setPage1] = useState(1); // Default to page 1
  const [limit, setLimit] = useState(40); // Default to limit of 10
  const { REACT_APP_API_ENDPOINT } = process.env;

  const filteredData = branch.filter((branch) =>
    branch.branch_name.toLowerCase().includes(branch_name.toLowerCase())
  );

  // Define a function to fetch data
  const fetchDefaultData = async () => {
    const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/branch/all/Branch/ACTIVE`;
    try {
      const response = await fetch(endpoint);
      if (response.ok) {
        const data = await response.json();
        setBranch(data);
        // console.log("item data:", data.items);
      } else {
        const errorData = await response.json();
        console.error("Failed to fetch branch data:", errorData);
      }
    } catch (error) {
      console.error("Error occurred while fetching branch data:", error);
    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, [fetchKey]);

  // const fetchSearchData = async () => {
  //   const endpoint = `${REACT_APP_API_ENDPOINT}/v1/inventory/item/search?search=${encodeURIComponent(
  //     branch_name
  //   )}&page=${page1}`;
  //   try {
  //     const response = await fetch(endpoint);
  //     if (response.ok) {
  //       const data = await response.json();
  //       setItemData(data.items);
  //       setPage(data.totalPages);
  //       // console.log("item data:", data.items);
  //     } else {
  //       const errorData = await response.json();
  //       console.error("Failed to fetch items data:", errorData);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred while fetching items data:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchSearchData();
  // }, [item_name]);

  const handleDownload = (filePath) => {
    if (!filePath) {
      alert("No file available for download.");
      return;
    }

    const downloadUrl = `${REACT_APP_API_ENDPOINT}/v1/hris/employees/downloadfile?file_path=${encodeURIComponent(
      filePath
    )}`;
    window.location.href = downloadUrl;
  };
  const HandleOnClick = (branch) => {
    // Navigate to the new page and pass the object
    navigate("/branches/update", {
      state: { selectedRecord: branch },
    });
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div>
        <p className="font-sans text-[#797C80] text-[32px] ml-6 mt-6 font-bold">
          View Branch
        </p>
      </div>

      <div className="mt-6">
        <div>
          <div className="ml-6 mt-4">
            <div className="flex flex-col font-poppins font-bold">
              <form className="form relative mb-4">
                <input
                  type="text"
                  required=""
                  placeholder="Search Branch Name"
                  className="input rounded-full w-[300px] h-10 px-8 py-3 border-2 border-transparent focus:outline-none focus:border-blue-500 placeholder-gray-400"
                  value={branch_name}
                  onChange={(e) => setBranch_name(e.target.value)}
                />
              </form>
            </div>
          </div>

          <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl">
            <table className="table-auto border-none w-[95%]">
              <thead>
                <tr className="bg-white">
                  <th className="text-primary justify-center text-[15px] font-bold py-4">
                    Branch ID
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Branch Name
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Branch Address
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Branch Description
                  </th>
                  <th className="text-primary text-[15px] font-bold py-4">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((branch) => (
                  <tr key={branch._id} className="bg-white text-center">
                    <td className="py-4">{branch.branch_id}</td>
                    <td className="py-4">{branch.branch_name}</td>
                    <td className="py-4">{branch.branch_address}</td>
                    <td className="py-4">{branch.branch_description}</td>
                    <td className="py-4">
                      {hasPermission(1511) && (
                        <button
                          onClick={() => HandleOnClick(branch)}
                          className="bg-primary rounded-[10px] p-2 text-white"
                        >
                          Edit
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View_Branch;
