/** @format */

import React, { useEffect, useState } from "react";
import axios from "axios";
import ViewMore from "./viewmore";
import Cookies from "js-cookie";
import moment from "moment";
import { GrPrevious, GrNext } from "react-icons/gr";
import Papa from "papaparse";
import usePermissions from "../../../components/permissions/permission";

export default function InventoryExpireItem() {
  const { hasPermission } = usePermissions();

  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString(); // Format the date to ISO string

  //get all available items
  const [AvailableItems, setAvailableItems] = useState([]);
  const [TotalAvailableItems, setTotalAvailableItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [viewMoreData, setViewMoreData] = useState(null);
  const [viewMoreOpen, setViewMoreOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [itemCategoryData, setItemCategoryData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10000);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  const fetchAdjustments = async (startDate, endDate) => {
    setLoading(true);
    try {
      const url = new URL(
        `${REACT_APP_API_ENDPOINT}/v1/inventory/Dashboard/stock-expires-date`
      );
      url.searchParams.append("page", 1); // Adjust page number as needed
      url.searchParams.append("pageSize", 1000); // Adjust pageSize as needed
      url.searchParams.append("start_date", startDate);
      url.searchParams.append("end_date", endDate);

      const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Fetched data:", data); // Confirm the structure here
      setTotalAvailableItems(data.items); // This is where the items are set
    } catch (error) {
      console.error("Failed to fetch adjustments:", error);
      setTotalAvailableItems([]); // Set an empty array on error
    } finally {
      setLoading(false);
    }
  };

  const SearchByDateRange = () => {
    const startDate =
      dateStart || moment().startOf("month").format("YYYY-MM-DD");
    const endDate = dateEnd || moment().endOf("month").format("YYYY-MM-DD");

    fetchAdjustments(startDate, endDate);
  };

  useEffect(() => {
    SearchByDateRange();
  }, []);

  const handleViewMore = (inventory) => {
    setViewMoreData(inventory);
    setViewMoreOpen(true);
  };
  // Close dialog
  const closeDialog = () => {
    setViewMoreData("");
    setViewMoreOpen(false);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const filteredItems = TotalAvailableItems.filter((item) => {
    const searchMatch =
      !searchQuery ||
      item.item_name?.toLowerCase().includes(searchQuery.toLowerCase());

    return searchMatch;
  });
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  console.log("Current Items:", currentItems);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const ExcelExport = () => {
  //   const csvData = currentItems.map((inventory) => ({
  //     "Item ID": inventory.item_id,
  //     "Item Name": inventory.item_name,
  //     "Item Measure Unit": inventory.item_measure_unit,
  //     "Item Category ID": inventory.item_category_id,
  //     "Available Qty": inventory.total_shadow_qty,
  //     "Item Batch ID": inventory.inventory_batch_id,
  //     "Item Name": inventory.inventory_item_description,
  //     "Current Qty": inventory.current_qty,
  //     "Manufacture Date": inventory.manufacture_date,
  //     "Expiration Date": inventory.expiration_date,
  //     Store: inventory.store_id,
  //     Location: inventory.location,
  //   }));

  //   const csv = Papa.unparse(csvData);

  //   const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   const link = document.createElement("a");
  //   const url = URL.createObjectURL(blob);
  //   link.setAttribute("href", url);
  //   link.setAttribute("download", "InventoryExpireItems.csv");
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  const ExcelExport = () => {
    const csvData = currentItems.map((inventory) => ({
      "Item ID": inventory.item_id,
      "Item Name": inventory.item_name,
      "Item Measure Unit": inventory.item_measure_unit,
      "Item Category ID": inventory.item_category_id,
      "Available Qty": inventory.total_shadow_qty,
      "Item Batch ID": inventory.inventory_batch_id,
      "Item Name": inventory.inventory_item_description,
      "Current Qty": inventory.current_qty,
      "Manufacture Date": inventory.manufacture_date,
      "Expiration Date": inventory.expiration_date,
      Store: inventory.store_id,
      Location: inventory.location,
    }));

    const csv = Papa.unparse(csvData);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "InventoryExpireItems.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-10 bg-background">
      <p className="header-1">Inventory Expire Items</p>

      <div className="w-full flex justify-start items-center mt-10 gap-x-5">
        <div className="w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">date start :</p>
          <input
            type="date"
            className="w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center"
            value={dateStart}
            onChange={(e) => setDateStart(e.target.value)}
          />
        </div>
        <div className="w-[500px] h-[82px] flex justify-between items-center  bg-[#F3F8FF] px-10 rounded-[46px] border border-[#00000036]">
          <p className="label-1">date end :</p>
          <input
            type="date"
            className="w-[250px] h-[50px] rounded-[46px] border border-[#00000036] text-center"
            value={dateEnd}
            onChange={(e) => setDateEnd(e.target.value)}
          />
        </div>
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 p2 text-white"
          onClick={SearchByDateRange}
        >
          Search
        </button>
      </div>

      <div className="flex justify-between items-center mt-[50px]">
        <div>
          <div className="flex items-center gap-5">
            <div className="ml-6 mt-3">
              <input
                className="w-[480px] h-[55px] rounded-[22px] pl-5 "
                placeholder="Search By Item Name"
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </div>

            <button
              className="w-[200px] h-[45px] flex items-center justify-center bg-[#5B6D49] border rounded-[23px] px-4 p2 text-white mt-3"
              onClick={ExcelExport}
            >
              Export CSV
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col font-sans font-bold ml-6 mt-6 rounded-xl item">
        <table className="table-auto border-none w-[95%] ">
          <thead>
            <tr className="bg-white">
              <th className="text-primary px-4 py-2">Item ID</th>
              <th className="text-primary px-4 py-2">Item Name</th>
              <th className="text-primary px-4 py-2">Item Measure Unit</th>
              <th className="text-primary px-4 py-2">Item Category ID</th>
              <th className="text-primary px-4 py-2">Available Qty</th>
              <th className="text-primary px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* Error state */}

            {/* No data state */}
            {Array.isArray(currentItems) && currentItems.length === 0 && (
              <tr>
                <td colSpan="6" className="bg-white px-4 py-2 text-center">
                  No data available
                </td>
              </tr>
            )}

            {/* Render data */}
            {Array.isArray(currentItems) &&
              currentItems.map((inventory) => (
                <tr key={inventory.item_id} className="">
                  <td className="bg-white px-4 py-2 text-center">
                    {inventory.item_id}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {inventory.item_name}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {inventory.item_measure_unit}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {inventory.item_category_id}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    {inventory.total_shadow_qty}
                  </td>
                  <td className="bg-white px-4 py-2 text-center">
                    <button
                      className="text-primary hover:underline"
                      onClick={() => handleViewMore(inventory)}
                    >
                      View More
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div className="mb-4 mt-4">
        <div
          className="flex justify-center mt-4"
          style={{ maxWidth: "80%", margin: "0 auto" }}
        >
          {/* Previous Button */}
          <button
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrPrevious className="w-3 h-3" />
          </button>

          {/* First Page */}
          {currentPage > 3 && (
            <>
              <button
                onClick={() => paginate(1)}
                className={`${
                  currentPage === 1 ? "bg-primary text-white" : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                1
              </button>
              <span className="px-2 py-2">...</span>{" "}
              {/* Ellipsis before current page group */}
            </>
          )}

          {/* Dynamic Page Numbers */}
          {Array.from({
            length: Math.min(
              Math.ceil(TotalAvailableItems.length / itemsPerPage),
              5 // Adjust this number to control how many pages to show around the current page
            ),
          }).map((_, index) => {
            const pageIndex = currentPage - 2 + index;
            if (
              pageIndex < 1 ||
              pageIndex > Math.ceil(TotalAvailableItems.length / itemsPerPage)
            ) {
              return null;
            }
            return (
              <button
                key={pageIndex}
                onClick={() => paginate(pageIndex)}
                className={`${
                  currentPage === pageIndex
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {pageIndex}
              </button>
            );
          })}

          {/* Last Page */}
          {currentPage <
            Math.ceil(TotalAvailableItems.length / itemsPerPage) - 2 && (
            <>
              <span className="px-2 py-2">...</span>{" "}
              {/* Ellipsis after current page group */}
              <button
                onClick={() =>
                  paginate(Math.ceil(TotalAvailableItems.length / itemsPerPage))
                }
                className={`${
                  currentPage ===
                  Math.ceil(TotalAvailableItems.length / itemsPerPage)
                    ? "bg-primary text-white"
                    : "bg-white"
                } px-4 py-2 mx-1 rounded-md`}
              >
                {Math.ceil(TotalAvailableItems.length / itemsPerPage)}
              </button>
            </>
          )}

          {/* Next Button */}
          <button
            onClick={() => paginate(currentPage + 1)}
            disabled={
              currentPage ===
              Math.ceil(TotalAvailableItems.length / itemsPerPage)
            }
            className="bg-primary text-white px-4 py-2 mx-1 rounded-md"
          >
            <GrNext className="w-3 h-3" />
          </button>
        </div>
      </div>

      {viewMoreOpen && (
        <ViewMore
          onClose={closeDialog} // Pass the dispose_items as a prop
          batchItems={viewMoreData}
        />
      )}
    </div>
  );
}
