/** @format */

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const VehicleFleetPermission = ({ onPermissionsChange, userPermissions }) => {
  // const currentDate = moment().format("MMMM Do YYYY");
  // const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));

  const [isVisible, setIsVisible] = useState(false);
  const [checkedValues, setCheckedValues] = useState({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (userPermissions && userPermissions.length > 0) {
      const initialCheckedValues = userPermissions.reduce((acc, curr) => {
        acc[String(curr)] = true;
        return acc;
      }, {});
      setCheckedValues(initialCheckedValues);
      console.log("Initialized checked values:", initialCheckedValues);
    }
  }, [userPermissions]);

  const permissionHierarchy = {
    22: { children: [500, 505, 510, 515, 520, 525, 530, 535, 540, 545, 550] },
    500: { children: [5100, 5105, 5110] },
    505: { children: [5115, 5120] },
    510: { children: [5125, 5130] },
    515: { children: [5135, 5140, 5145] },
    520: { children: [5150, 5155, 5160] },
    525: { children: [5165, 5170, 5175] },
    530: { children: [5180] },
    535: { children: [5185] },
    540: { children: [5190] },
    545: { children: [5195] },
    550: { children: [5200] },
    5100: { children: [7000, 7005, 7010, 7015, 7020] },
    5105: { children: [7030, 7035, 7040, 7045, 7050] },
    5110: { children: [7060, 7065, 7070, 7075, 7080] },
    5115: { children: [7090, 7095] },
    5120: { children: [7100, 7110] },
    5125: { children: [7120, 7135, 7140, 7145] },
    5130: { children: [7150, 7165, 7170, 7175] },
    5135: { children: [7185, 7190, 7195] },
    5140: { children: [7205, 7210, 7215] },
    // 5145: { children: [] },
    5150: { children: [7230, 7235, 7240, 7245] },
    5155: { children: [7250, 7255, 7260, 7265, 7270] },
    5160: { children: [7280, 7285, 7290, 7295, 7300] },
    // 5165: { children: [] },
    // 5170: { children: [] },
    5175: { children: [7305, 7310, 7315, 7320] },
    // 5180: { children: [] },
    5185: { children: [7325] },
    5190: { children: [7330, 7335, 7340] },
    5195: { children: [7345, 7350, 7355] },
    5200: { children: [9540] },
    7330: { children: [8500, 8505] },
    7335: { children: [9510] },
    7340: { children: [9515] },
    7345: { children: [8510, 8515] },
    7350: { children: [9530] },
    7355: { children: [9535] },
  };

  const handleCheckboxChange = (value, dependentValue) => {
    setCheckedValues((prev) => {
      const newValues = { ...prev, [value]: !prev[value] };

      if (dependentValue && !prev[dependentValue]) {
        newValues[dependentValue] = true;
      }

      if (newValues[value]) {
        Object.keys(permissionHierarchy).forEach((key) => {
          if (permissionHierarchy[key]?.children.includes(value)) {
            newValues[key] = true;
          }
        });
      }

      if (permissionHierarchy[value] && newValues[value]) {
        permissionHierarchy[value].children.forEach((child) => {
          newValues[child] = true;
        });
      }
      return newValues;
    });
  };

  const updatePermissions = useCallback(() => {
    const selectedPermissions = Object.keys(checkedValues)
      .filter((key) => checkedValues[key])
      .map(Number);

    // Ensure Fleet permission ID (22) is included if any HRIS permission is selected
    if (
      selectedPermissions.some((perm) =>
        permissionHierarchy[22].children.includes(perm)
      ) &&
      !selectedPermissions.includes(22)
    ) {
      selectedPermissions.push(22);
    }

    onPermissionsChange(selectedPermissions);
  }, [checkedValues, onPermissionsChange]);

  useEffect(() => {
    updatePermissions();
  }, [checkedValues, updatePermissions]);

  return (
    <div>
      <div>
        <div className="">
          <p className="font-bold text-[20px] ml-[20%] mt-4 underline">
            Vehicle Fleet
          </p>
          <div className="ml-[20%]">
            <button
              className="bg-blue-500 p-3 mt-5 text-white font-semibold rounded-[30px]"
              onClick={toggleVisibility}
            >
              {isVisible ? "Hide" : "Show"} Details
            </button>
          </div>
        </div>
        <div>
          {isVisible && (
            <div className="ml-[20%] mt-5 grid grid-cols-3 gap-10">
              <div>
                <p className="bg-gray-200 rounded-[20px] p-5 font-bold text-[15px] text-black">
                  Vehicle Information Management
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="500"
                      checked={!!checkedValues[500]}
                      onChange={() => handleCheckboxChange(500)}
                    />
                    Vehicle Information Management
                  </label>

                  <div className="ml-5 mt-2">
                    <label className="block mb-2 text-blue-500">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="5100"
                        checked={!!checkedValues[5100]}
                        onChange={() => handleCheckboxChange(5100)}
                      />
                      Vehicle Details
                    </label>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7000"
                            checked={!!checkedValues[7000]}
                            onChange={() => handleCheckboxChange(7000)}
                          />
                          pdf export
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7005"
                            checked={!!checkedValues[7005]}
                            onChange={() => handleCheckboxChange(7005)}
                          />
                          Excel export
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7010"
                            checked={!!checkedValues[7010]}
                            onChange={() => handleCheckboxChange(7010, 5100)}
                          />
                          Add vehicle
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7015"
                            checked={!!checkedValues[7015]}
                            onChange={() => handleCheckboxChange(7015, 5100)}
                          />
                          Edit
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7020"
                            checked={!!checkedValues[7020]}
                            onChange={() => handleCheckboxChange(7020, 5100)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>
                    <label className="block mb-2 text-blue-500">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="5105"
                        checked={!!checkedValues[5105]}
                        onChange={() => handleCheckboxChange(5105)}
                      />
                      Vehicle Part Details
                    </label>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7030"
                            checked={!!checkedValues[7030]}
                            onChange={() => handleCheckboxChange(7030, 5105)}
                          />
                          pdf export
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7035"
                            checked={!!checkedValues[7035]}
                            onChange={() => handleCheckboxChange(7035, 5105)}
                          />
                          Excel export
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7040"
                            checked={!!checkedValues[7040]}
                            onChange={() => handleCheckboxChange(7040, 5105)}
                          />
                          Add vehicle part
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7045"
                            checked={!!checkedValues[7045]}
                            onChange={() => handleCheckboxChange(7045, 5105)}
                          />
                          Edit
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7050"
                            checked={!!checkedValues[7050]}
                            onChange={() => handleCheckboxChange(7050, 5105)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>

                    <label className="block mb-2 text-blue-500">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="5110"
                        checked={!!checkedValues[5110]}
                        onChange={() => handleCheckboxChange(5110)}
                      />
                      driver Details
                    </label>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7060"
                            checked={!!checkedValues[7060]}
                            onChange={() => handleCheckboxChange(7060, 5110)}
                          />
                          pdf export
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7065"
                            checked={!!checkedValues[7065]}
                            onChange={() => handleCheckboxChange(7065, 5110)}
                          />
                          Excel export
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7070"
                            checked={!!checkedValues[7070]}
                            onChange={() => handleCheckboxChange(7070, 5110)}
                          />
                          Add driver
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7075"
                            checked={!!checkedValues[7075]}
                            onChange={() => handleCheckboxChange(7075, 5110)}
                          />
                          Edit
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7080"
                            checked={!!checkedValues[7080]}
                            onChange={() => handleCheckboxChange(7080, 5110)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  current vehicle dispatches
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5115"
                      checked={!!checkedValues[5115]}
                      onChange={() => handleCheckboxChange(5115, 505)}
                    />
                    Gate Pass Request Dispatch
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7090"
                          checked={!!checkedValues[7090]}
                          onChange={() => handleCheckboxChange(7090, 5115)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7095"
                          checked={!!checkedValues[7095]}
                          onChange={() => handleCheckboxChange(7095, 5115)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5120"
                      checked={!!checkedValues[5120]}
                      onChange={() => handleCheckboxChange(5120, 505)}
                    />
                    manager transport dispatch
                  </label>

                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7100"
                          checked={!!checkedValues[7100]}
                          onChange={() => handleCheckboxChange(7100, 5120)}
                        />
                        pdf export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7110"
                          checked={!!checkedValues[7110]}
                          onChange={() => handleCheckboxChange(7110, 5120)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Route Planning and Optimization
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5125"
                      checked={!!checkedValues[5125]}
                      onChange={() => handleCheckboxChange(5125, 510)}
                    />
                    Manager Transportation Schedule
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7120"
                          checked={!!checkedValues[7120]}
                          onChange={() => handleCheckboxChange(7120, 5125)}
                        />
                        Add Transportation Schedule
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7135"
                          checked={!!checkedValues[7135]}
                          onChange={() => handleCheckboxChange(7135, 5125)}
                        />
                        Excel export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7140"
                          checked={!!checkedValues[7140]}
                          onChange={() => handleCheckboxChange(7140, 5125)}
                        />
                        Edit
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7145"
                          checked={!!checkedValues[7145]}
                          onChange={() => handleCheckboxChange(7145, 5125)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5130"
                      checked={!!checkedValues[5130]}
                      onChange={() => handleCheckboxChange(5130, 510)}
                    />
                    create Gate Pass Request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7150"
                          checked={!!checkedValues[7150]}
                          onChange={() => handleCheckboxChange(7150, 5130)}
                        />
                        Add gate pass request
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7165"
                          checked={!!checkedValues[7165]}
                          onChange={() => handleCheckboxChange(7165, 5130)}
                        />
                        Excel export
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7170"
                          checked={!!checkedValues[7170]}
                          onChange={() => handleCheckboxChange(7170, 5130)}
                        />
                        Edit
                      </label>
                    </div>

                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7175"
                          checked={!!checkedValues[7175]}
                          onChange={() => handleCheckboxChange(7175, 5130)}
                        />
                        Delete
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Fuel Management
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5135"
                      checked={!!checkedValues[5135]}
                      onChange={() => handleCheckboxChange(5135, 515)}
                    />
                    Fuel Consumption data
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7185"
                            checked={!!checkedValues[7185]}
                            onChange={() => handleCheckboxChange(7185, 5135)}
                          />
                          Add Fuel consumption data
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7190"
                            checked={!!checkedValues[7190]}
                            onChange={() => handleCheckboxChange(7190, 5135)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7195"
                            checked={!!checkedValues[7195]}
                            onChange={() => handleCheckboxChange(7195, 5135)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5140"
                      checked={!!checkedValues[5140]}
                      onChange={() => handleCheckboxChange(5140, 515)}
                    />
                    Fuel Cost update
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7205"
                            checked={!!checkedValues[7205]}
                            onChange={() => handleCheckboxChange(7205, 5140)}
                          />
                          Add Fuel Cost update
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7210"
                            checked={!!checkedValues[7210]}
                            onChange={() => handleCheckboxChange(7210, 5140)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7215"
                            checked={!!checkedValues[7215]}
                            onChange={() => handleCheckboxChange(7215, 5140)}
                          />
                          Delete
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5145"
                      checked={!!checkedValues[5145]}
                      onChange={() => handleCheckboxChange(5145, 515)}
                    />
                    generate monthly fuel reports
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Maintenance and repairs
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5150"
                      checked={!!checkedValues[5150]}
                      onChange={() => handleCheckboxChange(5150, 520)}
                    />
                    Upload Request Letter Form
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7235"
                            checked={!!checkedValues[7235]}
                            onChange={() => handleCheckboxChange(7235, 5150)}
                          />
                          Add request letter form
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7240"
                            checked={!!checkedValues[7240]}
                            onChange={() => handleCheckboxChange(7240, 5150)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7245"
                            checked={!!checkedValues[7245]}
                            onChange={() => handleCheckboxChange(7245, 5150)}
                          />
                          Delete
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7230"
                            checked={!!checkedValues[7230]}
                            onChange={() => handleCheckboxChange(7230, 5150)}
                          />
                          Export Excel
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5155"
                      checked={!!checkedValues[5155]}
                      onChange={() => handleCheckboxChange(5155, 520)}
                    />
                    Maintenance Records
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7265"
                            checked={!!checkedValues[7265]}
                            onChange={() => handleCheckboxChange(7265, 5155)}
                          />
                          Add maintenance records
                        </label>
                      </div>
                    </div>

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7270"
                            checked={!!checkedValues[7270]}
                            onChange={() => handleCheckboxChange(7270, 5155)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7250"
                            checked={!!checkedValues[7250]}
                            onChange={() => handleCheckboxChange(7250, 5155)}
                          />
                          Delete
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7260"
                            checked={!!checkedValues[7260]}
                            onChange={() => handleCheckboxChange(7260, 5155)}
                          />
                          Export Excel
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7255"
                            checked={!!checkedValues[7255]}
                            onChange={() => handleCheckboxChange(7255, 5155)}
                          />
                          Export PDF
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5160"
                      checked={!!checkedValues[5160]}
                      onChange={() => handleCheckboxChange(5160, 520)}
                    />
                    Breakdown Records
                  </label>

                  <div className="ml-5 mt-2">
                    {/* <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7265"
                            checked={!!checkedValues[7265]}
                            onChange={() => handleCheckboxChange(7265, 5160)}
                          />
                          Add breakdown records
                        </label>
                      </div>
                    </div> */}

                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7290"
                            checked={!!checkedValues[7290]}
                            onChange={() => handleCheckboxChange(7290, 5160)}
                          />
                          Add Breakdown Records
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7295"
                            checked={!!checkedValues[7295]}
                            onChange={() => handleCheckboxChange(7295, 5160)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7300"
                            checked={!!checkedValues[7300]}
                            onChange={() => handleCheckboxChange(7300, 5160)}
                          />
                          Delete
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7285"
                            checked={!!checkedValues[7285]}
                            onChange={() => handleCheckboxChange(7285, 5160)}
                          />
                          Export Excel
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7280"
                            checked={!!checkedValues[7280]}
                            onChange={() => handleCheckboxChange(7280, 5160)}
                          />
                          Export PDF
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Inventory Management
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5165"
                      checked={!!checkedValues[5165]}
                      onChange={() => handleCheckboxChange(5165, 525)}
                    />
                    Vehicle Count Dashboard
                  </label>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5170"
                      checked={!!checkedValues[5170]}
                      onChange={() => handleCheckboxChange(5170, 525)}
                    />
                    Fuel Consumption Dashboard
                  </label>
                </div>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5175"
                      checked={!!checkedValues[5175]}
                      onChange={() => handleCheckboxChange(5175, 525)}
                    />
                    Location Data Dashboard
                  </label>

                  <div className="ml-5 mt-2">
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7315"
                            checked={!!checkedValues[7315]}
                            onChange={() => handleCheckboxChange(7315, 5175)}
                          />
                          Edit
                        </label>
                      </div>

                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7320"
                            checked={!!checkedValues[7320]}
                            onChange={() => handleCheckboxChange(7320, 5175)}
                          />
                          Delete
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7310"
                            checked={!!checkedValues[7310]}
                            onChange={() => handleCheckboxChange(7310, 5175)}
                          />
                          Export Excel
                        </label>
                      </div>
                      <div className="text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="7305"
                            checked={!!checkedValues[7305]}
                            onChange={() => handleCheckboxChange(7305, 5175)}
                          />
                          Export PDF
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Driver Performance Monitoring
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5180"
                      checked={!!checkedValues[5180]}
                      onChange={() => handleCheckboxChange(5180, 530)}
                    />
                    Monthly Driver Performance
                  </label>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Compliance & Reporting
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5185"
                      checked={!!checkedValues[5185]}
                      onChange={() => handleCheckboxChange(5185, 535)}
                    />
                    Vehicles Exceeding 5000 km
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="7325"
                          checked={!!checkedValues[7325]}
                          onChange={() => handleCheckboxChange(7325, 5185)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Approval Process 01
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5190"
                      checked={!!checkedValues[5190]}
                      onChange={() => handleCheckboxChange(5190, 540)}
                    />
                    Approval Process 01
                  </label>
                </div>
                <div className="ml-[9%] text-black">
                  <label className="block mb-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="7330"
                      checked={!!checkedValues[7330]}
                      onChange={() => handleCheckboxChange(7330, 5190)}
                    />
                    Document Uploading
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8500"
                          checked={!!checkedValues[8500]}
                          onChange={() =>
                            handleCheckboxChange(8500, 7330, 5190)
                          }
                        />
                        Vehicle Document uploading
                      </label>
                    </div>
                  </div>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="8505"
                          checked={!!checkedValues[8505]}
                          onChange={() =>
                            handleCheckboxChange(8505, 7330, 5190)
                          }
                        />
                        Driver Document uploading
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-[9%] text-black">
                  <label className="block mb-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="7335"
                      checked={!!checkedValues[7335]}
                      onChange={() => handleCheckboxChange(7335, 5190)}
                    />
                    System Editing
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="9510"
                          checked={!!checkedValues[9510]}
                          onChange={() =>
                            handleCheckboxChange(9510, 7335, 5190)
                          }
                        />
                        Export csv
                      </label>
                    </div>
                  </div>
                </div>

                <div className="ml-[9%] text-black">
                  <label className="block mb-2">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="7340"
                      checked={!!checkedValues[7340]}
                      onChange={() => handleCheckboxChange(7340, 5190)}
                    />
                    Gate Pass Request
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="ml-[9%] text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="9515"
                          checked={!!checkedValues[9515]}
                          onChange={() =>
                            handleCheckboxChange(9515, 7340, 5190)
                          }
                        />
                        Export csv
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Approval process 02
                </p>
                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5195"
                      checked={!!checkedValues[5195]}
                      onChange={() => handleCheckboxChange(5195, 545)}
                    />
                    Approval Process 02
                  </label>

                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="7345"
                        checked={!!checkedValues[7345]}
                        onChange={() => handleCheckboxChange(7345, 5195)}
                      />
                      Document Uploading
                    </label>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="ml-[9%] text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8510"
                            checked={!!checkedValues[8510]}
                            onChange={() =>
                              handleCheckboxChange(8510, 7345, 5195)
                            }
                          />
                          Vehicle Document uploading
                        </label>
                      </div>
                    </div>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="ml-[9%] text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="8515"
                            checked={!!checkedValues[8515]}
                            onChange={() =>
                              handleCheckboxChange(8515, 7345, 5195)
                            }
                          />
                          Driver Document uploading
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="7350"
                        checked={!!checkedValues[7350]}
                        onChange={() => handleCheckboxChange(7350, 5195)}
                      />
                      System Editing
                    </label>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="ml-[9%] text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="9530"
                            checked={!!checkedValues[9530]}
                            onChange={() =>
                              handleCheckboxChange(9530, 7350, 5195)
                            }
                          />
                          Export csv
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="ml-[9%] text-black">
                    <label className="block mb-2">
                      <input
                        type="checkbox"
                        className="mr-2"
                        value="7355"
                        checked={!!checkedValues[7355]}
                        onChange={() => handleCheckboxChange(7355, 5195)}
                      />
                      Gate Pass Request
                    </label>
                    <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                      <div className="ml-[9%] text-black">
                        <label className="block mb-2">
                          <input
                            type="checkbox"
                            className="mr-2"
                            value="9535"
                            checked={!!checkedValues[9535]}
                            onChange={() =>
                              handleCheckboxChange(9535, 7355, 5195)
                            }
                          />
                          Export csv
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p className="bg-gray-200 p-5 rounded-[20px] font-bold text-[15px] text-black">
                  Approval Process 03
                </p>

                <div className="ml-5 mt-2">
                  <label className="block mb-2 text-blue-500">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value="5200"
                      checked={!!checkedValues[5200]}
                      onChange={() => handleCheckboxChange(5200, 550)}
                    />
                    Approval Process 03
                  </label>
                  <div className="ml-[9%] grid grid-cols-2 grid-flow-row gap-1">
                    <div className="text-black">
                      <label className="block mb-2">
                        <input
                          type="checkbox"
                          className="mr-2"
                          value="9540"
                          checked={!!checkedValues[9540]}
                          onChange={() => handleCheckboxChange(9540, 5200)}
                        />
                        Excel export
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VehicleFleetPermission;
