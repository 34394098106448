import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import moment from "moment";
import TimePicker from "react-time-picker";
import "./attendance_edit.css"; // import the CSS file for custom styles
import axios from "axios";

const Attendance_Edit = () => {
  const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD")); // Date in YYYY-MM-DD format
  const [employeeId, setEmployeeId] = useState(""); // Employee ID input
  const [attendanceData, setAttendanceData] = useState(null); // Store the entire response data
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingCheckOut, setIsEditingCheckOut] = useState(false);
  const [editTime, setEditTime] = useState("");

  const { REACT_APP_API_ENDPOINT } = process.env;

  // Fetch attendance data when the "Get Data" button is clicked
  const fetchAttendanceData = async () => {
    if (!employeeId || !currentDate) {
      alert("Please enter both employee number and date.");
      return;
    }

    try {
      const response = await axios.get(
        `${REACT_APP_API_ENDPOINT}/v1/hris/attendence/getAttendanceByEmployeeAndDate`,
        {
          params: {
            employee_id: employeeId,
            date: currentDate,
          },
        }
      );

      setAttendanceData(response.data[0]); // Assuming response is an array with one object
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      alert(
        "Failed to fetch attendance data. Please check the inputs and try again."
      );
    }
  };

  const openEditModal = (timeType) => {
    if (timeType === "checkIn") {
      setEditTime(
        attendanceData?.checkIN_time
          ? moment(attendanceData.checkIN_time).format("HH:mm")
          : "09:00"
      );
      setIsEditing(true);
    } else {
      setEditTime(
        attendanceData?.checkOUT_time
          ? moment(attendanceData.checkOUT_time).format("HH:mm")
          : "17:00"
      );
      setIsEditingCheckOut(true);
    }
  };

  const handleSave = () => {
    if (isEditing) {
      setAttendanceData({ ...attendanceData, checkIN_time: editTime });
      setIsEditing(false);
    } else if (isEditingCheckOut) {
      setAttendanceData({ ...attendanceData, checkOUT_time: editTime });
      setIsEditingCheckOut(false);
    }
  };

  return (
    <div className="bg-background h-screen overflow-y-auto">
      <div className="mt-8 ml-6">
        <p className="text-[30px] font-bold">Employee Attendance Edit</p>

        {/* Input fields for employee number and date */}
        <div className="flex items-center gap-4 mt-6">
          <div className="text-[15px] font-semibold">
            <label className="text-[20px]">Employee Number</label>
            <input
              type="text"
              className="border border-black rounded-lg p-2 ml-2"
              value={employeeId}
              onChange={(e) => setEmployeeId(e.target.value)}
              placeholder="Enter Employee ID"
            />
          </div>
          <div className="text-[15px] font-semibold">
            <label className="text-[20px]">Date</label>
            <input
              type="date"
              className="border border-black rounded-lg p-2 ml-2"
              value={currentDate}
              onChange={(e) => setCurrentDate(e.target.value)}
            />
          </div>
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
            onClick={fetchAttendanceData}
          >
            Get Data
          </button>
        </div>

        {/* Display fetched data */}
        {attendanceData && (
          <div className="bg-white shadow-md rounded-lg p-5 w-[50%] mt-8 font-bold">
            <div className="text-[20px] mb-4">Attendance Details</div>
            <p>
              <strong>Employee ID:</strong> {attendanceData.employee_id}
            </p>
            <p>
              <strong>Check-IN Time:</strong>{" "}
              {moment(attendanceData.checkIN_time).format("HH:mm:ss")}
            </p>
            <p>
              <strong>Check-IN Type:</strong> {attendanceData.checkIN_type}
            </p>
            <p>
              <strong>Check-OUT Time:</strong>{" "}
              {moment(attendanceData.checkOUT_time).format("HH:mm:ss")}
            </p>
            <p>
              <strong>Check-OUT Type:</strong> {attendanceData.checkOUT_type}
            </p>
            <p>
              <strong>Status:</strong> {attendanceData.status}
            </p>
            <p>
              <strong>Latitude:</strong> {attendanceData.latitude}
            </p>
            <p>
              <strong>Longitude:</strong> {attendanceData.longitude}
            </p>
            <p>
              <strong>Address:</strong>{" "}
              {attendanceData.address || "No address available"}
            </p>
            <p>
              <strong>Edit:</strong>{" "}
              {attendanceData.edit || "No edit information"}
            </p>

            <div className="flex items-center gap-4 mt-6">
              <div>
                <p>
                  Check-IN Time:{" "}
                  {moment(attendanceData.checkIN_time).format("HH:mm:ss")}
                </p>
                <MdEdit
                  className="text-red-600 font-bold cursor-pointer"
                  onClick={() => openEditModal("checkIn")}
                />
              </div>
              <div className="mt-4">
                <p>
                  Check-OUT Time:{" "}
                  {moment(attendanceData.checkOUT_time).format("HH:mm:ss")}
                </p>
                <MdEdit
                  className="text-red-600 font-bold cursor-pointer"
                  onClick={() => openEditModal("checkOut")}
                />
              </div>
            </div>
          </div>
        )}

        {/* Edit Modal */}
        {(isEditing || isEditingCheckOut) && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-[300px]">
              <h2 className="text-lg font-bold mb-4">Edit Time</h2>
              <TimePicker
                onChange={setEditTime}
                value={editTime}
                className="w-full p-2 border border-gray-300 rounded-md custom-time-picker"
                disableClock={true}
              />

              <div className="flex justify-end gap-4 mt-4">
                <button
                  onClick={handleSave}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Save
                </button>
                <button
                  onClick={() => {
                    setIsEditing(false);
                    setIsEditingCheckOut(false);
                  }}
                  className="bg-gray-500 text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Attendance_Edit;
