import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { MdOutlineDelete } from "react-icons/md";

const OvertimeAssigning = () => {
    const [employeeData, setEmployeeData] = useState([]);
    const [filteredEmployeeData, setFilteredEmployeeData] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for searching employees
    const [formData, setFormData] = useState({
        date: '',
        start_time: '',
        end_time: '',
        reason: '',
        name_of_work: '',
    });

    const [showSuccessPopup, setShowSuccessPopup] = useState(false); // Popup visibility state

    useEffect(() => {
        // Fetch employee data on component mount
        axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/employees/getemployeebasicdetails')
            .then((response) => {
                setEmployeeData(response.data);
                setFilteredEmployeeData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching employee data:', error);
            });
    }, []);

    const handleSearch = (query) => {
        setSearchQuery(query);
        const lowerCaseQuery = query.toLowerCase();
        setFilteredEmployeeData(
            employeeData.filter((emp) =>
                emp.employee_name_initial.toLowerCase().includes(lowerCaseQuery)
            )
        );
    };

    const handleSelectEmployee = (employee) => {
        setSelectedEmployees((prevSelected) => {
            const isSelected = prevSelected.some((e) => e.employee_no === employee.employee_no);
            if (isSelected) {
                return prevSelected.filter((e) => e.employee_no !== employee.employee_no);
            }
            return [...prevSelected, employee];
        });
    };

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = () => {
        const employeeIds = selectedEmployees.map((employee) => employee.employee_no);
        const postData = {
            employees: employeeIds,
            name_of_work: formData.name_of_work, // Add this line
            date: formData.date,
            start_time: formData.start_time,
            end_time: formData.end_time,
            reason: formData.reason,
        };
    
        axios.post('https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/addovertime', postData)
            .then((response) => {
                console.log('Overtime assignment submitted:', response.data);
                setShowSuccessPopup(true); // Show success popup
    
                // Auto-close popup after 3 seconds
                setTimeout(() => setShowSuccessPopup(false), 3000);
            })
            .catch((error) => {
                console.error('Error submitting overtime assignment:', error);
            });
    };
    

    return (
        <div className="bg-background h-screen overflow-y-auto relative">
            <div className="mx-8 my-5">
                <p className="text-[30px] font-semibold">Overtime Assigning</p>
                <div className="bg-white p-5 rounded-lg shadow-lg mt-8">
                    <p className="text-[20px] font-semibold">Assignment Form</p>
                    <div className="mt-8 text-[20px] grid grid-cols-3 grid-flow-row gap-4">
                        <div className="flex items-center gap-4">
                            <label>Date :</label>
                            <input
                                type="date"
                                name="date"
                                className="border border-black rounded-lg p-1"
                                value={formData.date}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="flex items-center gap-4">
                            <label>Name of work :</label>
                            <input
                                name="name_of_work"
                                className="border border-black rounded-lg p-1"
                                value={formData.name_of_work}
                                onChange={handleFormChange}
                            />
                        </div>

                        <div className="flex items-center gap-4">
                            <label>Reason :</label>
                            <input
                                name="reason"
                                className="border border-black rounded-lg p-1"
                                value={formData.reason}
                                onChange={handleFormChange}
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label>Start Time :</label>
                            <input
                                type="time"
                                name="start_time"
                                className="border border-black rounded-lg p-1"
                                value={formData.start_time}
                                onChange={handleFormChange}
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <label>End Time :</label>
                            <input
                                type="time"
                                name="end_time"
                                className="border border-black rounded-lg p-1"
                                value={formData.end_time}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>
                    <div className="mt-6">
                        <h2 className="text-2xl font-bold font-[#071C50]">Assign Employees to Timetable</h2>
                        <div className="mt-4">
                            <input
                                type="text"
                                placeholder="Search employee by name"
                                className="input rounded-xl border border-gray-300 h-10 px-4 py-2 mb-4 placeholder-gray-400"
                                value={searchQuery}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                            <div className="overflow-y-auto max-h-64">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                                            <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-r-xl">Select</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredEmployeeData.map((employee) => (
                                            <tr key={employee.employee_no} className="border-b border-gray-300">
                                                <td className="px-4 py-2">{employee.employee_no}</td>
                                                <td className="px-4 py-2">{employee.employee_name_initial}</td>
                                                <td className="px-4 py-2 text-center">
                                                    <input
                                                        type="checkbox"
                                                        onChange={() => handleSelectEmployee(employee)}
                                                        checked={selectedEmployees.some(
                                                            (e) => e.employee_no === employee.employee_no
                                                        )}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-xl font-bold text-[#344054] mb-4">Selected Employees</h3>
                            <div className="overflow-y-auto max-h-64">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr>
                                            <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                                            <th className="px-4 py-2 bg-[#F5F5F5] ">Employee Name</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {selectedEmployees.map((employee) => (
                                            <tr key={employee.employee_no} className="border-b border-gray-300">
                                                <td className="px-4 py-2 ml-[600px]">{employee.employee_no}</td>
                                                <td className="px-4 py-2">{employee.employee_name_initial}</td>

                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="flex justify-between mt-4">
                            <button
                                onClick={handleSubmit}
                                className="bg-gray-500 text-white px-4 py-2 rounded-[22px]"
                            >
                                Assign Employees to Timetable
                            </button>
                            <button className="bg-[#797C80] text-white px-4 py-2 rounded-[22px]">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Success Popup */}
            {showSuccessPopup && (
                <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-5 rounded-lg shadow-lg">
                        <p className="text-lg font-semibold text-green-600">Overtime assigned successfully!</p>
                        <button
                            onClick={() => setShowSuccessPopup(false)}
                            className="mt-4 bg-green-500 text-white px-4 py-2 rounded"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OvertimeAssigning;
