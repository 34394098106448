import React from 'react'
import { Link } from 'react-router-dom';

const overtime_assigning = () => {
  return (
    <div className='bg-background h-screen overflow-y-auto '>
      <div className='mx-5 mt-6'>
        <p className='text-[30px] font-semibold mb-6'>Overtime</p>

        <div className='flex gap-8 items-center'>
          <div className='flex items-center gap-8'>
            <div className='bg-white shadow-md rounded-lg p-2 w-[400px] h-[200px]'>
              <div className='text-[25px] font-semibold mx-5 my-5'>
                <p>Overtime</p>
                <p>Assigning</p>
              </div>

              <div className='flex justify-between items-center ml-3 mt-5'>
                <Link to="/overtime_assigning">
                  <button className='p-2 bg-primary rounded-[20px] text-white text-semibold'>Click Here</button>
                </Link>
              </div>
            </div>
          </div>

          <div className='flex items-center gap-8'>
            <div className='bg-white shadow-md rounded-lg p-2 w-[400px] h-[200px]'>
              <div className='text-[25px] font-semibold mx-5 my-5'>
                <p>Overtime</p>
                <p>Assignment Table</p>
              </div>

              <div className='flex justify-between items-center ml-3 mt-5'>
                <Link to="/overtime_assignment_table">
                  <button className='p-2 bg-primary rounded-[20px] text-white text-semibold'>Click Here</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default overtime_assigning