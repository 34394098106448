/** @format */

import React from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";

const InventoryDashboard_nav = () => {
  const { hasPermission } = usePermissions();
  const BranchReqData = [
    {
      id: 1,
      header: "Inventory Items",
      headerClasses: "text-black",
      linkTo: "/inventory/dashboard",
      permissionId: 1280,
    },
    {
      id: 2,
      header: "Inventory Expire Items ",
      headerClasses: "text-black",
      linkTo: "/inventory/expire_items",
      permissionId: 1281,
    },
  ];

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[25px] font-sans ml-6 mt-6 font-bold text-[#797C80]">
        Inventory Dashboard
      </p>

      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[10%] mx-6">
        {BranchReqData.map((card) =>
          hasPermission(card.permissionId) ? (
            <Link key={card.id} to={card.linkTo}>
              <div
                key={card.id}
                className="bg-white rounded-[32px] p-4 shadow-md flex flex-col justify-between h-[200px]" // Fixed height
              >
                <div>
                  <h2 className="text-[20px] font-semibold mb-1">
                    {card.header}
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button className="bg-primary w-[30%] mt-2 text-white font-bold py-2 px-4 rounded-[22px]">
                    Click Here
                  </button>
                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
};

export default InventoryDashboard_nav;
