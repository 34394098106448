import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Navbar from "../../../../components/navbar/navbar";
import { IoAdd } from "react-icons/io5";
import AddMaintenancePopup from "./add_maintenance";
import ChangeMaintenancePopup from "./change_maintenance";
import { FaEdit, FaTrash } from "react-icons/fa"; // Import icons for edit and delete
import axios from "axios";
import "jspdf-autotable";
import Papa from "papaparse";
import Notification from "../../../../components/notification/notification";
import usePermissions from "../../../../components/permissions/permission";

function Maintenance_records() {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;

  const { hasPermission } = usePermissions();
  const [seletedValue, setSeletedValue] = useState({
    search_term: "",
  });
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleRowSelect = (id) => {
    console.log(id);
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    getTableData();
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
    getTableData();
  };

  const handleEdit = () => {
    getTableData();
    setIsOpenEdit(true);
  };

  const handleAdd = async () => {
    setIsOpen(true);
    // try {
    //   const response = await axios.put(
    //     `${backendUrl}/driver/delete-driver-details`,
    //     {}, // Empty body as PUT requires some data
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //       params: {
    //         driver_id: selectedRow.driver_id, // Passing the vehicle_id as a query param
    //       },
    //     }
    //   );

    //   // Success response
    //   setNotification({
    //     message: response.data.message || "Vehicle deactivated successfully!",
    //     type: "success",
    //   });

    //   // Optionally refresh the data
    //   getTableData();
    // } catch (error) {
    //   console.error("Error deleting vehicle:", error);

    //   setNotification({
    //     message:
    //       error.response?.data?.error || "Delete failed, vehicle not found",
    //     type: "error",
    //   });
    // }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        search: seletedValue.search_term,
      };

      console.log(params);

      const response = await axios.get(`${backendUrl}/maintenance/`, {
        params,
      });

      console.log("----Driver------");
      console.log(response.data);
      console.log("----Driver------");

      // Ensure you set both data and pagination state
      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  const downloadPDF = async (row) => {
    console.log(row);

    // Check if upload_req_letter is null or undefined
    if (row.upload_req_letter === null || row.upload_req_letter === "null") {
      setNotification({
        message: "The document is in the process of being verified",
        type: "error",
      });
      return; // Exit the function if the document is not available
    }

    const temp = row.upload_req_letter; // Get the upload request letter path

    try {
      const response = await axios.get(`${backendUrl}/maintenance/download`, {
        params: {
          file_path: temp, // Pass the file path as a query param
        },
        responseType: "blob", // Expect a blob response for the PDF
      });

      // Create a new Blob object for the PDF
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "UploadRequestLetter.pdf"; // Set the download file name

      // Append the link, click it, and remove it
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF:", error);
      setNotification({
        message: "Error occurred while downloading the document",
        type: "error",
      });
    }
  };

  const PdfExport = () => {};

  const ExcelExport = () => {
    const csvData = tableData.map((row) => ({
      "Maintenance ID": row.maintenance_id,
      "Date of Request": row.date_of_request,
      "Maintenance Type": row.maintenance_type,
      "Maintenance Priority": row.maintenance_priority,
      "Desc of Issue": row.desc_of_issue,
      "Date of Approval": row.date_of_approval,
      "Vehicle Service Dates": row.vehicle_service_dates,
      "Vehicle ID": row.vehicle_id,
    }));

    const csv = Papa.unparse(csvData);

    // Create a blob and download it as a CSV file
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "Maintenance Records.csv");
    document.body.appendChild(link); // Required for Firefox
    link.click();
    document.body.removeChild(link);
  };
  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const actionsRef = useRef(null); // Ref for the "actions" element

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [
    rowsPerPage,
    pagination.current_page,
    seletedValue,
    seletedValue.search_term,
  ]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="">
        <div className="mx-10 mt-5 -ml-1">
          <Navbar />
          <div className="flex items-center justify-between mt-6 ">
            {/* Added negative margin-left */}
            <div>
              <p className="text-[30px] font-semibold">Maintenance & Repairs</p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Maintenance Records
              </p>
            </div>
            <div className="flex items-center gap-6">
              <div>
                <div className="text-[#069855] text-[20px] font-bold">
                  {/* {currentDate} */}
                </div>
              </div>
              <div className="text-[20px] font-bold">{/* {currentTime} */}</div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row md:items-center md:gap-4 mt-6">
            {/* Search Box for Account Name */}
            <div className="w-full md:w-[350px]">
              <input
                type="text"
                placeholder="search maintenance Id"
                name="search_term"
                value={seletedValue.search_term}
                onChange={handleChange}
                className="w-full p-2 border border-black rounded focus:ring-2"
              />
            </div>

            {/* Notification Alert */}
            <Notification
              message={notification.message}
              type={notification.type}
              onClose={handleCloseNotification}
            />

            {/* Button Container */}
            <div
              className="relative flex items-center gap-4 ml-auto"
              id="actions"
              ref={actionsRef}
            >
              {/* <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-red-400 to-red-600 rounded hover:from-red-500 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
                onClick={PdfExport}
              >
                <IoAdd className="text-xl" />
                PDF Export
              </button> */}
              {hasPermission(7260) && (
                <button
                  className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-green-400 to-green-600 rounded hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
                  onClick={ExcelExport}
                >
                  <IoAdd className="text-xl" />
                  Excel Export
                </button>
              )}
              {selectedRow ? (
                // Action Dropdown
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                    <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                      {selectedRow.date_of_completion === null ||
                      selectedRow.date_of_completion === "null" ? (
                        <div>
                          {hasPermission(7265) && (
                            <button
                              onClick={handleAdd}
                              className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <IoAdd className="mr-2" /> Add
                            </button>
                          )}
                        </div>
                      ) : (
                        <div>
                          {hasPermission(7270) && (
                            <button
                              onClick={handleEdit}
                              className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <FaEdit className="mr-2" /> Change
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                // Add Button
                // <button
                //   className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                //   onClick={togglePopup}
                // >
                //   <IoAdd className="text-xl" />
                //   Add
                // </button>
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-10 z-auto">
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Maintenance ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Date of Request
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Maintenance Type
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Maintenance Priority
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Desc of Issue
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Date of Approval
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Vehicle Service Dates
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Vehicle ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200 ">
                  Upload Request Letter
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr
                  key={index}
                  className={`${
                    selectedRow === item
                      ? "bg-blue-600 text-white"
                      : "hover:bg-gray-100"
                  }`}
                  onClick={() => handleRowSelect(item)}
                >
                  <td className="px-6 py-4 border-b border-black">
                    {item.maintenance_id}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.date_of_request}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.maintenance_type}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.maintenance_priority}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.desc_of_issue}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.date_of_approval}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.vehicle_service_dates}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {item.vehicle_id}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    {item.upload_req_letter === null ||
                    item.upload_req_letter === "null" ? (
                      <button
                        className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition duration-300 cursor-not-allowed"
                        onClick={() => downloadPDF(item)}
                      >
                        Pending
                      </button>
                    ) : (
                      <button
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                        onClick={() => downloadPDF(item)}
                      >
                        Download
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
          <button
            key={size}
            onClick={() => handleRowsPerPageChange(size)}
            className={`px-4 py-2 ${
              rowsPerPage === size
                ? "bg-green-600 text-white" // Active button with green background and white text
                : "bg-green-300 text-black" // Inactive button with lighter green background and black text
            } rounded hover:bg-green-500`} // Hover effect for both states
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              currentPage: Math.min(
                prevPagination.currentPage + 1,
                prevPagination.totalPages
              ),
            }))
          }
          disabled={pagination.currentPage >= pagination.totalPages}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
        >
          Load More
        </button>
      </div>

      {/* Add Chart Account Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <AddMaintenancePopup
              togglePopup={togglePopup}
              maintenance={selectedRow}
            />
          </div>
        </div>
      )}

      {/* Edit Chart Account Popup */}
      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <ChangeMaintenancePopup
              toggleEditPopup={editePopup}
              maintenance={selectedRow}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Maintenance_records;
