import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../components/navbar/navbar";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa"; // Import icons for edit and delete
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Notification from "../../../components/notification/notification";
import FuelConsumptionPopup from "./fuel_consumption_popup";
import usePermissions from "../../../components/permissions/permission";

const Fuel_consumption_data = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  
const { hasPermission } = usePermissions();
  const [seletedValue, setSeletedValue] = useState({
    search_term: "",
  });

  
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const handleRowSelect = (id) => {
    console.log(id);
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
    getTableData();
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
    getTableData();
  };

  const handleEdit = () => {
    getTableData();
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    try {
      const response = await axios.put(
        `${backendUrl}/driver/delete-driver-details`,
        {}, // Empty body as PUT requires some data
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            driver_id: selectedRow.driver_id, // Passing the vehicle_id as a query param
          },
        }
      );

      // Success response
      setNotification({
        message: response.data.message || "Vehicle deactivated successfully!",
        type: "success",
      });

      // Optionally refresh the data
      getTableData();
    } catch (error) {
      console.error("Error deleting vehicle:", error);

      setNotification({
        message:
          error.response?.data?.error || "Delete failed, vehicle not found",
        type: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        search: seletedValue.search_term,
      };


      const response = await axios.get(`${backendUrl}/fuelsManagements/get`, {
        params,
      });

      console.log("----Driver------");
      console.log(response.data);
      console.log("----Driver------");

      // Ensure you set both data and pagination state
      if (response.data) {
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  const downloadPDF = async (row) => {
    console.log(row);
    // Check if upload_paths exists and has at least one element
    if (!row.upload_paths || row.upload_paths.length === 0) {
      setNotification({
        message: "The document is in the process of being verified",
        type: "error",
      });
      return; // Exit the function if no paths are provided
    }

    const temp = row.upload_paths[0]; // Get the first upload path

    try {
      const response = await axios.get(`${backendUrl}/vehicle/downloadFiles`, {
        params: {
          registration_upload_path: temp, // Pass registration_upload_path as a query param
        },
        responseType: "blob", // Ensure we expect a blob response
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "VehicleDetails.pdf"; // Add .pdf extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

  const PdfExport = () => {
    
  };
  
  const ExcelExport = () => {
   
  };
  
  // Handler for closing dropdown and deselecting row on outside click
  const handleClickOutside = (event) => {
    if (actionsRef.current && !actionsRef.current.contains(event.target)) {
      setSelectedRow(null);
      setDropdownVisible(false);
    }
  };

  const actionsRef = useRef(null); // Ref for the "actions" element

  // Close dropdown and deselect row if clicking outside the table
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [
    rowsPerPage,
    pagination.current_page,
    seletedValue,
    seletedValue.search_term,
  ]);

  return (
    <div className="mx-10 mt-5 text-xl">
    <div className="">
      <div className="mx-10 mt-5 -ml-1">
        <Navbar />
        <div className="flex items-center justify-between mt-6 ">
          {/* Added negative margin-left */}
          <div>
            <p className="text-[30px] font-semibold">
            Fuel Management
            </p>
            <p className="text-[15px] font-semibold text-[#ABDCB0]">
            Fuel Consumption data
            </p>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {/* {currentDate} */}
              </div>
            </div>
            <div className="text-[20px] font-bold">{/* {currentTime} */}</div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row md:items-center md:gap-4 mt-6">
          {/* Search Box for Account Name */}
          <div className="w-full md:w-[350px]">
            <input
              type="text"
              placeholder="search fuel consumption Id"
              name="search_term"
              value={seletedValue.search_term}
              onChange={handleChange}
              className="w-full p-2 border border-black rounded focus:ring-2"
            />
          </div>

          {/* Notification Alert */}
          <Notification
            message={notification.message}
            type={notification.type}
            onClose={handleCloseNotification}
          />

          {/* Button Container */}
          <div
            className="relative flex items-center gap-4 ml-auto"
            id="actions"
            ref={actionsRef}
          >
            {/* <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-red-400 to-red-600 rounded hover:from-red-500 hover:to-red-700 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={PdfExport}
            >
              <IoAdd className="text-xl" />
              PDF Export
            </button>
            <button
              className="flex items-center gap-2 px-4 py-2 text-white bg-gradient-to-r from-green-400 to-green-600 rounded hover:from-green-500 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              onClick={ExcelExport}
            >
              <IoAdd className="text-xl" />
              Excel Export
            </button> */}
            {selectedRow ? (
              // Action Dropdown
              <div className="relative">
                <button
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                  className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  Actions
                </button>
                {dropdownVisible && (
                  <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                   {hasPermission (7190) && (
                    <button
                      onClick={handleEdit}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                    >
                      <FaEdit className="mr-2" /> Edit
                    </button>
                    )}
                    {hasPermission (7195) && (
                    <button
                      onClick={handleDelete}
                      className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                    >
                      <FaTrash className="mr-2" /> Delete
                    </button>
                    )}
                  </div>
                )}
              </div>
            ) : (
              // Add Button
              <div>
              {hasPermission (7185) && (
                
              <button
                className="flex items-center gap-2 px-4 py-2 text-white bg-indigo-600 rounded hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                onClick={togglePopup}
              >
                <IoAdd className="text-xl" />
                Add 
              </button>
            
            )}
            </div>
            )}
          </div>
        </div>
      </div>
    </div>

    <div className="my-4 overflow-x-auto mt-10 z-auto">
      {/* Scrollable Table Container with Increased Height */}
      <div className="max-h-[600px] overflow-y-auto">
        {/* TailwindCSS Table */}
        <table className="min-w-full bg-white border border-black ">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              Fuel Consumption Id
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              Vehicle Id
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              Driver Id
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              Driver Name
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              Fuel Cost
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              fuel_efficiency
              </th>
              <th className="px-6 py-3 font-bold text-left text-gray-500 uppercase border-b border-gray sticky top-0 bg-gray-200 ">
              fuel_qty
              </th>
          
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr
                key={index}
                className={`${
                  selectedRow === row
                    ? "bg-blue-600 text-white"
                    : "hover:bg-gray-100"
                }`}
                onClick={() => handleRowSelect(row)}
              >
                <td className="px-6 py-4 border-b border-black">
                  {row.fuel_consumption_id}
                </td>
                <td className="px-6 py-4 border-b border-black">
                  {row.vehicle_id}
                </td>
                {/* <td className="px-6 py-4 border-b border-black">
                  {row.is_enabled === 1 ? (
                    <span className="text-green-500">Enable</span>
                  ) : (
                    <span className="text-red-500">Disable</span>
                  )}
                </td> */}
                <td className="px-6 py-4 border-b border-black">
                  {row.driver_id}
                </td>
                <td className="px-6 py-4 border-b border-black">
                  {row.driverName}
                </td>
                <td className="px-6 py-4 border-b border-black">
                  {row.fuel_cost}
                </td>
                <td className="px-6 py-4 border-b border-black">
                  {row.fuel_efficiency}
                </td>
                <td className="px-6 py-4 border-b border-black">
                  {row.fuel_qty}
                </td>
                {/* <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap ">
                  {!row.upload_paths ||
                  row.upload_paths.length === 0 ||
                  row.upload_paths.includes("PENDING") ? (
                    <button
                      className="px-4 py-2 bg-red-500 text-white font-semibold rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-75 transition duration-300 cursor-not-allowed"
                      onClick={() => downloadPDF(row)}
                    >
                      Pending
                    </button>
                  ) : (
                    <button
                      className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                      onClick={() => downloadPDF(row)}
                    >
                      Download
                    </button>
                  )}
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

    {/* Pagination Controls */}
    <div className="flex justify-center mt-4 gap-2">
      {[20, 40, 60].map((size) => (
        <button
          key={size}
          onClick={() => handleRowsPerPageChange(size)}
          className={`px-4 py-2 ${
            rowsPerPage === size
              ? "bg-green-600 text-white" // Active button with green background and white text
              : "bg-green-300 text-black" // Inactive button with lighter green background and black text
          } rounded hover:bg-green-500`} // Hover effect for both states
        >
          {size}
        </button>
      ))}
      <button
        onClick={() =>
          setPagination((prevPagination) => ({
            ...prevPagination,
            currentPage: Math.min(
              prevPagination.currentPage + 1,
              prevPagination.totalPages
            ),
          }))
        }
        disabled={pagination.currentPage >= pagination.totalPages}
        className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-600"
      >
        Load More
      </button>
    </div>

    
       {/* Add Fuel Consumption Process Popup */}
       {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <FuelConsumptionPopup togglePopup={togglePopup} />
          </div>
        </div>
      )}

    {/* Edit Chart Account Popup */}
    {/* {isOpenEdit && (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
          <EditDrivrPopup toggleEditPopup={editePopup} driver={selectedRow} />
        </div>
      </div>
    )} */}
  </div>
  );
};

export default Fuel_consumption_data;
