import React, { useState } from 'react';

const AttendanceEditApproval = () => {
    const [showModal, setShowModal] = useState(false);
    const [selectedAttendance, setSelectedAttendance] = useState(null);

    const handleViewClick = (attendanceData) => {
        setSelectedAttendance(attendanceData);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedAttendance(null);
    };

    return (
        <div className='bg-background overflow-y-auto h-screen'>
            <div className='mx-6 mt-5'>
                <p className='text-[20px] font-bold mb-5'>Attendance Edit</p>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-100 border-b">
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Edited Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID of Editor</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID of Edited Employee</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Department</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Old Attendance Value</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">New Attendance Value</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="border-b hover:bg-gray-50">
                            <td className="px-6 py-4 text-sm"></td>
                            <td className="px-6 py-4 text-sm"></td>
                            <td className="px-6 py-4 text-sm"></td>
                            <td className="px-6 py-4 text-sm"></td>
                            <td className="px-6 py-4 text-sm text-primary font-bold cursor-pointer" onClick={() => handleViewClick({ /* mock data */ })}>View</td>
                            <td className="px-6 py-4 text-sm text-primary cursor-pointer" onClick={() => handleViewClick({ /* mock data */ })}>View</td>
                            <td className="px-6 py-4 text-sm"></td>
                            <td className="px-6 py-4 text-sm">
                                <div className='flex items-center gap-5'>
                                    <button className="bg-primary p-2 text-white rounded-lg">Edit</button>
                                    <button className='bg-gray-500 text-white p-2 rounded-lg'>Reject</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Modal for Viewing Attendance Details */}
            {showModal && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[400px] p-5 rounded-lg shadow-lg">
                        <h2 className="text-center text-lg font-bold mb-4">Attendance Details</h2>
                        <div className="flex flex-col gap-4">
                            <div className="flex justify-between">
                                <label>Employee ID :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.employeeId || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>Check In Time :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkInTime || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>Check In Type :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkInType || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>Check Out Time :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkOutTime || ''}
                                    readOnly
                                />
                            </div>
                            <div className="flex justify-between">
                                <label>Check Out Type :</label>
                                <input
                                    type="text"
                                    className="border rounded p-1"
                                    value={selectedAttendance?.checkOutType || ''}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="flex justify-center mt-5">
                            <button
                                onClick={closeModal}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AttendanceEditApproval;
