import React from "react";
// import AnimatedCard from "../../../components/vehicleFleetCard/card";
import vehicleLocation from "../../../Assets/vehicleFleetImg/vehicle_location.svg";
import usePermissions from "../../../components/permissions/permission";


const AnimatedCard = ({ imageSrc, title, description, buttonText, link }) => {
  const handleCardClick = () => {
    if (link) {
      window.open(link, "_blank", "noopener,noreferrer"); // Open link in a new tab
    }
  };

  return (
    <div
      className="w-80 h-96 bg-white rounded-lg shadow-lg overflow-hidden transform transition duration-500 hover:scale-105 hover:shadow-2xl cursor-pointer"
      onClick={handleCardClick} // Redirect on card click
    >
      {/* Card Image */}
      <div className="w-full h-2/3">
        <img
          className="w-full h-full object-contain" // Ensures image fits inside the div without distortion
          src={imageSrc}
          alt="Card"
        />
      </div>
    
      {/* Card Content */}
      <div className="p-4 h-1/3 flex items-center justify-center">
        <h2 className="text-xl font-bold text-gray-800">{title || "Card Title"}</h2>
      </div>
    </div>
  );
};




const Home = () => {
  const { hasPermission } = usePermissions();

  return (
    <div className="flex flex-wrap justify-center items-center gap-8 p-4 bg-gray-100 min-h-screen">
      {/* Card 1 */}
      {/* {hasPermission(5100) && ( */}
      <AnimatedCard
        imageSrc={vehicleLocation}
        title="Track My Car"
        link="https://gps.trackmycar.lk/index.php"
      />
      {/* )} */}
    </div>
  );
};

export default Home;
