import axios from "axios";
import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
// import Autocomplete from "./autocomplete"; // Make sure to adjust the import path if needed
import Notification from "../../../../components/notification/notification";

const Add_maintenance = ({ togglePopup, maintenance }) => {
  console.log(maintenance)
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [maintenanceid, setMaintenanceID] = useState();
  const [formData, setFormData] = useState({
    date_of_completion: "",
    serviced_mileage: "",
    life_span: "",
    maintenance_cost: "",
    changed_parts: [{ parts_name: "", quantity: "", parts_price: "" }],
  });
  const [allItems, setAllItems] = useState([
    // Example data, replace with actual data
    { id: 1, item_name: "Engine Oil" },
    { id: 2, item_name: "Oil filter" },
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePartChange = (index, e) => {
    const { name, value } = e.target;
    const newParts = formData.changed_parts.map((part, i) =>
      i === index ? { ...part, [name]: value } : part
    );
    setFormData({ ...formData, changed_parts: newParts });
  };

  const addPart = () => {
    setFormData({
      ...formData,
      changed_parts: [
        ...formData.changed_parts,
        { parts_name: "", quantity: "", parts_price: "" },
      ],
    });
  };

  const removePart = (index) => {
    const newParts = formData.changed_parts.filter((_, i) => i !== index);
    setFormData({ ...formData, changed_parts: newParts });
  };

  const [allCustomers, setAllCustomers] = useState([]);
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [allAccount, setAllAccount] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };

  // Handle input change
  // const handleInputChange = (e, index = null) => {
  //   const { name, value } = e.target;
  //   if (index !== null) {
  //     const newItems = [...addSalesInvoice.sales_invoice_items.data];
  //     newItems[index][name] = value;
  //     SetAddSalesInvoice((prevDetails) => ({
  //       ...prevDetails,
  //       sales_invoice_items: { data: newItems },
  //     }));
  //   } else {
  //     SetAddSalesInvoice((prevDetails) => ({
  //       ...prevDetails,
  //       [name]: value,
  //     }));
  //   }
  // };

  // Handle Save Form
  const handleSaveForm = async () => {
    try {
      const response = await axios.put(
        `${backendUrl}/maintenance/maintenanceLogs`, // Make sure backendUrl is correct
        formData, // Payload (formData)
        {
          params: {
            maintenance_id: maintenanceid, // Passing the maintenance ID as a query parameter
          },
          headers: {
            "Content-Type": "application/json", // Set header for JSON
          },
        }
      );
      
        setNotification({
          message: "Maintenance log saved successfully!",
          type: "success",
        });
    
    } catch (error) {
      console.error("Error saving maintenance log:", error);
      // Handle error case
      setNotification({
        message:
          error.response?.data?.message ||
          "Error saving details. Please try again.",
        type: "error",
      });
    }

    setIsSaveConfirmationOpen(false);
  };

  // Handle Reset Form
  const handleResetForm = () => {
    // setAllAccount({
    //   customer: "",
    //   invoice_no: "",
    //   invoice_date: "",
    //   payment_due_date: "",
    //   grand_total: "",
    //   payment_method: "",
    //   debit_account_id: "",
    //   credit_account_id: "",
    //   prepared_by: "",
    //   sales_invoice_items: {
    //     data: [{ item_name: "", quantity: 0, rate: 0, amount: 0 }],
    //   },
    //   description: "",
    //   remarks: "",
    //   salesInvoiceFile: "",
    // });
    setIsResetConfirmationOpen(false);
  };

  // File State Clear
  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // Open Reset Confirmation
  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  // Close Reset Confirmation
  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  // Open Save Confirmation
  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  // Close Save Confirmation
  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  // Add a new testing stage
  // const addStage = () => {
  //   SetAddSalesInvoice((prevDetails) => ({
  //     ...prevDetails,
  //     sales_invoice_items: {
  //       data: [
  //         ...prevDetails.sales_invoice_items.data,
  //         { item_name: "", quantity: 0, rate: 0, amount: 0 },
  //       ],
  //     },
  //   }));
  // };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  // Remove a testing stage
  // const removeStage = (index) => {
  //   const temp = addSalesInvoice.sales_invoice_items.data;
  //   console.log(temp);

  //   const newStages = temp.filter((_, i) => i !== index);

  //   SetAddSalesInvoice((prevDetails) => ({
  //     ...prevDetails,
  //     sales_invoice_items: {
  //       ...prevDetails.sales_invoice_items,
  //       data: newStages,
  //     },
  //   }));
  // };

  // const getAccountData = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${backendUrl}/account/getAllAccountsByFilters`
  //     );

  //     const formattedData = response.data.data.map((item) => ({
  //       id: item.account_id,
  //       code: item.account_code,
  //       name: item.account_name,
  //     }));

  //     setAllAccount(formattedData);
  //   } catch (error) {
  //     console.error("Error fetching sample details:", error);
  //   }
  // };

  // const getAllCustomers = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${backendUrl}/paymentEntry/getPartyByPartyType`,
  //       {
  //         params: {
  //           party_type: "Customer",
  //         },
  //       }
  //     );
  //     console.log(response.data);
  //     setAllCustomers(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setMaintenanceID(maintenance.maintenance_id);
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">
            Add Date of Completion & Related Details
          </h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Date of Completion* */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Date of Completion*
                </label>
                <input
                  type="date"
                  name="date_of_completion"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={formData.date_of_completion}
                  onChange={handleInputChange}
                />
              </div>

              {/* Maintenance Cost* */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Maintenance Cost*
                </label>
                <input
                  type="text"
                  name="maintenance_cost"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={formData.maintenance_cost}
                  onChange={handleInputChange}
                />
              </div>

              {/* Service Mileage* */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Service Mileage*
                </label>
                <input
                  type="text"
                  name="serviced_mileage"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={formData.serviced_mileage}
                  onChange={handleInputChange}
                />
              </div>

              {/* Lifespan* */}
              <div>
                <label className="block text-gray-700 font-semibold mb-1">
                  Lifespan*
                </label>
                <input
                  type="text"
                  name="life_span"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={formData.life_span}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <h2 className="text-2xl font-semibold mt-5">Add Part details</h2>

          {/* Dynamically render part details */}
          {formData.changed_parts.map((part, index) => (
            <div key={index} className="relative mb-4 mt-3">
              <div className="flex items-center">
                <label className="block mb-2 mr-2 text-gray-700">
                  Items {index + 1}
                </label>
                <button
                  type="button"
                  className="absolute top-0 right-0 mt-2 mr-2 font-semibold text-red-600 hover:text-red-800"
                  onClick={() => removePart(index)}
                >
                  <IoIosCloseCircleOutline />
                </button>
              </div>

              <div className="flex space-x-4">
                <div className="w-1/2">
                  <label className="block text-gray-700"> Part Name*</label>
                  <select
                    name="parts_name"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={part.parts_name}
                    onChange={(e) => handlePartChange(index, e)}
                  >
                    <option value=""></option>
                    {allItems.map((type) => (
                      <option key={type.id} value={type.item_name}>
                        {type.item_name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">Quantity*</label>
                  <input
                    type="text"
                    name="quantity"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={part.quantity}
                    onChange={(e) => handlePartChange(index, e)}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">Price*</label>
                  <input
                    type="text"
                    name="parts_price"
                    className="w-full p-2 mt-2 border border-gray-300 rounded"
                    value={part.parts_price}
                    onChange={(e) => handlePartChange(index, e)}
                  />
                </div>
              </div>
            </div>
          ))}

          <button
            type="button"
            onClick={addPart}
            className="p-2 mt-4 text-white bg-blue-500 rounded hover:bg-blue-600"
          >
            Add Items
          </button>
        </form>
        {/* Buttons */}
        <div className="flex justify-end gap-4 mt-8">
          <button
            type="button"
            onClick={handleOpenSaveConfirmation}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Save
          </button>

          <button
            type="button"
            onClick={handleOpenResetConfirmation}
            className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
          >
            Reset
          </button>
        </div>
      </div>

      {/* Confirmation Modals */}
      {isResetConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">
              Are you sure you want to reset the form?
            </h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseResetConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleResetForm}
                className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      )}

      {isSaveConfirmationOpen && (
        <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
          <div className="p-8 bg-white rounded-lg">
            <h3 className="mb-4 text-lg font-semibold">
              Are you sure you want to save the form?
            </h3>
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={handleCloseSaveConfirmation}
                className="px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={handleSaveForm}
                className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Add_maintenance;
