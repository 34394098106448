
import React, { useEffect, useState } from "react";
import axios from "axios";
import Notification from "../../../components/notification/notification";
const FileUpload = ({ setUploadedFiles, onClose }) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [notification, setNotification] = useState({ message: "", type: "" });

  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState({
    account_id: '',
    file:[]
  }); // State for selected bank account
  const [allAccount, setAllAccount] = useState([]);


  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

   // Handle Notification Close
   const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedBankAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpload = async () => {
    setUploading(true);
    console.log(selectedBankAccount);
  
    // Create a FormData object to send files and other data
    const formData = new FormData();
  
    // Append bank account data
    formData.append("account_id", selectedBankAccount.account_id);
  
    // Append each file
    files.forEach((file) => {
      formData.append("file", file);
    });
  
    try {
      const response = await axios.post(
        `${backendUrl}/banking/uploadBankStatement`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      console.log(response.data.message);
      setNotification({
        message: response.data.message,
        type: "success",
      });
    } catch (error) {
      console.error("Error", error);
      setNotification({
        message: error.response?.data?.error || "An error occurred.",
        type: "error",
      });
    }
  
    // Simulate file upload
    setTimeout(() => {
      setUploadedFiles((prev) => [...prev, ...files]);
      setFiles([]);
      setUploading(false);
      onClose(); // Close the modal after upload
    }, 2000);
  };
  


  const getAccountData = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/account/getAllAccountsByFilters`
      );

      const formattedData = response.data.data.map((item) => ({
        account_id: item.account_id,
        name: item.account_name,
      }));


      setAllAccount(formattedData);
    } catch (error) {
      console.error("Error fetching sample details:", error);
      // setTableData([]);
    }
  };


  useEffect(() => {
    getAccountData()
  }, [])

  return (
    <div className="max-w-md p-6 mx-auto mt-10 border rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-bold text-center">Upload</h2>

            {/* Notification Alert */}
            <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

      {/* Dropdown for selecting bank account */}
      <div className="mb-4">
        <label htmlFor="bankAccount" className="block mb-2 font-medium">
          Select Bank Account
        </label>
        <select
          id="account_id"
        name="account_id"
          value={selectedBankAccount.account_id}
          onChange={handleChange}
          className="w-full p-2 border rounded"
        >
          <option value="" disabled>
            -- Select a bank account --
          </option>
          {allAccount.map((account) => (
            <option key={account.account_id} value={account.account_id}>
              {account.name}
            </option>
          ))}
        </select>
      </div>

      <div className="p-6 text-center border-2 border-green-500 border-dashed rounded-lg">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="fileInput"
        />

        <label
          htmlFor="fileInput"
          className="block text-green-600 cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center">
            <div className="mb-2 text-3xl">📂</div>
            <div className="text-lg font-medium">
              Drag & drop files or <span className="underline">Browse</span>
            </div>
            <div className="mt-1 text-sm text-gray-500">
              Supported format: PDF
            </div>
          </div>
        </label>
      </div>

      <button
        onClick={handleUpload}
        className="w-full py-2 mt-6 text-white transition bg-green-600 rounded-lg hover:bg-green-700"
        disabled={!files.length || !selectedBankAccount} // Disable if no files or bank account selected
      >
        {uploading ? "Uploading..." : "Upload Files"}
      </button>

      {uploading && (
        <div className="mt-4 text-center">
          <p>
            Uploading - {files.length} {files.length === 1 ? "file" : "files"}
          </p>
          <div className="relative pt-1">
            <div className="flex items-center justify-between mb-2">
              <div className="text-right">
                <span className="inline-block text-xs font-semibold text-green-600">
                  Progress
                </span>
              </div>
            </div>
            <div className="flex h-2 mb-4 overflow-hidden text-xs bg-green-200 rounded">
              <div
                style={{ width: "100%" }}
                className="flex flex-col justify-center text-center text-white bg-green-500 shadow-none whitespace-nowrap"
              ></div>
            </div>
          </div>
        </div>
      )}

      {files.length > 0 && (
        <div className="mt-6">
          <h3 className="mb-2 text-xl font-bold">Files to Upload</h3>
          {files.map((file, index) => (
            <div
              key={index}
              className="flex items-center justify-between p-2 mb-2 bg-yellow-100 border rounded-lg"
            >
              <span>{file.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
