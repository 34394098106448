/** @format */

import React, { useEffect, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";

export default function HistoryItemReqFromBranch() {
  const location = useLocation();
  const selectedRecord = location.state?.selectedRecord || {};
  console.log("selected Rerecord", selectedRecord);
  const [tableData, setTableData] = useState(selectedRecord.items || []);
  console.log("selected tableData", tableData);
  const [releasedItem, setReleasedItem] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [subTotal, setSubTotal] = useState(0);
  const navigate = useNavigate();

  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => contentToPrint.current,
    documentTitle: "Print This Document",
    onBeforePrint: () => console.log("before printing..."),
    onAfterPrint: () => console.log("after printing..."),
    removeAfterPrint: true,
  });

  useEffect(() => {
    let total = 0;

    // Loop through each batch in the releasedItem array
    tableData.forEach((item) => {
      // Calculate the total for each batch and add it to the subtotal

      total += item.inventory_release_item_qty * item.batch_price;
    });

    setSubTotal(total);
    console.log("Subtotal: ", total);
  }, [tableData]);

  const BackHandler = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <div className="p-10 bg-background">
      {/* <TopNaw moduleName ='Inventory Items Purchase' userName={userName}/> */}
      <p className="header-1">History Log of Received Branch Item Request </p>
      <div>
        <div className="flex items-center ">
          <p className="label-1">Item Released Id</p>
          <p className="label-1">:</p>
          <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#F3F8FF] ml-10 border border-[#00000036] px-7 flex items-center ">
            {selectedRecord.inventory_release_id}
          </p>
        </div>
      </div>

      <div className="w-full flex items-center justify-center ">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1">Request Order Details</p>
          <div className="flex flex-col flex-wrap justify-between items-center">
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Id :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_id}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Request Order Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_date}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Requested User :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_by}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Released Order Date :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_release_date}
              </p>
            </div>
            <div className="w-[600px] flex items-center justify-between my-10">
              <p className="label-1">Released User :</p>
              <p className="font-[22px] w-[355px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_release_user_id}
              </p>
            </div>
          </div>
        </div>
      </div>

      <p className="header-1">Request Order Items</p>

      {/* table */}
      <div className="w-full flex items-center justify-center my-10">
        <div className="bg-[#F3F8FF] w-full min-h-96 mx-16 rounded-t-[40px] rounded-b-[50px]">
          <div className="w-full h-20 bg-PrimaryColor2 rounded-[100px] flex items-center justify-around">
            <p className="w-10 p1 text-[#656565] text-center">#</p>
            <div className="w-[170px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item ID</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[350px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Item Name</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Requested Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">
                Release Qty
              </p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Price</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Units</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[160px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Total</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
            <div className="w-[200px] flex flex-col items-center">
              <p className="w-full p1 text-[#656565] text-center ">Remarks</p>
              <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
            </div>
          </div>
          <div>
            {selectedRecord.items.map((batch, index) => {
              // Find the corresponding item in selectedRecord.items

              const Total =
                batch.inventory_release_item_qty * batch.batch_price;
              return (
                <div
                  key={index}
                  className="w-full flex items-start justify-around my-3"
                >
                  <p className="w-10 p1 text-[#656565] text-center">
                    {index + 1}
                  </p>
                  <p className="w-[170px] p1 text-[#656565] text-center">
                    {batch.item_id}
                  </p>
                  <p className="w-[350px] p1 text-[#656565] text-center">
                    {batch.item_name}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.inventory_request_item_qty}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.inventory_release_item_qty}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.batch_price}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {batch.item_measure_unit}
                  </p>
                  <p className="w-[160px] p1 text-[#656565] text-center">
                    {Total.toFixed(2)}
                  </p>
                  <p className="w-[170px] p1 text-[#656565] text-center">
                    {batch.inventory_release_item_description}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className="w-full flex justify-between items-start px-16 pt-3 ">
        <div className="w-[585px]  rounded-[23px] bg-[#F3F8FF] px-10 py-7 flex flex-col justify-between gap-y-7">
          <div className="w-full flex items-center gap-2">
            <p className="p1 text-[#656565] w-[500px]">Branch Owner Name:</p>
            <div className="relative w-[300px]">
              <p className="font-[22px] w-[310px] h-[46px] rounded-[23px] bg-[#FFFFFF] border border-[#00000036] px-7 flex items-center ">
                {selectedRecord.inventory_request_by}
              </p>
            </div>
          </div>
        </div>
        <div className="w-[350px] h-full  flex flex-col gap-y-2">
          <div className="w-[350px] h-full rounded-[23px] bg-[#F3F8FF] flex flex-col px-4 py-3">
            <div className="flex items-center justify-between">
              <p className="p1 text-[#656565]"> Sub Total</p>
              <p className="p1 text-[#656565]">{subTotal.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full items-center justify-end gap-x-10 my-10">
        <button
          className="w-[118px] h-[45px] flex items-center justify-center bg-[#AA4343] border rounded-[23px] px-4 text-white font-bold text-xl"
          onClick={BackHandler}
        >
          Back
        </button>
      </div>

      {/* Print layout */}

      {/* <div className="p-10 hidden">
        <div className="p-10" ref={contentToPrint}>
          <p className="header-1">Inventory Purchase (GRN)</p>

          <div id="printable" className="formal-document">
            <h1 className="document-title">Goods Received Note</h1>
            <div className="document-section">
              <h2 className="section-title">Purchase Order Details</h2>
              <div className="section-content">
                <p>
                  <strong>Purchase Order Id:</strong> {PoData[0].po_id}
                </p>
                <p>
                  <strong>Supplier:</strong> {PoData[0].supplier_name}
                </p>
                <p>
                  <strong>PO Date:</strong> {PoData[0].po_issue_date}
                </p>
              </div>
            </div>

            <div className="document-section">
              <h2 className="section-title">Purchasing Items</h2>
              <table className="items-table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Item Name</th>
                    <th>Requested Qty</th>
                    <th>Supplied Qty</th>
                    <th>Unit</th>
                    <th>Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {poItemsData.length > 0 &&
                    poItemsData.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.item_name}</td>
                        <td>{item.requested_qty}</td>
                        <td>{item.supplied_qty}</td>
                        <td>{item.unit}</td>
                        <td>{item.remark}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
