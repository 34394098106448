import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import axios from "axios";
import usePermissions from "../../../../components/permissions/permission";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import Notification from "../../../../components/notification/notification";
const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
// import FileUpload from "./upload_files";
const Edit_breakdown_records = ({ toggleEditPopup, breakdownRec }) => {
  console.log("breakdownRec => ", breakdownRec);

  const currentDate = moment().format("YYYY-MM-DD");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [driverMap, setDriverMap] = useState({});
  const { REACT_APP_BACKEND_URL } = process.env;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [breakDown, setBreakDown] = useState({
    breakdown_id: "",
    breakdown_date: "",
    breakdown_time: "",
    breakdown_location: "",
    remark: "",
    vehicle_id: "",
    driver_id: "",
  });



  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [employeeNumbers, setEmployeeNumbers] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [driverDetails, setDriverDetails] = useState([]);

  // Define a function to fetch Driver IDs
  const fetchDriverDetails = async () => {
    try {
      const response = await axios(`${backendUrl}/driver/get-details`);

      const formattedData = response.data.data.map((item) => ({
        driver_id: item.driver_id,
        driver_name: item.employee_fullname,
      }));

      setDriverDetails(formattedData);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  const handleVehicleIDChange = (selectedValue) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      vehicle_id: selectedValue,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));

    if (name === "employeeNumber" && value.length > 0) {
      const filtered = employeeNumbers.filter((emp) =>
        emp.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredEmployees(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      employeeNumber: suggestion,
    }));
    setShowSuggestions(false);
    fetchDriverDetails(suggestion);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles([]);
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async () => {
    console.log(breakDown);

    try {
      const response = await axios.put(
        `${backendUrl}/breakdownRec/update?breakdown_id=${breakDown.breakdown_id}`,
        breakDown,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setNotification({
        message: response.data,
        type: "success",
      });
    } catch (error) {
      const errorMessage =
        (error.response.data[0] && error.response.data[0].msg) ||
        error.response.data.error ||
        "An unexpected error occurred";
      setNotification({
        message: errorMessage,
        type: "error",
      });
    }

    setIsSaveConfirmationOpen(false);
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const handleResetForm = () => {
    setBreakDown({
      breakdown_id: "",
      breakdown_date: "",
      breakdown_time: "",
      breakdown_location: "",
      remark: "",
      vehicle_id: "",
      driver_id: "",
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const LocationChange = (fieldName, place) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      breakdown_location: place.label, // store the place label
    }));
  };

  const handleDriverIDChange = (selectedValue) => {
    setBreakDown((prevDetails) => ({
      ...prevDetails,
      driver_id: selectedValue,
    }));
  };

  // Define a function to fetch vehicle IDs
  const fetchVehicleDetails = async () => {
    try {
      const response = await axios(`${backendUrl}/vehicle/allVehicles`);

      const formattedData = response.data.data.map((item) => ({
        vehicle_id: item.vehicle_id,
        vehicle_no_plate_no: item.vehicle_no_plate_no,
      }));
      setVehicleDetails(formattedData);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  useEffect(() => {
    if (breakdownRec) {
      setBreakDown({
        breakdown_id: breakdownRec.breakdown_id,
        breakdown_date: moment(breakdownRec.breakdown_date).format(
          "YYYY-MM-DD"
        ), // Format date for input
        breakdown_time: breakdownRec.breakdown_time,
        remark: breakdownRec.remark,
        vehicle_id: breakdownRec.vehicle_id,
        driver_id: breakdownRec.driver_id,
      });
    }

    fetchVehicleDetails();
    fetchDriverDetails();
  }, []);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="relative w-full p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Breakdown Record</h2>
          <button
            type="button"
            onClick={toggleEditPopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>

                <select
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.vehicle_id}
                  onChange={(e) => handleVehicleIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the vehicle
                  </option>
                  {vehicleDetails.map((items, index) => (
                    <option key={index} value={items.vehicle_id}>
                      {items.vehicle_id} - {items.vehicle_no_plate_no}
                    </option>
                  ))}
                </select>
              </div>

              {/* Breakdown ID */}
              <div>
                <label className="block text-gray-700">Breakdown ID*</label>
                <input
                  type="text"
                  name="breakdown_id"
                  disabled
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID*</label>

                <select
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.driver_id}
                  onChange={(e) => handleDriverIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the driver
                  </option>
                  {driverDetails.map((items, index) => (
                    <option key={index} value={items.driver_id}>
                      {items.driver_id} - {items.driver_name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Breakdown Date */}
              <div>
                <label className="block text-gray-700">Breakdown Date*</label>
                <input
                  type="date"
                  name="breakdown_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* breakdown time */}
              <div>
                <label className="block text-gray-700">Breakdown Date*</label>
                <input
                  type="time"
                  name="breakdown_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.breakdown_time}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label className="block text-gray-700 mb-2">
                  Breakdown Location
                </label>
                {/* <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY} // Replace with your Google Maps API key
                  value={breakDown.breakdown_location}
                  name='breakdown_location'
                  selectProps={{
                    onChange: (place) =>
                      LocationChange("breakdown_location", place),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                /> */}

                <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY}
                  defaultValue={breakDown.breakdown_location} // Set the initial location value
                  selectProps={{
                    onChange: (place) =>
                      LocationChange("breakdown_location", place),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

              {/* Remark */}
              <div>
                <label className="block text-gray-700">Remark*</label>
                <input
                  type="text"
                  name="remark"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={breakDown.remark}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Edit_breakdown_records;
