import React, { useEffect, useState } from "react";
import { IoAdd } from "react-icons/io5";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Papa from "papaparse";
import Notification from "../../../components/notification/notification";

const AccountsPayables = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [selectedValue, setSelectedValue] = useState({
    ageingBasedOn: "Post_Date",
    supplier_id: null,
    supplier_name: null,
    custom_ageing: null,
  });
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableData, setTableData] = useState([]);
  const [allSupplier, setAllSupplier] = useState([]);
  const [totalPayableAmount, setTotalPayableAmount] = useState();
  const [agingCategoryTotals, setAgingCategoryTotals] = useState({});
  const [totalSupplier, setTotalSupplier] = useState(0);
  const [customAgeing, setCustomAgeing] = useState([]);
  const [columnsEnable, setColumnsEnable] = useState(false);
  const [notification, setNotification] = useState({ message: "", type: "" });

  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };

  const getAllSupplier = async () => {
    try {
      const response = await axios.get(
        `${backendUrl}/paymentEntry/getPartyByPartyType`,
        {
          params: { party_type: "Supplier" },
        }
      );
      setAllSupplier(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...selectedValue,
      };

      console.log(params)

      const response = await axios.get(
        `${backendUrl}/payable/accountsPayable/getAccountsPayables`,
        { params }
      );

      console.log("API Response", response.data); // Debugging

      if (response.data) {
        console.log(response.data.totalPayableAmount)
        setTotalPayableAmount(response.data.totalPayableAmount || 0); // Ensure it's correctly named in the response
        setTotalSupplier(response.data.totalSuppliers);
        setAgingCategoryTotals(response.data.agingCategoryTotals || {});
        setTableData(response.data.data);
        setPagination(response.data.pagination);
      } else {
        setTableData([]);
      }
    } catch (error) {
      setTotalPayableAmount(0);
      setTotalSupplier(0);
      console.error("Error fetching table data:", error);
      setTableData([]);

      if (error.status === 400) {
        setNotification({
          message: error.response.data.error,
          type: "error",
        });
      }
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1,
    }));
  };

  const PdfExport = () => {
    const doc = new jsPDF();
    doc.text("Accounts Payable", 14, 10);

    const columns = [
      "Supplier Id",
      "Supplier Name",
      "Posting Date",
      "Due Date",
      "Total Payables",
      ...customAgeing.map((age) => `${age} Days`),
    ];

    const rows = tableData.map((row) => [
      row.supplier_id,
      row.supplier_name,
      new Date(row.posting_date).toLocaleDateString("en-US"),
      new Date(row.due_date).toLocaleDateString("en-US"),
      row.totalPayables,
      ...customAgeing.map((age) => row.agingCategories[`${age}`]),
    ]);

    doc.autoTable({
      startY: 20,
      head: [columns],
      body: rows,
    });

    doc.save("accounts_payable.pdf");
  };

  const ExcelExport = () => {
    const csvData = Papa.unparse(
      tableData.map((row) => ({
        "Supplier Id": row.supplier_id,
        "Supplier Name": row.supplier_name,
        "Posting Date": new Date(row.posting_date).toLocaleDateString("en-US"),
        "Due Date": new Date(row.due_date).toLocaleDateString("en-US"),
        "Total Payables": row.totalPayables,
        ...customAgeing.reduce((acc, age) => {
          acc[`${age} Days`] = row.agingCategories[`${age}`];
          return acc;
        }, {}),
      }))
    );

    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "accounts_payable.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCustomAgeingChange = (e) => {
    const { value } = e.target;

    if (value.trim() === "") {
      // Clear custom ageing
      setCustomAgeing([]); // Clear the custom ageing state
      setColumnsEnable(false); // Disable the columns
      setSelectedValue((prevState) => ({
        ...prevState,
        custom_ageing: "[]", // Set custom_ageing to an empty array in string format
      }));
    } else {
      // Update custom ageing with new values
      const ageingArray = value.split(",");
      setCustomAgeing(ageingArray); // Update the custom ageing state
      setColumnsEnable(false); // Adjust columns based on your logic
      setSelectedValue((prevState) => ({
        ...prevState,
        custom_ageing: `[${value}]`, // Update custom_ageing with the new input
      }));
    }
  };

  useEffect(() => {
    getAllSupplier();
  }, []);

  useEffect(() => {

    if (selectedValue.custom_ageing === "[]") {
      selectedValue.custom_ageing = null
    }
    getTableData();
  }, [rowsPerPage, pagination.current_page, selectedValue,]);

  useEffect(() => {
    setColumnsEnable(true);
  }, [customAgeing]);



  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Accounts Payable
            </p>
          </div>
        </div>
      </div>

      {/* Notification Alert */}
      {/* <Notification
        message={notification.message}
        type={notification.type}
        onClose={handleCloseNotification}
      /> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 mt-10">
        <div className="p-4 bg-gray-500 text-white rounded shadow">
          <p className="text-lg font-semibold">Suppliers Count</p>
          <p className="text-2xl font-bold">{totalSupplier}</p>
        </div>
        <div className="p-4 bg-purple-500 text-white rounded shadow">
          <p className="text-lg font-semibold">Total Payables</p>
          <p className="text-2xl font-bold">{totalPayableAmount}</p>
        </div>
      </div>

      <div className="mt-5">
        <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col md:flex-row md:items-center md:gap-4">
            <div className="w-full mt-6 md:w-[350px]">
              <input
                type="text"
                placeholder="kasun sadaruwan"
                name="supplier_name"
                value={selectedValue.supplier_name}
                onChange={handleChange}
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              />
            </div>

            <div className="w-full mt-6 md:w-[300px]">
              <input
                type="text"
                placeholder="30,40,50,60,70"
                name="custom_ageing"
                value={customAgeing.join(",")}
                onChange={handleCustomAgeingChange}
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
              />
            </div>

            <div className="w-full md:w-[200px]">
              <label
                htmlFor="ageingBasedOn"
                className="block mb-2 text-sm font-medium text-black"
              >
                Ageing Based On
              </label>
              <select
                id="ageingBasedOn"
                name="ageingBasedOn"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={selectedValue.ageingBasedOn}
                onChange={handleChange}
              >
                <option value="Due_Date">Due Date</option>
                <option value="Post_Date">Post Date</option>
              </select>
            </div>

            <div className="w-full md:w-[200px]">
              <label
                htmlFor="supplier_id"
                className="block mb-2 text-sm font-medium text-black"
              >
                Supplier Id
              </label>
              <select
                name="supplier_id"
                className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                value={selectedValue.supplier_id}
                onChange={handleChange}
              >
                <option value=""></option>
                {allSupplier.map((type) => (
                  <option key={type.supplier_id} value={type.supplier_id}>
                    {type.supplier_id}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="flex justify-between gap-4 mt-5">
            <button
              className="flex items-center justify-center p-2 text-white bg-red-500 rounded hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-opacity-50"
              onClick={PdfExport}
            >
              <IoAdd className="mr-2 text-xl" />
              Export as PDF
            </button>
            <button
              className="flex items-center justify-center p-2 text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-opacity-50"
              onClick={ExcelExport}
            >
              <IoAdd className="mr-2 text-xl" />
              Export as Excel
            </button>
          </div>
        </div>

        <div className="overflow-auto mt-5">
          <table className="min-w-full bg-white border border-black rounded-lg">
            <thead>
              <tr>
                <th className="p-4 text-center bg-gray-300 border-b">
                  Supplier Id
                </th>
                <th className="p-4 text-center bg-gray-300 border-b">
                  Supplier Name
                </th>
                <th className="p-4 text-center bg-gray-300 border-b">
                  Posting Date
                </th>
                <th className="p-4 text-center bg-gray-300 border-b">
                  Due Date
                </th>
                <th className="p-4 text-center bg-gray-300 border-b">
                  Total Payables
                </th>

                {/* Dynamically render custom aging headers */}
                {columnsEnable && selectedValue.custom_ageing !== null && (
                  <>
                    {/* Mapping through customAgeing to generate dynamic headers */}
                    {customAgeing.map((age, index) => (
                      <th
                        key={index}
                        className="p-4 text-center bg-gray-300 border-b"
                      >
                        {index === 0
                          ? `0 - ${age} Days`
                          : `${customAgeing[index - 1]} - ${age} Days`}
                      </th>
                    ))}

                    {/* Adding an extra <th> for the last customAgeing value */}
                    <th className="p-4 text-center bg-gray-300 border-b">
                      {customAgeing[customAgeing.length - 1]}+
                    </th>
                  </>
                )}
              </tr>
            </thead>

            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="p-4 text-center border-b">
                      {row.supplier_id}
                    </td>
                    <td className="p-4 text-center border-b">
                      {row.supplier_name}
                    </td>
                    <td className="p-4 text-center border-b">
                      {row.posting_date ? new Date(row.posting_date).toLocaleDateString("en-US") : null}
                    </td>

                    <td className="p-4 text-center border-b">
                      {row.due_date ? new Date(row.due_date).toLocaleDateString("en-US") : null}
                    </td>


                    <td className="p-4 text-center border-b">
                      {row.totalPayables}
                    </td>

                    {/* Dynamically render aging category amounts */}
                    {columnsEnable &&
                      selectedValue.custom_ageing !== null &&
                      Object.entries(row.agingCategories).map(
                        ([ageCategory, amount], index) => (
                          <td key={index} className="p-4 text-center border-b">
                            {amount}
                          </td>
                        )
                      )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={5 + customAgeing.length}
                    className="p-4 text-center"
                  >
                    No data available
                  </td>
                </tr>
              )}

              {/* Add the total row */}
              <tr className="bg-gray-100">
                <td className="p-4 font-bold text-center" colSpan={4}></td>
                <td className="p-4 font-bold text-center">
                  {totalPayableAmount}
                </td>

                {/* Render totals for each aging category */}
                {customAgeing.map((age) => (
                  <td key={age} className="p-4 font-bold text-center">
                    {agingCategoryTotals[`${age}`] || 0}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AccountsPayables;
