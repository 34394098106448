import React, { useEffect, useRef, useState } from "react";
import { IoAdd } from "react-icons/io5";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IoMdAddCircleOutline } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import axios from "axios";
import Add_purchase_popup from "./add_purchase_invoice_popup";
import Edit_purchase_popup from "./edit_purchase_invoice_popup";
import usePermissions from "../../../components/permissions/permission";
import {BiDetail} from "react-icons/bi";
import PurchaseInvoiceDetailModal from "./PurchaseInvoiceDetailModal";
import Cookies from "js-cookie";

const Purchase_invoice = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
  const [seletedValue, setSeletedValue] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20); // Default rows per page
  const [tableData, setTableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [poIdsWhichHasInvoices, setPoIdsWhichHasInvoices] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    page_size: 20,
    total: 0,
    total_pages: 0,
  });

  const { hasPermission } = usePermissions();
  
  const actionsRef = useRef(null); // Ref for the "actions" element
  const tableRef = useRef(null); // Ref for the table element

  const handleRowSelect = (id) => {
    if (selectedRow === id) {
      setSelectedRow(null);
      setDropdownVisible(false);
    } else {
      setSelectedRow(id);
      setDropdownVisible(true);
    }
  };

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const editePopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  const handleEdit = () => {
    console.log("Edit row: ", selectedRow);
    setIsOpenEdit(true);
  };

  const handleDelete = async () => {
    if (selectedRow) {
      console.log("Delete row: ", selectedRow);
      try {
        const response = await axios.delete(`${backendUrl}/payable/purchaseInvoice/deletePurchaseInvoice/${selectedRow._id}`, {
          data: { deleted_by: Cookies.get("employee_no") }
        });
        setDropdownVisible(false);
        setSelectedRow(null);
        await getTableData();
      } catch (error) {
        console.error("Error deleting journal entry:", error);
      }
    }
  };

  const handleDetail = async () => {
    try {
      if (selectedRow) {
        const response = await axios.get(`${backendUrl}/payable/purchaseInvoice/get/${selectedRow.invoice_id}`, );
        if (response.data && response.data.invoice) {
          setSelectedInvoice(response.data.invoice); // Set the selected invoice data
          setIsDetailsModalOpen(true); // Open the modal
        }
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSeletedValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Function to handle fetching table data
  const getTableData = async () => {
    try {
      const params = {
        page_size: rowsPerPage,
        page: pagination.current_page,
        ...seletedValue, // Include your filter parameters
      };

      const response = await axios.get(
        `${backendUrl}/payable/purchaseInvoice/getAllPurchaseInvoicesByFilters`,
        { params }
      );

      // Ensure you set both data and pagination state
      if (response.data) {
        console.log(response.data);
        setTableData(response.data.data); // Set the data array
        setPagination(response.data.pagination); // Set the pagination info
      } else {
        console.error("Unexpected response format:", response.data);
        setTableData([]);
      }
    } catch (error) {
      console.error("Error fetching sample details:", error);
      setTableData([]);
    }
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current_page: 1, // Reset to first page whenever rows per page changes
    }));
  };

  // Function to handle PDF download
  const downloadPDF = async (id) => {
    console.log(id);
    try {
      // Fetch the PDF from the backend (change the URL as needed)
      const response = await axios.get(
        `${backendUrl}/payable/purchaseInvoice/downloadPurchaseInvoiceDoc/${id}`,
        {
          responseType: "blob", // Ensure the response is in Blob format (binary)
        }
      );

      // Create a Blob from the PDF response
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "purchase_invoice"; // Set the file name for the download
      document.body.appendChild(link); // Append the link to the document
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link after the download
    } catch (error) {
      console.log("Error downloading the PDF:", error);
    }
  };

    const handleClickOutside = (event) => {
      if (actionsRef.current && !actionsRef.current.contains(event.target)) {
        setSelectedRow(null);
        setDropdownVisible(false);
      }
    };
  
    // Close dropdown and deselect row if clicking outside the table
    useEffect(() => {
      getPOsWhichHasInvoices();
      document.addEventListener("mousedown", handleClickOutside);
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

  const getPOsWhichHasInvoices = async () => {
    try {
      const response = await axios.get(
          `${backendUrl}/payable/purchaseInvoice/getPOsWhichHasInvoices`
      );
      console.log(response.data);
      setPoIdsWhichHasInvoices(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePInvSubmit = async () => {
    try {
      const response = await axios.put(
          `${backendUrl}/payable/purchaseInvoice/submitOrApprovePurchaseInvoice/${selectedRow._id}`, {
            status: "Submitted",
            action_by: "USER-1445"
          }
      );
      await getTableData();
      setDropdownVisible(!dropdownVisible)
    } catch (error) {
      console.log(error);
    }
  };

  const handlePInvApprove = async () => {
    try {
      const response = await axios.put(
          `${backendUrl}/payable/purchaseInvoice/submitOrApprovePurchaseInvoice/${selectedRow._id}`, {
            status: "Approved",
            action_by: "USER-1445"
          }
      );
      await getTableData();
      setDropdownVisible(!dropdownVisible)
    } catch (error) {
      console.log(error);
    }
  };

  const handlePInvReject = async () => {
    try {
      const response = await axios.put(
          `${backendUrl}/payable/purchaseInvoice/submitOrApprovePurchaseInvoice/${selectedRow._id}`, {
            status: "Rejected",
            action_by: "USER-1445"
          }
      );
      await getTableData();
      setDropdownVisible(!dropdownVisible)
    } catch (error) {
      console.log(error);
    }
  };

  // Call the API whenever rowsPerPage or currentPage changes
  useEffect(() => {
    getTableData();
  }, [rowsPerPage, pagination.current_page, seletedValue]);

  return (
    <div className="mx-10 mt-5 text-xl">
      <div className="overflow-visible">
        <div className="flex items-center justify-between mt-6">
          <div>
            <p className="text-[30px] font-semibold text-black">
              Purchace Invoice
            </p>
          </div>
        </div>

        <div className="mt-5">
          <div className="flex flex-col gap-4 mt-5 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col md:flex-row md:items-center md:gap-4">
              {/*  Supplier Name	 */}
              <div className="w-full md:w-[250px] relative">
                <label
                  htmlFor="supplier_name"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Supplier Name
                </label>
                <input
                  id="supplier_name"
                  name="supplier_name"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                />
              </div>
              {/*invoice ID*/}
              <div className="w-full md:w-[250px] relative">
                <label
                  htmlFor="invoice_number"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Invoice Number
                </label>
                <input
                  id="invoice_number"
                  name="invoice_number"
                  type="text"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                />
              </div>

              {/* Status */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="status"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Status
                </label>
                <select
                    id="status"
                    name="status"
                    className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                    onChange={handleChange}
                >
                  <option value=""></option>
                  <option value="Draft">Draft</option>
                  <option value="Submitted">Submitted</option>
                  <option value="Approved">Approved</option>
                  <option value="Rejected">Rejected</option>
                  <option value="Unpaid">Unpaid</option>
                  <option value="Paid">Paid</option>
                  <option value="Overdue">Overdue</option>
                </select>
              </div>

              {/* PO ID */}
              <div className="w-full md:w-[200px] relative">
                <label
                  htmlFor="po_id"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  PO ID
                </label>
                <select
                  id="po_id"
                  name="po_id"
                  className="w-full p-2 border border-black rounded focus:ring-2 focus:ring-black"
                  onChange={handleChange}
                >
                  <option value=""></option>
                  {poIdsWhichHasInvoices && poIdsWhichHasInvoices.length > 0 ? (
                      poIdsWhichHasInvoices.map((po) => (
                          <option key={po.po_id} value={po.po_id}>
                            {po.po_id}
                          </option>
                      ))
                  ) : (
                      <option disabled>No POs available</option>
                  )}
                </select>
              </div>
            </div>

            {/* Right-Side Button or Dropdown */}
            <div className="relative flex items-center gap-4 md:mt-0"    
             id="actions"
              ref={actionsRef} >
              {selectedRow ? (
                // Action Dropdown
                <div className="relative">
                  <button
                    onClick={() => setDropdownVisible(!dropdownVisible)}
                    className="px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                  >
                    Actions
                  </button>
                  {dropdownVisible && (
                      <div className="absolute right-0 z-20 mt-2 bg-white border border-black rounded shadow-lg">
                        {hasPermission(3102) && (
                            <button
                                onClick={handleEdit}
                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <FaEdit className="mr-2"/> Edit
                            </button>
                        )}
                        {hasPermission(3103) && (
                            <button
                                onClick={handleDelete}
                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <FaTrash className="mr-2"/> Delete
                            </button>
                        )}
                        <button
                            onClick={handleDetail}
                            className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                        >
                          <BiDetail className="mr-2"/> Details
                        </button>
                        {hasPermission(3104) && (
                            <button
                                onClick={handlePInvSubmit}
                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <IoMdAddCircleOutline className="mr-2"/> Submit
                            </button>
                        )}
                        {hasPermission(3105) && (
                            <button
                                onClick={handlePInvApprove}
                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <TiTick className="mr-2"/> Approve
                            </button>
                        )}
                        {hasPermission(3107) && (
                            <button
                                onClick={handlePInvReject}
                                className="flex items-center w-full px-4 py-2 text-xl text-black hover:bg-gray-100"
                            >
                              <IoIosCloseCircleOutline className="mr-2"/> Reject
                            </button>
                        )}
                      </div>
                  )}
                </div>
              ) : (
                  // Add Button
                  <div>
                    {hasPermission(3101) && (
                        <button
                            className="flex items-center gap-2 px-4 py-2 text-white bg-black rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
                            onClick={togglePopup}
                        >
                          <IoAdd className="text-xl"/>
                          New
                        </button>
                    )}
                  </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 overflow-x-auto mt-20" ref={tableRef}>
        {/* Scrollable Table Container with Increased Height */}
        <div className="max-h-[600px] overflow-y-auto">
          {/* TailwindCSS Table */}
          <table className="min-w-full bg-white border border-black mt-12">
            <thead className="bg-gray-200">
              {/* Background color to distinguish header */}
              <tr>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Invoice Id
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Invoice Number
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Supplier Name
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Status
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Approved Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Invoice Date
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Grand Total
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  PO ID
                </th>
                <th className="px-6 py-3 font-bold text-left text-black uppercase border-b border-black sticky top-0 bg-gray-200">
                  Document
                </th>
              </tr>
            </thead>
            <tbody>
            {tableData.map((row, index) => (
                <tr
                    key={index}
                    className={`${
                        selectedRow === row
                            ? "bg-blue-600 text-white"
                            : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleRowSelect(row)}
                >
                  <td className="px-6 py-4 border-b border-black">{row.invoice_id}</td>
                  <td className="px-6 py-4 border-b border-black">{row?.invoice_number}</td>
                  <td className="px-6 py-4 border-b border-black">{row?.supplier_name}</td>
                  <td className="px-6 py-4 border-b border-black">
                    <span
                        className={
                          row.status === "Approved"
                              ? "text-green-500 font-semibold"
                              : "text-red-500 font-semibold"
                        }
                    >
                      {row.status}
                    </span>
                  </td>

                  <td className="px-6 py-4 border-b border-black">
                    {row?.approved_date ? new Date(row?.approved_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short", // Use "short" for abbreviated month names
                      day: "2-digit",
                    }) : ""}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {new Date(row.invoice_date).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short", // Use "short" for abbreviated month names
                      day: "2-digit",
                    })}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.total_amount}
                  </td>
                  <td className="px-6 py-4 border-b border-black">
                    {row.po_id}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900 whitespace-nowrap">
                    <button
                        className="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 focus:ring-opacity-75 transition duration-300"
                        onClick={() => downloadPDF(row._id)}
                    >
                      Download PDF
                    </button>
                  </td>
                </tr>
            ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 gap-2">
        {[20, 40, 60].map((size) => (
            <button
                key={size}
                onClick={() => handleRowsPerPageChange(size)}
                className={`px-4 py-2 ${
              rowsPerPage === size ? "bg-gray-800 text-white" : "bg-gray-300"
            } rounded`}
          >
            {size}
          </button>
        ))}
        <button
          onClick={() =>
            setPagination((prevPagination) => ({
              ...prevPagination,
              current_page: Math.min(
                prevPagination.current_page + 1,
                prevPagination.total_pages
              ),
            }))
          }
          disabled={pagination.current_page >= pagination.total_pages}
          className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-black"
        >
          Load More
        </button>
      </div>

      {/* Add purchase invoice entry Popup */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Add_purchase_popup togglePopup={togglePopup} />
          </div>
        </div>
      )}

      {/* edit purchase invoice entry Popup */}
      {isOpenEdit && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <Edit_purchase_popup editePopup={editePopup} row = {selectedRow} refreshTable={getTableData} />
          </div>
        </div>
      )}

      <div>
        {/* Render other UI elements here */}

        <PurchaseInvoiceDetailModal
            invoice={selectedInvoice}
            isOpen={isDetailsModalOpen}
            onClose={() => setIsDetailsModalOpen(false)}
        />
      </div>

    </div>
  );
};

export default Purchase_invoice;
