import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import PieChart from "./pie_chart";
import { FaCar } from "react-icons/fa6";
import Navbar from "../../../components/navbar/navbar";
import axios from "axios";
import BarChart from "./bar_chart";

const Vehicle_Count_Dashboard = () => {
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [currentTime, setCurrentTime] = useState(moment().format("h:mm:ss a"));
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([0]);
  const [picChatData, setPicChartData] = useState([0]);

  const departments = ["Car", "Van", "All Vehicle"];

  const [selectedDepartment, setSelectedDepartment] = useState("All Vehicle");

  const currentDate = moment().format("MMMM Do YYYY");

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen); // Toggle the isOpen state
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment().format("h:mm:ss a"));
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  useState("All Departments");

  const rowsPerPage = 5;

  const getVehicleCountDeta = async () => {
    try {
      let params = {}; // Change const to let, initialize as an empty object
  
      // Conditionally set params based on selectedDepartment
      if (selectedDepartment === "All Vehicle") {
        params = {
          vehicle_type: null, // Set vehicle_type to null for "All Vehicle"
        };
      } else {
        params = {
          vehicle_type: selectedDepartment, // Use selectedDepartment for specific vehicle type
        };
      }
  
      console.log(params); // Debugging log to check params
  
      // Make the GET request with the correct params
      const response = await axios.get(
        `${backendUrl}/vehicle/getVehicleCountDashboard`,
        {
          params, // Pass the params object in the GET request
        }
      );
  
      // Set the response data to your state
      setData(response.data);
      setPicChartData(response.data.picData);
    } catch (error) {
      // Log the error to debug in case of failure
      console.error("Error fetching vehicle count details:", error);
    }
  };
  


 
  // const getBarChartDeta = async () => {
  //   try {
  //     const response = await axios.get(`${backendUrl}/vehicle/vehicleCount`);
  //     console.log(response.data);

  //     setDashbordData(response.data.totalCount);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  const handleDepartmentSelect = (dept) => {
    setSelectedDepartment(dept); // Set the selected department
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  useEffect(() => {
    getVehicleCountDeta();
  }, [selectedDepartment]);

  return (
    <div className="mx-10 mt-5">
      <Navbar />
      <div className="overflow-hidden">
        <div className="flex items-center justify-between gap-6 mt-6">
          <div>
            <p className="text-[30px] font-semibold">Vehicle Count Dashboard</p>
            <div className="flex items-center gap-6 align-left">
              <p className="text-[15px] font-semibold text-black ">
                Vehicle Fleet Management System
              </p>
              <p className="text-[15px] font-semibold text-black">
                Inventory Management
              </p>
              <p className="text-[15px] font-semibold text-[#ABDCB0]">
                Vehicle Count Dashboard
              </p>
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div>
              <div className="text-[#069855] text-[20px] font-bold">
                {currentDate}
              </div>
            </div>
            <div className="text-[20px] font-bold">{currentTime}</div>
          </div>
        </div>
        {/* <div className="mt-5">
          <div>
            <button
              className="bg-[#069855] text-white p-3 rounded-[12px] font-semibold flex items-center gap-2"
              onClick={togglePopup}
            >
              <IoAdd /> Add fuel cost data
            </button>
          </div>
        </div> */}
      </div>
      <div className="mt-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4 mt-5">
            <div className="relative">
              {/* <input
                className="border  rounded-xl p-2 pl-10 w-[325px]"
                placeholder="Search by vehicle ID"
                // value={searchInput}
                // onChange={(e) => setSearchInput(e.target.value)}
              /> */}
              {/* <CiSearch className="absolute text-gray-500 transform -translate-y-1/2 top-1/2 left-3" /> */}
            </div>
            {/* <div>
              <button className="p-2 border border-black rounded-[12px]">
                <div className="flex items-center gap-3">
                  Filter <RiMenu5Fill />
                </div>
              </button>
            </div> */}

            <div className="relative">
              <button
                className="p-3 border border-black rounded-[12px]"
                onClick={toggleDropdown}
              >
                <div className="flex items-center gap-3">
                  <div>{selectedDepartment}</div>
                  <MdKeyboardArrowDown />
                </div>
              </button>
              {isDropdownOpen && (
                <div className="absolute w-48 mt-2 bg-white border border-gray-300 rounded-lg shadow-lg">
                  <ul>
                    {departments.map((dept, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleDepartmentSelect(dept)} // Handle click
                      >
                        {dept}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-center gap-2 text-black">
            {/* <div className="flex items-center space-x-2 bg-white rounded-[20px] px-4 py-2 shadow-sm border border-black">
              <FaCalendarAlt className="w-5 h-5 text-gray-400" />

              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="Start Date"
              />

              <span className="text-gray-400">-</span>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="d MMM, yyyy"
                className="text-sm text-gray-600 focus:outline-none"
                value="End Date"
              />
            </div> */}

            {/* <button
              className="p-3 border border-black rounded-[12px]"
              onClick={exportToCSV}
            >
              <div className="flex items-center gap-3">
                <div>Export CSV</div>
                <RiDownloadCloud2Line />
              </div>
            </button> */}
          </div>
        </div>
      </div>

      {/* <div className="overflow-x-auto">
        <Maintenance_records_table/>
      </div> */}

      {/* card layer */}
      <div className="mt-10">
        <div className="grid grid-cols-3 gap-5 grid-flow-rows">
          {/* card 1 */}
          <div className="p-3 border border-black rounded-xl">
            <div className="flex items-center justify-between mt-5">
              <div>
                <FaCar className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Total Vehicle Count</p>
            <p className="text-[30px] font-semibold">{data.TOTAL}</p>
          </div>
          {/* card 2 */}
          <div className="p-3 border border-black rounded-xl">
            <div className="flex items-center justify-between mt-5">
              <div>
                <FaCar className="w-8 h-8" />
              </div>

              <div>
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">Operational Vehicle Count</p>
            <p className="text-[30px] font-semibold">{data["OPERATIONAL"]}</p>
          </div>
          {/* card 3 */}
          <div className="p-3 border border-black rounded-xl">
            <div className="flex items-center justify-between mt-5">
              <div>
                <FaCar className="w-8 h-8" />
              </div>

              <div className="">
                <p className="font-semibold text-green-400">{currentDate}</p>
              </div>
            </div>
            <p className="mt-5 font-semibold">
              Under Maintenance Vehcile Count
            </p>
            <p className="text-[30px] font-semibold">
              {data["UNDER MAINTENANCE"]}
            </p>
          </div>
        </div>
      </div>
      {/* lower layer */}

      {/* Add Pie Chart and Bar Chart */}
      <div className="mt-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <h1>Vehicle Count</h1>
            <PieChart data={picChatData} />
          </div>

          <div>
            <BarChart />
          </div>
        </div>
      </div>

      {/* Add Fuel Consumption Process Popup */}
      {/* {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="w-3/4 p-6 bg-white rounded-lg shadow-lg">
            <FuelCostUpdateDataPopup togglePopup={togglePopup} />
          </div>
        </div>
      )} */}
    </div>
  );
};
export default Vehicle_Count_Dashboard;
