/** @format */

import React, { useState, useEffect } from "react";
import { MdOutlineNotStarted } from "react-icons/md";
import Moment from "react-moment";
import moment from "moment-timezone";
import StartStage from "./WarehouseStages"; // Import StartStage component
import usePermissions from "../../../components/permissions/permission";
import Cookies from "js-cookie";

function WarehouseStageData({ productionId, jobId, jobname }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { hasPermission } = usePermissions();
  const [showPopup, setShowPopup] = useState(false);
  const [showStartStage, setShowStartStage] = useState(false); // State to control StartStage visibility
  const [stageName, setStageName] = useState("");
  const [supervisorNote, setSupervisorNote] = useState("");
  const [setEstimateTime] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const userId = Cookies.get("employee_no");
  const [stageData, setStageData] = useState([]);
  const [jobData, setJobData] = useState(null); // State to store job data
  const [selectedOption, setSelectedOption] = useState("");
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showRejectConfirmation, setShowRejectConfirmation] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const itemsPerPage = 4; // Update items per page to 3
  const [selectedStageId, setSelectedStageId] = useState(null); // Store selected stage ID
  const [rejectReason, setRejectReason] = useState(""); // State to store rejection reason
  const [productDescription, setProductDescription] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [units, setUnits] = useState([]);
  const [secondaryUnit, setSecondaryUnit] = useState([]);
  const [unitQuantity, setUnitQuantity] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("");
  const [secondaryUnitQuantity, setSecondaryUnitQuantity] = useState("");
  const [selectedSecondaryUnit, setSelectedSecondaryUnit] = useState("");
  const handleStartStage = (stageId) => {
    setSelectedStageId(stageId); // Store selected stage ID
    setShowStartStage(true);
  };
  // Handler function for product_description change
  const handleProductDescriptionChange = (event) => {
    setProductDescription(event.target.value);
  };
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleStatusChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value === "Approve") {
      setShowApproveConfirmation(true);
    } else if (e.target.value === "Reject") {
      setShowRejectConfirmation(true);
    }
  };
  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/product/finalProductionProductData?_id=${jobId}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const jobData = await response.json();
        setJobData(jobData);
        console.log("job Data0", jobData);
      } catch (error) {
        console.error("Error fetching job data:", error);
      }
    };

    const fetchStageData = () => {
      if (productionId) {
        fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/stage/get?production_id=${productionId}`
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((data) => {
            setStageData(data); // Update stageData state with fetched data
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    };

    fetchJobData(); // Call the fetchJobData function
    fetchStageData(); // Call the fetchStageData function
  }, [productionId, jobId, jobname]);

  // Calculate total pages based on number of items per page
  const totalPages = Math.ceil(stageData.length / itemsPerPage);

  // Calculate the start and end indexes for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the current page's data
  const currentPageData = stageData.slice(startIndex, endIndex);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleBackToTable = () => {
    setShowStartStage(false); // Show table when back button is clicked
  };
  const handleMovetoproduction = async () => {
    // Extract values from input fields
    const productFinalUnit =
      document.getElementById("product_final_unit").value;

    // Construct the request body
    const requestBody = {
      production_id: productionId,
      product_name: jobname,
      secondary_product_measuer_unit_id:
        selectedSecondaryUnit?.unit_production_id,
      single_unit_quantity: secondaryUnitQuantity,
      product_final_unit: productFinalUnit,
      product_create_by: userId,
    };
    console.log("Request Body:", requestBody);

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/product/moveproduction`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Production moved successfully");
        window.alert("Production moved to warehouse successfully");
        setShowSuccessMessage(true);
        // Refresh the page after reset
        window.location.reload();
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      } else {
        const responseData = await response.json();
        if (responseData.message === "Invalid unit conversion") {
          window.alert("Invalid unit conversion");
        } else if (responseData.message === "Over getting Item") {
          window.alert("Over getting Item");
        } else {
          console.error("Failed to move production:", responseData.message);
        }
      }
    } catch (error) {
      console.error("Error moving production:", error);
    }
  };

  // Function to handle adjustment submission
  const handleAdjustmentSubmit = async () => {
    try {
      const actualQuantity = document.getElementById("actual_quantity").value;
      const adjustmentReason =
        document.getElementById("adjutment_reason").value;

      const requestBody = {
        adjustment_quantity: actualQuantity,
        current_quantity: jobData.latest_adjustment_quantity,
        adjustment_reason: adjustmentReason,
        adjustment_job_id: jobId,
        adjust_by: userId,
        job_approve_level_1: "PENDING",
        job_approve_level_2: "PENDING",
        job_approve_level_3: "PENDING",
        job_approved_level1_by: "PENDING",
        job_approved_level2_by: "PENDING",
        job_approved_level3_by: "PENDING",
      };

      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/product/finalProductionQuantityAdjustment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Adjustment submitted successfully");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      } else if (response.status === 400) {
        const contentType = response.headers.get("content-type");
        let message;
        if (contentType && contentType.includes("application/json")) {
          const data = await response.json();
          message = data.message || "Bad Request";
        } else {
          message = await response.text();
        }
        alert(message);
      } else {
        console.error("Failed to submit adjustment");
      }
    } catch (error) {
      console.error("Error submitting adjustment:", error);
    }
  };

  // Function to handle Production End submission
  const handleProductionEnd = async () => {
    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/production/product/finalProductionEnd?production_id=${productionId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Production ID: ", productionId);

      if (response.ok) {
        console.log("Adjustment submitted successfully");
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 2000);
      } else {
        console.error("Failed to submit adjustment");
      }
    } catch (error) {
      console.error("Error submitting adjustment:", error);
    }
  };

  useEffect(() => {
    // Function to fetch units from the API
    const fetchUnits = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/unit/get`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch units");
        }
        const unitsData = await response.json();
        // Update the units state with fetched data
        setUnits(unitsData);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    // Call the fetchUnits function when the component mounts
    fetchUnits();
  }, []);

  useEffect(() => {
    // Function to fetch Secondary units from the API
    const fetchSecondaryUnits = async () => {
      console.log("job id", jobId);
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/production/warehouse/get-production-units?job_id=${jobId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch units");
        }
        const secondaryUnitsData = await response.json();
        // Update the Secondaryunits state with fetched data
        setSecondaryUnit(secondaryUnitsData.data);
        console.log("setSecondaryUnit", secondaryUnitsData.data);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    // Call the fetchSecondaryUnits function when the component mounts
    fetchSecondaryUnits();
  }, [jobId]);

  useEffect(() => {
    const fetchUnitQuantity = async () => {
      console.log("Fetching units for jobId:", jobId);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/v1/production/warehouse/get-job-unit-quantity?job_id=${jobId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch units");
        }
        const secondaryUnitsData = await response.json();
        setUnitQuantity(secondaryUnitsData.data);
        console.log("Fetched Unit Quantity:", secondaryUnitsData.data);
      } catch (error) {
        console.error("Error fetching units:", error);
      }
    };

    if (jobId) {
      setUnitQuantity([]); // Reset state before fetching new data
      fetchUnitQuantity(); // Fetch new data based on the updated jobId
    }
  }, [jobId]);

  const handleUnitChange = (e) => {
    const selectedId = e.target.value;
    const selectedUnit = secondaryUnit.find(
      (unit) => unit.unit_production_id === selectedId
    );
    setSelectedSecondaryUnit(selectedUnit || null); // Set the selected unit or null if not found
  };

  useEffect(() => {
    if (selectedSecondaryUnit?.job_production_create_uint_quantity) {
      setSecondaryUnitQuantity(
        selectedSecondaryUnit.job_production_create_uint_quantity
      );
    }
  }, [selectedSecondaryUnit]);
  return (
    <div className="overflow-y-auto h-[calc(100vh-80px)] overflow-x-hidden">
      {/* Added vertical scrolling */}
      <div className="box-border w-full p-4 bg-[#c6d2b05e] ml-9 mt-5 relative rounded-b-[60px]">
        {showStartStage ? (
          // Render StartStage component instead of table
          <StartStage
            onBackToTable={handleBackToTable}
            stageId={selectedStageId}
          />
        ) : (
          <>
            <div className="relative ml-5">
              <div className="grid grid-flow-row-dense grid-cols-6 overflow-y-auto">
                <div className="col-span-1">
                  <div className="absolute top-0 left-0 w-[150px] h-[150px] bg-[#99BC85] rounded-full"></div>
                  <div className="absolute top-3 left-3 w-[126px] h-[126px] bg-white rounded-full">
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <p className="text-2xl font-semibold text-center">
                        {stageData.length} Stages
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div className="col-span-5">
                  <div className="ml-10">
                    <h2 className="text-2xl font-semibold mb-4 rounded-lg ml-[100]">
                      Production ID - {productionId}
                    </h2>
                    <div className="">
                      <div className="grid grid-flow-row-dense grid-cols-6 overflow-y-auto">
                        <div className="col-span-3">
                          <div className="flex">
                            <label className="mb-2">Job ID: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobId}
                            </label>
                          </div>
                          <div className="flex">
                            <label className="mb-2">Quantity: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobData ? jobData.job_quantity : ""}
                            </label>
                          </div>
                          <div className="flex">
                            <label className="mb-2">Unit Symbol: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobData ? jobData.unit_symbol : ""}
                            </label>
                          </div>
                        </div>

                        <div className="col-span-3">
                          <div className="flex">
                            <label className="mb-2">Reject Count: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobData ? jobData.reject_count : ""}
                            </label>
                          </div>
                          <div className="flex">
                            <label className="mb-2">Approved Count: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobData ? jobData.approve_count : ""}
                            </label>
                          </div>
                          <div className="flex">
                            <label className="mb-2">Left Quantity: </label>{" "}
                            <label className="font-semibold mb-2">
                              {jobData
                                ? jobData.latest_adjustment_quantity
                                : ""}
                            </label>
                          </div>
                        </div>

                        {/* Render the unit data dynamically */}
                        <div className="col-span-6 mt-4">
                          <h3 className="text-xl font-semibold mb-2">
                            Unit Details
                          </h3>
                          <div className="grid grid-cols-6 gap-4">
                            {unitQuantity.length > 0 ? (
                              unitQuantity.map((unit, index) => (
                                <div
                                  key={index}
                                  className="col-span-2 border p-4 rounded-md shadow-md"
                                >
                                  <p className="font-semibold">
                                    Unit Name: {unit.unit_name}
                                  </p>
                                  <p>
                                    Created Quantity:{" "}
                                    {unit.job_production_create_uint_quantity}
                                  </p>
                                  <p>
                                    Completed Quantity:{" "}
                                    {unit.job_production_complete_qty}
                                  </p>
                                </div>
                              ))
                            ) : (
                              <p className="col-span-6 text-center">
                                No unit data available
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" mt-10 line border-t-2 border-gray-300 w-[100%] h-3"></hr>
            <div>
              <div className="mt-10 ml-10">
                <div className="grid grid-cols-6 gap-4 overflow-y-auto">
                  <div className="col-span-4 flex flex-col">
                    <div className="mt-1 flex items-center">
                      <label className="mr-4">Product Name: </label>{" "}
                      <label className="mr-4 ">{jobname}</label>
                    </div>
                    <div className="mt-1 flex items-center">
                      <label className="mr-4">Product Measure Unit ID: </label>{" "}
                      <select
                        value={selectedSecondaryUnit?.unit_production_id || ""}
                        onChange={handleUnitChange}
                        className="pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="">Select Unit</option>
                        {/* Map over the units and create options for each */}
                        {secondaryUnit.map((secondaryUnit) => (
                          <option
                            key={secondaryUnit.unit_production_id}
                            value={secondaryUnit.unit_production_id}
                          >
                            {secondaryUnit.unit_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="mt-1 flex items-center">
                      <label className="mr-4">Single Unit Quantity: </label>{" "}
                      <input
                        type="number"
                        name="single_unit_quantity"
                        value={secondaryUnitQuantity || ""}
                        onChange={(e) =>
                          setSecondaryUnitQuantity(e.target.value)
                        }
                        className="ml-[30px] w-[50%] rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                      />
                    </div>
                    <div className="mt-1 flex items-center">
                      <label className="mr-4">Product Final Unit </label>{" "}
                      <select
                        id="product_final_unit"
                        value={selectedUnit}
                        onChange={(e) => setSelectedUnit(e.target.value)}
                        className="ml-[50px] pl-5 px-4 block w-60 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      >
                        <option value="">Select Unit</option>
                        {/* Map over the units and create options for each */}
                        {units.map((unit) => (
                          <option key={unit._id} value={unit._id}>
                            {unit.unit_name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <button
                      onClick={handleMovetoproduction}
                      className="bg-[#99BC85] text-white w-[200px] px-4 py-2 rounded-md mr-2 mt-5"
                    >
                      Submit
                    </button>

                    <div className="mt-8 flex items-center">
                      <label className="mr-4">Actual Product Quantity: </label>
                      <input
                        type="number"
                        name="actual_quantity"
                        id="actual_quantity"
                        className="w-[50%] rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                      />
                    </div>
                    <div className="mt-1 flex items-center">
                      <label className="mr-4">Adjustment Reason: </label>
                      <input
                        type="text"
                        name="adjutment_reason"
                        id="adjutment_reason"
                        className="h-[60px] ml-[33px] w-[50%] rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-2"
                      />
                    </div>

                    <button
                      onClick={handleAdjustmentSubmit}
                      className="bg-[#99BC85] text-white px-4 py-2 rounded-md mt-2"
                    >
                      Submit Adjustment
                    </button>

                    <button
                      onClick={handleProductionEnd}
                      className="bg-[#99BC85] text-white w-[200px] px-4 py-2 rounded-md mr-2 mt-5"
                    >
                      End Production
                    </button>

                    {showSuccessMessage && (
                      <div className="text-green-600 text-sm mt-2">
                        Submitted successfully!
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="overflow-x-auto mt-10 overflow-y-auto"
              style={{ width: "100%" }}
            >
              <table className="border-collapse border border-gray-200 rounded-lg ml-6">
                <thead>
                  <tr className="bg-[#99BC85]">
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Stage ID
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Stage Name
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Progress
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Estimated Time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Exceed Time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Start time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      End time
                    </th>
                    <th className="border border-gray-200 px-10 py-4 w-[500px] text-center">
                      Start Stage
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {currentPageData.map((row, index) => (
                    <tr
                      key={index}
                      className="border border-gray-200 px-4 py-2"
                      onClick={() => handleStartStage(row._id)} // Pass _id to handleStartStage function
                    >
                      <td className="text-center">{row._id}</td>
                      <td className="text-center">{row.stage_name}</td>
                      <td className="text-center">{row.stage_estimate_time}</td>
                      <td className="text-center">
                        <Moment tz="Asia/Colombo" format="YYYY/MM/DD HH:mm:ss">
                          {moment(row.stage_start_at).tz("Asia/Colombo")}
                        </Moment>
                      </td>
                      <td className="text-center">
                        {row.stage_supervisor_note}
                      </td>
                      <td className="text-center">
                        <Moment tz="Asia/Colombo" format="YYYY/MM/DD HH:mm:ss">
                          {moment(row.stage_start_at).tz("Asia/Colombo")}
                        </Moment>
                      </td>
                      <td className="text-center">
                        <Moment tz="Asia/Colombo" format="YYYY/MM/DD HH:mm:ss">
                          {moment(row.stage_end_at).tz("Asia/Colombo")}
                        </Moment>
                      </td>
                      <td className="text-center">
                        <button className="bg-[#99BC85] hover:bg-[#86b46c] text-white font-bold p-2 px-2s rounded-full">
                          <MdOutlineNotStarted size={20} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <div className="flex justify-center mb-6">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                className={`px-3 py-1 rounded-md mr-2 ${
                  currentPage === 1
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-gray-300 cursor-pointer"
                }`}
                disabled={currentPage === 1}
              >
                {"<"}
              </button>
              {[...Array(totalPages)].map((_, page) => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  className={`px-3 py-1 rounded-md mr-2 ${
                    page + 1 === currentPage ? "bg-gray-300" : "bg-gray-200"
                  }`}
                >
                  {page + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                className={`px-3 py-1 rounded-md mr-2 ${
                  currentPage === totalPages
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-gray-300 cursor-pointer"
                }`}
                disabled={currentPage === totalPages}
              >
                {">"}
              </button>

              <button
                onClick={togglePopup}
                className="cursor-pointer font-bold opacity-45 ml-[150px] mt-2"
              >
                + Add New Stage
              </button>
            </div>
            {/* End Pagination */}
          </>
        )}
        {/* Popup */}
        {showPopup && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-8 rounded-md">
              <h2 className="text-xl font-bold mb-4">Add New Stage</h2>
              <div className="mb-4">
                <label className="block mb-2">Stage Name:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={stageName}
                  onChange={(e) => setStageName(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block mb-2">Supervisor Note:</label>
                <textarea
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={supervisorNote}
                  onChange={(e) => setSupervisorNote(e.target.value)}
                ></textarea>
              </div>
              <div className="mb-4">
                <label className="block mb-2">Estimate Time:</label>
                <div className="flex">
                  <input
                    type="number"
                    min="0"
                    max="23"
                    placeholder="HH"
                    className="border border-gray-300 rounded-md px-3 py-1 w-1/2 mr-2"
                    value={hours}
                    onChange={(e) => setHours(e.target.value)}
                  />
                  <span className="flex items-center">:</span>
                  <input
                    type="number"
                    min="0"
                    max="59"
                    placeholder="MM"
                    className="border border-gray-300 rounded-md px-3 py-1 w-1/2 ml-2"
                    value={minutes}
                    onChange={(e) => setMinutes(e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4">
                <label className="block mb-2">Supervisor:</label>
                <select
                  className="border border-gray-300 rounded-md px-3 py-1 w-full"
                  value={supervisor}
                  onChange={(e) => setSupervisor(e.target.value)}
                >
                  <option value="">Select Supervisor</option>
                  {/* Add options for supervisors */}
                </select>
              </div>
              <div className="flex justify-center">
                <button
                  onClick={() => {
                    // Add logic to handle form submission
                    console.log("Submitted!");
                    window.alert("Submitted!");
                    // Reset input fields and close popup
                    setStageName("");
                    setSupervisorNote("");
                    setEstimateTime("");
                    setSupervisor("");
                    setShowPopup(false);
                  }}
                  className="bg-[#99BC85] text-white px-4 py-2 rounded-md mr-2"
                >
                  Add Stage
                </button>
                <button
                  onClick={togglePopup}
                  className="bg-gray-300 px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {/* End Popup */}
      </div>
    </div>
  );
}

export default WarehouseStageData;
