/** @format */

import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline } from "react-icons/io";
import moment from "moment";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import axios from "axios";
import Notification from "../../../../components/notification/notification";

function Gate_pass_popup({ togglePopup }) {
  const currentDate = moment().format("YYYY-MM-DD");
  const [notification, setNotification] = useState({ message: "", type: "" });
  const [gatePassDetails, setGatepassDetails] = useState({
    schedule_id: "",
    applicant_name: "",
    designation: "",
    requested_date: "",
    driver_id: "",
    driver_1_name: "",
    driver_assistant_id_1: "",
    driver_assistant_1_name: "",
    driver_assistant_id_2: "",
    driver_assistant_2_name: "",
    department: "",
    departure_location: "",
    departure_point: "",
    destination_location: "",
    destination_points: "",
    stops: [],
    departure_date: "",
    departure_time: "",
    purpose_of_transport: "",
    vehicle_id: "",
    estimated_amount_of_fuel: "",
    estimated_fuel_cost: "",
    estimated_km: "",
    vehicle_no_plate_no: "",
    arrival_date: "",
    arrival_time: "",
  });
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [driverDetails, setDriverDetails] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const { REACT_APP_GOOGLE_MAPS_KEY } = process.env;
  const [driverMap, setDriverMap] = useState({});
  const [vehicleMap, setVehicleMap] = useState({});

  const handleInputChange = (e) => {
    const { name, value, type, checked, options } = e.target;

    if (type === "select-one") {
      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else if (type === "date" || type === "time") {
      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    } else {
      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        [name]: value,
      }));
    }
  };

  // Auto Generating Gate Pass ID
  const fetchLastGatePassID = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/dailyRoutesSchedule/getLastId` // Ensure the URL is correct
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json(); // Parse JSON from response
      const { lastGatePassId } = data; // Destructure from parsed JSON

      console.log("gateid: ", lastGatePassId);
      const newGatePassId = `GT${(parseInt(lastGatePassId.slice(2)) + 1)
        .toString()
        .padStart(3, "0")}`;

      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        schedule_id: newGatePassId,
      }));
    } catch (error) {
      console.error("Error fetching last Gate Pass ID:", error);
      // Optionally set an error message state here
    }
  };
  // Define a function to fetch Driver IDs
  // const fetchDriverDetails = async () => {
  //   try {
  //     const response = await fetch(
  //       `${backendUrl}/driver/getDriverByDate?date=${encodeURIComponent(
  //         currentDate
  //       )}`
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     console.log("driver details:", data);
  //     setDriverDetails(data);

  //     // Create a map of driver_id to driver details
  //     const map = data.reduce((acc, driver) => {
  //       acc[driver.driver_id] = driver;
  //       return acc;
  //     }, {});
  //     setDriverMap(map);
  //   } catch (error) {
  //     console.error("Error fetching driver details:", error);
  //   }
  // };

  // Define a function to fetch vehicle IDs
  // const fetchVehicleDetails = async () => {
  //   try {
  //     const response = await fetch(
  //       `${backendUrl}/vehicle/allVehiclesbyDate?date=${encodeURIComponent(
  //         currentDate
  //       )}`
  //     );

  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }

  //     const data = await response.json();
  //     console.log("vehcile details:", data);
  //     setVehicleDetails(data);

  //     // Create a map of driver_id to driver details
  //     const map = data.reduce((acc, vehicle) => {
  //       acc[vehicle.vehicle_id] = vehicle;
  //       return acc;
  //     }, {});
  //     setVehicleMap(map);
  //   } catch (error) {
  //     console.error("Error fetching vehicle details:", error);
  //   }
  // };



  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

  const handleOpenSaveConfirmation = () => {
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);
  };

  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleSaveForm = async (event) => {
    console.log(gatePassDetails);

    try {
      const response = await axios.post(
        `${backendUrl}/dailyRoutesSchedule`,
        gatePassDetails,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      // Show success notification
      setNotification({
        message: "Form submitted successfully!",
        type: "success",
      });
    } catch (error) {
      console.log(error);

      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = error.response.data.error;
        } else if (error.response.status === 400) {
          // Access first error message if available
          errorMessage =
            error.response.data[0]?.msg ||
            error.response.data.error ||
            errorMessage;
        }
      }

      // Show error notification
      setNotification({
        message: errorMessage,
        type: "error",
      });
    }
    setIsSaveConfirmationOpen(false);
  };

  const handleResetForm = () => {
    setGatepassDetails({
      // set proper data
      employeeNumber: "",
      driver_1_name: "",
      phoneNumber: "",
      emailAddress: "",
      licenseNumber: "",
      driver_id: "",
    });
    setIsResetConfirmationOpen(false);
  };

  function addStop() {
    setGatepassDetails((prevDetails) => ({
      ...prevDetails,
      stops: [...prevDetails.stops, { stops: "", stops_points: "" }], // Add a new blank stop
    }));
  }

  const removeStop = (index) => {
    setGatepassDetails((prevDetails) => {
      const updatedStops = prevDetails.stops.filter((_, i) => i !== index);
      return {
        ...prevDetails,
        stops: updatedStops, // Remove the stop at the given index
      };
    });
  };

  const handleDriverIDChange = (selectedValue) => {
    const driver = driverMap[selectedValue];
    if (driver) {
      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        driver_id: driver.driver_id,
        driver_1_name: driver.employee_fullname,
      }));
    }
  };
  const handleVehicleIDChange = (selectedValue) => {
    const vehicle = vehicleMap[selectedValue];
    if (vehicle) {
      setGatepassDetails((prevDetails) => ({
        ...prevDetails,
        vehicle_id: vehicle.vehicle_id,
        vehicle_no_plate_no: vehicle.vehicle_no_plate_no,
      }));
    }
  };

  const handleDepartureLocationChange = (fieldName, place) => {
    console.log(place);
    if (place && place.value && place.value.place_id) {
      const placeId = place.value.place_id;

      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId: placeId }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const latitude = result.geometry.location.lat();
          const longitude = result.geometry.location.lng();

          console.log("Latitude:", latitude, "Longitude:", longitude);

          setGatepassDetails((prevDetails) => ({
            ...prevDetails,
            departure_location: place.label, // store the place label
            departure_point: `${latitude}, ${longitude}`, // store latitude and longitude
          }));
        }
      });
    }
  };

  const handleDestinationLocationChange = (fieldName, place) => {
    console.log(place);
    if (place && place.value && place.value.place_id) {
      const placeId = place.value.place_id;

      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId: placeId }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const latitude = result.geometry.location.lat();
          const longitude = result.geometry.location.lng();

          console.log("Latitude:", latitude, "Longitude:", longitude);

          setGatepassDetails((prevDetails) => ({
            ...prevDetails,
            destination_location: place.label, // store the place label
            destination_points: `${latitude}, ${longitude}`, // store latitude and longitude
          }));
        }
      });
    }
  };

  // Function to handle stop change
  const handleStopChange = (index, place) => {
    if (place && place.value && place.value.place_id) {
      const placeId = place.value.place_id;
      const service = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      service.getDetails({ placeId: placeId }, (result, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const latitude = result.geometry.location.lat();
          const longitude = result.geometry.location.lng();

          const updatedStop = {
            stops: place.label, // Save the new place label
            stops_points: `${latitude}, ${longitude}`, // Save the new coordinates
          };

          setGatepassDetails((prevDetails) => {
            const updatedStops = [...prevDetails.stops];
            updatedStops[index] = updatedStop; // Update the specific stop
            return {
              ...prevDetails,
              stops: updatedStops, // Keep the rest of the stops intact
            };
          });
        }
      });
    }
  };

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };


  const fetchVehicleDetails = async () => {
    try {
      const params = {
        departure_date: gatePassDetails.departure_date,
      arrival_date: gatePassDetails.arrival_date,
   
      };
  
      const response = await axios.get(
        `${backendUrl}/vehicle/allVehiclesbyDate`,
        {
          params
        }
      );
  
      console.log(params);
  
      // Axios handles JSON parsing automatically
      const data = response.data;
      console.log("vehicle details:", data);
      setVehicleDetails(data);
  
      // Create a map of vehicle_id to vehicle details
      const map = data.reduce((acc, vehicle) => {
        acc[vehicle.vehicle_id] = vehicle;
        return acc;
      }, {});
      setVehicleMap(map);
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };
  

  const fetchDriverDetails = async () => {
    const params = {
      departure_date: gatePassDetails.departure_date,
      arrival_date: gatePassDetails.arrival_date,
      
      
    };
  
    try {
      const response = await axios.get(`${backendUrl}/driver/getDriverByDate`, {
        params, // Pass params in the Axios config
      });
  
      console.log(params);
      console.log(response.data);
  
      // No need for response.ok, Axios returns an error for non-2xx status codes
      if (response.status !== 200) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = response.data; // Axios automatically parses JSON response
      console.log("driver details:", data);
      setDriverDetails(data);
  
      // Create a map of driver_id to driver details
      const map = data.reduce((acc, driver) => {
        acc[driver.driver_id] = driver;
        return acc;
      }, {});
      setDriverMap(map);
    } catch (error) {
      console.error("Error fetching driver details:", error);
    }
  };
  



  useEffect(() => {
    fetchLastGatePassID();
    // fetchDriverDetails();
    // fetchVehicleDetails();
  }, []);

  useEffect(() => {
    fetchDriverDetails();
    fetchVehicleDetails();
  }, [gatePassDetails.departure_date, gatePassDetails.arrival_date])


  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[90%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Create Gate Pass Request</h2>
          <button
            type="button"
            onClick={togglePopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
        <form>
          <div className="items-center gap-8 mt-5">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/* Gate Pass ID* */}
              <div className="relative">
                <label className="block text-gray-700">Gate Pass ID*</label>
                <input
                  type="text"
                  name="employeeNumber"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.schedule_id}
                  disabled
                />
                {showSuggestions && filteredEmployees.length > 0 && (
                  <ul className="absolute z-10 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-lg shadow-lg max-h-40">
                    {filteredEmployees.map((suggestion, index) => (
                      <li
                        key={index}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        // onClick={() => handleSuggestionClick(suggestion)}
                      >
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* Applicant’s Name */}
              <div>
                <label className="block text-gray-700">Applicant’s Name*</label>
                <input
                  type="text"
                  name="applicant_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.applicant_name}
                  onChange={handleInputChange}
                />
              </div>

              {/* Designation* */}
              <div>
                <label className="block text-gray-700">Designation*</label>
                <input
                  type="text"
                  name="designation"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.designation}
                  onChange={handleInputChange}
                />
              </div>

              {/* Department */}
              <div>
                <label className="block text-gray-700">Department*</label>
                <select
                  name="department"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.department}
                  onChange={handleInputChange} // Handle change event
                >
                  <option value="" disabled>
                    Select the department
                  </option>
                  <option value="HR">HR</option>
                  <option value="Finance">Finance</option>
                  <option value="Sales">Sales</option>
                  <option value="Packing Section">Packing Section</option>
                  <option value="Production">Production</option>
                </select>
              </div>

              {/* Requested Date* */}
              <div>
                <label className="block text-gray-700">Requested Date*</label>
                <input
                  type="date"
                  name="requested_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.requested_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Date */}
              <div>
                <label className="block text-gray-700">Departure Date*</label>
                <input
                  type="date"
                  name="departure_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.departure_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Time  // include time picker for this */}
              <div>
                <label className="block text-gray-700">Departure Time*</label>
                <input
                  type="time"
                  name="departure_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.departure_time}
                  onChange={handleInputChange}
                />
              </div>

              {/* Departure Location :- include location for this */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Departure Location
                </label>
                <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY} // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) =>
                      handleDepartureLocationChange(
                        "departure_location",
                        place
                      ),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

              {/* Arrival Date */}
              <div>
                <label className="block text-gray-700">Arrival Date*</label>
                <input
                  type="date"
                  name="arrival_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.arrival_date}
                  onChange={handleInputChange}
                />
              </div>

              {/* Arrival Time */}
              <div>
                <label className="block text-gray-700">Arrival Time*</label>
                <input
                  type="time"
                  name="arrival_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.arrival_time}
                  onChange={handleInputChange}
                />
              </div>

              {/* Estimated amount of Fuel liters */}
              <div>
                <label className="block text-gray-700">
                  Estimated amount of Fuel liters*
                </label>
                <input
                  type="number"
                  name="estimated_amount_of_fuel"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.estimated_amount_of_fuel}
                  onChange={handleInputChange}
                />
              </div>

              {/* Estimated cost for Fuel */}
              <div>
                <label className="block text-gray-700">
                  Estimated cost for Fuel (LKR)*
                </label>
                <input
                  type="number"
                  name="estimated_fuel_cost"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.estimated_fuel_cost}
                  onChange={handleInputChange}
                />
              </div>

              {/* Purpose of Transport */}
              <div>
                <label className="block text-gray-700">
                  Purpose of Transport*
                </label>
                <input
                  type="text"
                  name="purpose_of_transport"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.purpose_of_transport}
                  onChange={handleInputChange}
                />
              </div>

              {/* Estimated KM */}
              <div>
                <label className="block text-gray-700">Estimated KM*</label>
                <input
                  type="number"
                  name="estimated_km"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.estimated_km}
                  onChange={handleInputChange}
                />
              </div>

              {/* Stops */}
              {gatePassDetails.stops.map((stop, index) => (
                <div key={index} className="relative mb-4">
                  <div className="flex items-center">
                    <label className="block text-gray-700 mb-2 mr-2">
                      Stop {index + 1}
                    </label>
                    <button
                      type="button"
                      className="absolute top-0 right-0 mt-2 mr-2 text-red-600 hover:text-red-800 font-semibold"
                      onClick={() => removeStop(index)}
                    >
                      <IoIosCloseCircleOutline />
                    </button>
                  </div>

                  <GooglePlacesAutocomplete
                    apiKey={REACT_APP_GOOGLE_MAPS_KEY}
                    selectProps={{
                      value: stop.stops
                        ? { label: stop.stops, value: stop.stops }
                        : null, // Show current value if available
                      onChange: (place) => handleStopChange(index, place),
                      styles: {
                        input: (provided) => ({
                          ...provided,
                        }),
                      },
                    }}
                  />
                </div>
              ))}

              {/* Destination Location */}
              <div>
                <label className="block text-gray-700 mb-2">
                  Destination Location
                </label>
                <GooglePlacesAutocomplete
                  apiKey={REACT_APP_GOOGLE_MAPS_KEY} // Replace with your Google Maps API key
                  selectProps={{
                    onChange: (place) =>
                      handleDestinationLocationChange(
                        "destination_location",
                        place
                      ),
                    styles: {
                      input: (provided) => ({
                        ...provided,
                      }),
                    },
                  }}
                />
              </div>

              {/* Add More Stops Button */}
              <button
                type="button"
                className="px-4 py-2 w-[200px] h-[50px] mt-8 text-white bg-green-600 border border-black rounded-xl"
                onClick={() => addStop()}
              >
                Add more Stops
              </button>
            </div>
            <hr className="my-8 border-t border-gray-300 w-[80%] mx-auto" />
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px]">
              {/*  Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID*</label>

                <select
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.vehicle_id}
                  onChange={(e) => handleVehicleIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the vehicle
                  </option>
                  {vehicleDetails.map((items, index) => (
                    <option key={index} value={items.vehicle_id}>
                      {items.vehicle_id} - {items.vehicle_no_plate_no}
                    </option>
                  ))}
                </select>
              </div>

              {/* Driver ID */}
              <div>
                <label className="block text-gray-700">Driver ID*</label>

                <select
                  name="driver_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_id}
                  onChange={(e) => handleDriverIDChange(e.target.value)} // Handle change event
                >
                  <option value="" disabled>
                    Select the driver
                  </option>
                  {driverDetails.map((items, index) => (
                    <option key={index} value={items.driver_id}>
                      {items.driver_id} - {items.employee_fullname}
                    </option>
                  ))}
                </select>
              </div>

              {/* Driver Name */}
              <div>
                <label className="block text-gray-700">Driver Name*</label>
                <input
                  type="text"
                  name="licenseNumber"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_1_name}
                  disabled
                />
              </div>

              {/* Vehicle Number Plate */}
              <div>
                <label className="block text-gray-700">
                  Vehicle Number Plate*
                </label>

                <input
                  type="text"
                  name="vehicle_no_plate_no"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.vehicle_no_plate_no}
                  disabled
                />
              </div>

              {/* ID of Driver Assistant 01 */}
              <div>
                <label className="block text-gray-700">
                  ID of Driver Assistant 01
                </label>
                <input
                  type="text"
                  name="driver_assistant_id_1"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_assistant_id_1}
                  onChange={handleInputChange}
                />
              </div>

              {/* ID of Driver Assistant 02 */}
              <div>
                <label className="block text-gray-700">
                  ID of Driver Assistant 02
                </label>
                <input
                  type="text"
                  name="driver_assistant_id_2"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_assistant_id_2}
                  onChange={handleInputChange}
                />
              </div>

              {/* Name of Driver Assistant 01 */}
              <div>
                <label className="block text-gray-700">
                  Name of Driver Assistant 01*
                </label>
                <input
                  type="text"
                  name="driver_assistant_1_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_assistant_1_name}
                  onChange={handleInputChange}
                />
              </div>

              {/* Name of Driver Assistant 02 */}
              <div>
                <label className="block text-gray-700">
                  Name of Driver Assistant 02*
                </label>

                <input
                  type="text"
                  name="driver_assistant_2_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={gatePassDetails.driver_assistant_2_name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={(e) => handleSaveForm(e)}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Gate_pass_popup;
