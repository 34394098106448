import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Approval_Overtime = () => {
    const [overtimeData, setOvertimeData] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search input
    const [selectedOvertime, setSelectedOvertime] = useState(null); // State for selected overtime
    const [showModal, setShowModal] = useState(false); // State for modal visibility

    useEffect(() => {
        // Fetch overtime data on component mount
        axios.get('https://back-ayurveda.erp-quortech.com/v1/hris/Overtime/overtime')
            .then((response) => {
                setOvertimeData(response.data);
            })
            .catch((error) => {
                console.error('Error fetching overtime data:', error);
            });
    }, []);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleReset = () => {
        setSearchQuery('');
    };

    const handleViewClick = (overtime) => {
        setSelectedOvertime(overtime); // Set the selected overtime data
        setShowModal(true); // Show the modal
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedOvertime(null);
    };

    // Filter overtime data based on search query
    const filteredData = overtimeData.filter((data) =>
        data.name_of_work?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        data.overtime_id.toString().includes(searchQuery)
    );

    return (
        <div className='bg-background overflow-y-auto h-screen'>
            <div className='mt-5 mx-5'>
                <p className='text-[20px] font-semibold'>Overtime (Certify Step)</p>

                <div className="flex items-center gap-5">
                    <div className="ml-6 mt-3">
                        <input
                            className="w-[480px] h-[55px] rounded-[22px] border px-4"
                            placeholder="Search By Employee Code"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div>
                        <button
                            onClick={handleReset}
                            className="text-headerColor font-bold"
                        >
                            Reset
                        </button>
                    </div>
                </div>

                <div>
                    <div className="mx-5 my-5">
                        <table className="min-w-[80%] bg-white border border-gray-200">
                            <thead>
                                <tr className="bg-gray-100 border-b">
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">View Overtime Form</th>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredData.map((overtime) => (
                                    <tr key={overtime.overtime_id} className="border-b hover:bg-gray-50">
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            {new Date(overtime.date).toLocaleDateString()}
                                        </td>
                                        <td
                                            className="px-6 py-4 whitespace-nowrap text-sm text-blue-600 hover:underline cursor-pointer"
                                            onClick={() => handleViewClick(overtime)}
                                        >
                                            Click Here
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                            <div className='flex items-center gap-4'>
                                                <button className="text-white bg-primary p-2 rounded-md">Approve</button>
                                                <button className="text-white bg-primary p-2 rounded-md">Reject</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {filteredData.length === 0 && (
                                    <tr>
                                        <td colSpan="3" className="px-6 py-4 text-center text-gray-500">No overtime data found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Modal for Viewing Overtime Form */}
            {showModal && selectedOvertime && (
                <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white w-[80%] h-[90%] p-5 rounded-lg shadow-lg overflow-y-auto">
                        <div className="mx-8 my-5">
                            <p className="text-[30px] font-semibold">Overtime Assigning</p>
                            <div className="bg-white p-5 rounded-lg shadow-lg mt-8">
                                <p className="text-[20px] font-semibold">Assignment Form</p>
                                <div className="mt-8 text-[20px] grid grid-cols-3 grid-flow-row gap-4">
                                    <div className="flex items-center gap-4">
                                        <label>Date :</label>
                                        <input
                                            type="date"
                                            name="date"
                                            className="border border-black rounded-lg p-1"
                                            value={new Date(selectedOvertime.date).toISOString().slice(0, 10)}
                                            readOnly
                                        />
                                    </div>

                                    <div className="flex items-center gap-4">
                                        <label>Name of work :</label>
                                        <input
                                            name="name_of_work"
                                            className="border border-black rounded-lg p-1"
                                            value={selectedOvertime.name_of_work || ''}
                                            readOnly
                                        />
                                    </div>

                                    <div className="flex items-center gap-4">
                                        <label>Reason :</label>
                                        <input
                                            name="reason"
                                            className="border border-black rounded-lg p-1"
                                            value={selectedOvertime.reason || ''}
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <label>Start Time :</label>
                                        <input
                                            type="time"
                                            name="start_time"
                                            className="border border-black rounded-lg p-1"
                                            value={selectedOvertime.start_time}
                                            readOnly
                                        />
                                    </div>
                                    <div className="flex items-center gap-4">
                                        <label>End Time :</label>
                                        <input
                                            type="time"
                                            name="end_time"
                                            className="border border-black rounded-lg p-1"
                                            value={selectedOvertime.end_time}
                                            readOnly
                                        />
                                    </div>
                                </div>

                                {/* Selected Employees Table */}
                                <div className="mt-6">
                                    <h3 className="text-xl font-bold text-[#344054] mb-4">Selected Employees</h3>
                                    <div className="overflow-y-auto max-h-64">
                                        <table className="table-auto w-full">
                                            <thead>
                                                <tr>
                                                    <th className="px-4 py-2 bg-[#F5F5F5] rounded-l-xl">Employee ID</th>
                                                    <th className="px-4 py-2 bg-[#F5F5F5]">Employee Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedOvertime.employees?.map((employee) => (
                                                    <tr key={employee.employee_no} className="border-b border-gray-300">
                                                        <td className="px-4 py-2">{employee.employee_no}</td>
                                                        <td className="px-4 py-2">{employee.employee_name_initial}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="flex justify-end mt-4">
                                    <button
                                        onClick={closeModal}
                                        className="bg-[#797C80] text-white px-4 py-2 rounded-[22px]"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Approval_Overtime;
