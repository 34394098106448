/** @format */

import React, { useState, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import usePermissions from "../../../components/permissions/permission";
const CreatedRecipe = () => {
  const { hasPermission } = usePermissions();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRecipe, setSelectedRecipe] = useState(null); // State to track the selected recipe
  const itemsPerPage = 10;
  const [recipes, setRecipes] = useState([]);
  const { REACT_APP_API_ENDPOINT } = process.env;
  console.log("Selected Recipe", selectedRecipe);
  useEffect(() => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getAccordingtoStatus?status=APPROVE`
    )
      .then((response) => response.json())
      .then((data) => {
        // Extracting only required fields
        const extractedRecipes = data.map((recipe) => ({
          createdBy: recipe.recipe_dr,
          name: recipe.recipe_name,
          status: recipe.recipe_status,
          recipeId: recipe.recipe_id,
        }));
        setRecipes(extractedRecipes);
        console.log("Data:", extractedRecipes);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  }, []);
  //gt
  const totalPages = Math.ceil(recipes.length / itemsPerPage);
  const maxPageNumbers = 5;
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }
    return pageNumbers;
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  // Filter data based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = recipes.slice(startIndex, endIndex);
  const handleRecipeClick = (recipe) => {
    console.log("Recipe ID", recipe.recipeId);
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/recipe/getrecipeAccordingtoId?recipe_id=${recipe.recipeId}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Fetched Recipe Data:", data);
        setSelectedRecipe(data);
      })
      .catch((error) => {
        console.error("Error fetching recipe details:", error);
      });
  };
  // Function to close the popup
  const handleClosePopup = () => {
    setSelectedRecipe(null);
  };
  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Approved Recipes
      </p>
      <div className="flex mt-10">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Recipe Name
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[520px] text-center">
                Created By
              </th>
              <th className="border border-gray-200 px-10 py-4 w-[40px] text-center">
                Actions
              </th>
              {/* li */}
            </tr>
          </thead>
          <tbody>
            {filteredData.map((recipe, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{recipe.name}</td>
                <td className="text-center">{recipe.createdBy}</td>
                <td className="text-center">
                  <button
                    onClick={() => handleRecipeClick(recipe)}
                    className="text-gray-400 hover:text-gray-800 focus:outline-none"
                  >
                    <FaEye />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination */}
      <div className="flex justify-center mt-4">
        <button
          onClick={handlePrevious}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === 1}
        >
          {"<"}
        </button>
        {renderPageNumbers()}
        <button
          onClick={handleNext}
          className="px-3 py-1 rounded-md mr-2 bg-gray-200 cursor-pointer"
          disabled={currentPage === totalPages}
        >
          {">"}
        </button>
      </div>
      {/* End Pagination */}
      {selectedRecipe && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center ">
          <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[400px]">
            <button
              onClick={handleClosePopup}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4">Recipe Details</h2>
            <p className="text-lg">Status: {selectedRecipe.status}</p>
            {selectedRecipe && selectedRecipe.length > 0 && (
              <div className="fixed inset-0 z-50 overflow-auto bg-gray-800 bg-opacity-75 flex justify-center items-center">
                <div className="relative bg-white p-8 rounded-lg max-w-md overflow-y-auto h-[400px]">
                  <button
                    onClick={handleClosePopup}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                  <h2 className="text-2xl font-bold mb-4">Recipe Details</h2>
                  {selectedRecipe[0].raw_item && (
                    <div>
                      <p className="text-lg">Raw Items:</p>
                      <ul>
                        {selectedRecipe[0].raw_item.map((item, index) => (
                          <li key={index}>
                            <p className="font-bold">
                              Item Name: {item.raw_item_name}
                            </p>
                            <p>Quantity: {item.raw_item_quantity}</p>
                            <p>Unit: {item.raw_item_unit}</p>
                            {item.alternative_item && (
                              <div>
                                <p className="text-lg">Alternative Items:</p>
                                <ul>
                                  {item.alternative_item.map(
                                    (alternative, altIndex) => (
                                      <li
                                        key={altIndex}
                                        className="bg-yellow-200 p-2 rounded-md mb-2"
                                      >
                                        <p className="font-bold">
                                          Alternative Item Name:{" "}
                                          {alternative.raw_item_name}
                                        </p>
                                        <p>
                                          Quantity:{" "}
                                          {alternative.raw_item_quantity}
                                        </p>
                                        <p>Unit: {alternative.raw_item_unit}</p>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
export default CreatedRecipe;
