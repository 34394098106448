/** @format */

import React, { useState, useEffect } from "react";
import usePermissions from "../../../components/permissions/permission";
import Cookies from "js-cookie";
import axios from "axios";

function ConfirmationDialog({
  message,
  onConfirm,
  onCancel,
  selling_price,
  cost_price,
  handleSelling_price,
  handleCost_price,
  file,
  handleFileInputChange,
  handleFileDrop,
  removeFile,
}) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="bg-white p-4 rounded-md shadow-lg w-[80%]">
        <p className="mb-4">{message}</p>

        {/* Selling Price Input */}
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Product Selling Price
        </label>
        <input
          type="number"
          value={selling_price}
          onChange={handleSelling_price}
          step="0.01"
          min="0"
          placeholder="Enter product selling price"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />

        {/* Cost Price Input */}
        <label className="block text-gray-700 text-sm font-bold mb-2">
          Product Cost Price
        </label>
        <input
          type="number"
          value={cost_price}
          onChange={handleCost_price}
          step="0.01"
          min="0"
          placeholder="Enter product cost price"
          className="border border-gray-300 rounded-md px-2 py-1 mb-2 w-full"
        />

        {/* File Upload Section */}
        <div className="mt-5">
          <header
            className="border-dashed border-2 bg-gray-50 border-gray-400 py-12 flex flex-col justify-center items-center rounded-md"
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
          >
            <p className="mb-3 font-semibold text-gray-900">
              Drag & drop PDF file here or
            </p>
            <input
              id="hidden-input"
              type="file"
              className="hidden"
              onChange={handleFileInputChange}
            />
            <button
              className="mt-2 px-3 py-1 bg-white rounded-md hover:bg-gray-300"
              onClick={() => document.getElementById("hidden-input").click()}
            >
              Browse Files
            </button>
          </header>

          {/* Display uploaded file */}
          {file && (
            <div className="mt-4">
              <h1 className="font-semibold text-gray-900">Uploaded File</h1>
              <div className="flex justify-between items-center mt-2">
                <span className="text-gray-900">{file.name}</span>
                <span className="text-gray-700 text-sm">
                  {file.size > 1024
                    ? file.size > 1048576
                      ? Math.round(file.size / 1048576) + " MB"
                      : Math.round(file.size / 1024) + " KB"
                    : file.size + " B"}
                </span>
                <button
                  onClick={removeFile}
                  className="text-red-500 text-sm ml-4"
                >
                  Remove
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Confirmation Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={onConfirm}
            className="bg-[#D4E7C5] text-black px-4 py-2 rounded-md mr-2"
          >
            Add
          </button>
          <button
            onClick={onCancel}
            className="bg-gray-300 px-4 py-2 rounded-md"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

const WarehousePending = () => {
  const { hasPermission } = usePermissions();
  const userId = Cookies.get("employee_no");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [recipes, setRecipes] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [rejectReason, setRejectReason] = useState("");
  const [selectedRecipeIndex, setSelectedRecipeIndex] = useState(null); // Track the index of the selected recipe
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [productCostPrice, setCostPrice] = useState("");
  const [productSellingPrice, setSellingPrice] = useState("");
  const [file, setFile] = useState(null); // Track uploaded file

  const { REACT_APP_API_ENDPOINT } = process.env;

  const fetchDefaultData = async () => {
    fetch(
      `${REACT_APP_API_ENDPOINT}/v1/production/finalProduct/finalProductLevelbystatus?product_approve_status=PENDING`
    )
      .then((response) => response.json())
      .then((data) => {
        const extractedRecipes = data.map((recipe) => ({
          id: recipe._id,
          productName: recipe.product_name,
          productCreateBy: recipe.product_create_by,
          status: recipe.product_approve_status,
          productCost: recipe.product_cost,
          packing_cost: recipe.packing_cost,
          laber_cost: recipe.laber_cost,
          raw_material_cost: recipe.raw_material_cost,
          laber_hours: recipe.laber_hours,
          productUnitQuantity: recipe.product_unit_quantity,
          productMeasureUnitSymbol: recipe.product_measure_unit_symbol,
          productFinalQuantity: recipe.product_final_quantity,
          productFinalUnitSymbol: recipe.product_final_unit_symbol,
          productCreateAt: recipe.product_create_at,
        }));
        setRecipes(extractedRecipes);
      })
      .catch((error) => {
        console.error("Error fetching recipes:", error);
      });
  };

  useEffect(() => {
    fetchDefaultData();
  }, []);

  const handleApproveConfirmation = async () => {
    try {
      const recipeId = recipes[selectedRecipeIndex].id;
      const formData = new FormData();

      // Add text and file data to the FormData object
      formData.append("_id", recipeId);
      formData.append("product_selling_price", productSellingPrice);
      formData.append("product_cost_price", productCostPrice);
      if (file) {
        formData.append("file", file);
      }

      // Send the POST request with the formData
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/production/warehouse/enter-cost-for-product`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        console.log("Product Price added successfully");
        alert("Product Price added successfully");
        // Reset the values
        setSellingPrice(""); // Reset selling price
        setCostPrice(""); // Reset cost price
        setFile(null); // Reset file
        setShowApproveConfirmation(false);
        fetchDefaultData();
      } else {
        console.error("Failed to add Product Price successfully");
      }
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Product Price successfully");
    }
  };

  const totalPages = Math.ceil(recipes.length / itemsPerPage);
  const maxPageNumbers = 5;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
    const endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded-md mr-2 ${
            i === currentPage ? "bg-gray-300" : "bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const filteredData = recipes.slice(startIndex, endIndex);

  const handleFileInputChange = (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      setFile(uploadedFile);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const uploadedFile = e.dataTransfer.files[0];
    if (uploadedFile && uploadedFile.type === "application/pdf") {
      setFile(uploadedFile);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  return (
    <div className="overflow-y-auto h-screen bg-white">
      <p className="text-[32px] ml-6 mt-5 bg-[#E1F0DA] w-[100%] rounded-xl">
        Pending Productions At Warehouse For Pricing
      </p>
      <div className="flex mt-10 overflow-x-auto">
        <table className="border-collapse border border-gray-200 rounded-lg ml-6">
          <thead>
            <tr className="bg-[#99BC85]">
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Product Name{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Product Create By{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Status
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Product Cost (LKR)
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Packing Cost (LKR)
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Labour Cost (LKR)
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Raw Material Cost (LKR)
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                Labour Hours (H)
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_unit_quantity{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_measure_unit_symbol{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_final_quantity{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_final_unit_symbol{" "}
              </th>
              <th className="border border-gray-200 px-10 py-4 w-80 text-center">
                product_create_at{" "}
              </th>
            </tr>
          </thead>

          <tbody>
            {filteredData.map((recipe, index) => (
              <tr key={index} className="border border-gray-200 px-4 py-2">
                <td className="text-center">{recipe.productName}</td>
                <td className="text-center">{recipe.productCreateBy}</td>
                <td>
                  {hasPermission(2510) && (
                    <button
                      className="px-4 py-2 bg-[#D4E7C5] text-black rounded-md"
                      onClick={() => {
                        setSelectedRecipeIndex(index);
                        setShowApproveConfirmation(true);
                      }}
                    >
                      Add Pricing
                    </button>
                  )}
                </td>
                <td className="text-center">{recipe.productCost}</td>
                <td className="text-center">{recipe.packing_cost}</td>
                <td className="text-center">{recipe.laber_cost}</td>
                <td className="text-center">{recipe.raw_material_cost}</td>
                <td className="text-center">{recipe.laber_hours}</td>
                <td className="text-center">{recipe.productUnitQuantity}</td>
                <td className="text-center">
                  {recipe.productMeasureUnitSymbol}
                </td>
                <td className="text-center">{recipe.productFinalQuantity}</td>
                <td className="text-center">{recipe.productFinalUnitSymbol}</td>
                <td className="text-center">
                  {" "}
                  {new Date(recipe.productCreateAt).toLocaleString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Approval Confirmation Dialog */}
      {showApproveConfirmation && (
        <ConfirmationDialog
          message="Add Pricing for the Product"
          onConfirm={handleApproveConfirmation}
          onCancel={() => setShowApproveConfirmation(false)}
          selling_price={productSellingPrice}
          cost_price={productCostPrice}
          handleSelling_price={(e) => setSellingPrice(e.target.value)}
          handleCost_price={(e) => setCostPrice(e.target.value)}
          file={file}
          handleFileInputChange={handleFileInputChange}
          handleFileDrop={handleFileDrop}
          removeFile={removeFile}
        />
      )}
    </div>
  );
};

export default WarehousePending;
