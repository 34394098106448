import React, { useState, useEffect } from "react";
import { FaCirclePlus } from "react-icons/fa6";

const ApplyLeave = ({ onSuccess }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [requestedDates, setRequestedDates] = useState([""]);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [empNo, setEmpNo] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [leaveType, setLeaveType] = useState("Annual");
  const [reason, setReason] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleRequestedDateChange = (index, event) => {
    const newDates = [...requestedDates];
    newDates[index] = event.target.value;
    setRequestedDates(newDates);
  };

  const addRequestedDateField = () => {
    setRequestedDates([...requestedDates, ""]);
  };

  useEffect(() => {
    async function fetchEmployeeList() { 
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/employees/getemployeebasicdetails`
        );
        const data = await response.json();
        setEmployeeList(data);
      } catch (error) {
        console.error("Failed to fetch employee list:", error);
      }
    }
    fetchEmployeeList();
  }, [REACT_APP_API_ENDPOINT]);

  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append("employee_no", empNo);
    // Add reason
    formData.append("leave_type", leaveType);
    formData.append("reason", reason);

    // Format requestedDates as a single string like "[2024-10-24,2024-10-25]"
    formData.append("requested_date", `[${requestedDates.join(",")}]`);

    if (selectedFile) {
      formData.append("file", selectedFile);
    }

    try {
      const response = await fetch(
        `${REACT_APP_API_ENDPOINT}/v1/hris/leave/addleave`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        setSubmissionStatus("Leave submitted successfully.");
        onSuccess && onSuccess(result);
        setIsOpen(false);
      } else {
        const errorData = await response.json();
        setSubmissionStatus(`Submission failed: ${errorData.message}`);
      }
    } catch (error) {
      console.error("Error submitting leave:", error);
      setSubmissionStatus("An error occurred while submitting leave.");
    }
  };

  const handleEmployeeSelect = (e) => {
    setEmpNo(e.target.value);
  };

  return (
    <div>
      <div className="p-[50px]">
        <div>
          <div className="bg-white p-5 w-[354px] rounded-xl relative">
            <p className="font-bold font-sans text-[#797C80] text-[32px] ">
              Apply <br />
              Leave
            </p>
            <div className="flex justify-between items-center mt-2">
              <button
                className="bg-primary p-2 w-[151px] font-sans font-semibold rounded-[50px] text-white mt-2"
                onClick={() => setIsOpen(true)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="fixed top-0 left-0 h-full flex items-center justify-center bg-black bg-opacity-50 z-50 w-full">
          <div className="bg-[#F3F8FF] p-6 rounded-[40px]">
            <div className="mb-6 items-center justify-center">
              <p className="font-sans text-[32px] font-bold text-center">
                Enter Leave Details
              </p>

              <div className="mt-5 flex flex-col">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-col">
                    <p className="font-sans font-semibold">Select Employee:</p>
                    <select
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans"
                      onChange={handleEmployeeSelect}
                      value={empNo}
                    >
                      <option value="">Select Employee</option>
                      {employeeList.map((employee) => (
                        <option
                          key={employee.employee_no}
                          value={employee.employee_no}
                        >
                          {employee.employee_name_initial}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="flex flex-col">
                    <p className="font-sans font-semibold">Employee ID:</p>
                    <input
                      className="w-[300px] h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans"
                      value={empNo}
                      readOnly
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-4">
                  <div>
                    {requestedDates.map((date, index) => (
                      <div
                        key={index}
                        className="flex flex-row items-center mt-4"
                      >
                        <div className="ml-[12%] flex items-center gap-3">
                          <p className="font-sans font-semibold">
                            Requested Date {index + 1}:
                          </p>
                          <FaCirclePlus
                            className="text-green-600 font-semibold cursor-pointer"
                            onClick={addRequestedDateField}
                          />
                        </div>
                        <input
                          type="date"
                          className="w-[300px] ml-6 h-10 rounded-[73px] py-1.5 bg-white px-2 font-sans"
                          value={date}
                          onChange={(e) => handleRequestedDateChange(index, e)}
                          min={new Date().toISOString().split("T")[0]}
                        />
                      </div>
                    ))}
                  </div>

                  <div>
                    <div className="flex flex-row items-center mt-4">
                      <p className="font-sans font-semibold">Reason :</p>
                      <textarea
                        className="w-[500px] ml-8 h-24 py-1.5 bg-white px-2 font-sans"
                        placeholder="Enter Reason"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4 flex flex-row items-center">
                  <p className="font-sans font-semibold ml-[12%]">Attachment</p>
                  <div className="ml-10">
                    <input
                      type="file"
                      className="h-10 rounded-[30px] border border-[#9CAC8B]-300 border-input bg-white text-sm text-gray-400 file:border-0 file:bg-[#9CAC8B] file:text-white file:text-sm file:font-medium file:h-10"
                      onChange={handleFileSelect}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-5 space-x-3">
              <button
                className="bg-primary p-2 rounded-[22px] text-white"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                className="bg-gray-500 p-2 rounded-[22px] text-white"
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </button>
            </div>
            {submissionStatus && (
              <p className="text-center mt-4">{submissionStatus}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplyLeave;
