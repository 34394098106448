/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usePermissions from "../../../components/permissions/permission";
import moment from "moment";
import Leave from "../../../Assets/icons/leave.png"
import Work from "../../../Assets/icons/work.png"
import Absense from "../../../Assets/icons/absence.png"
import Users from "../../../Assets/icons/users.png"
import Late from "../../../Assets/icons/late.png"


function Attendance() {
  const { hasPermission } = usePermissions();
  const [data, setData] = useState({
    totalWorkforce: 0,
    presentWorkforce: 0,
    absentWorkforce: 0,
    lateArrivals: 0,
    inLeave: 0,
  });
  const attendance = [
    {
      id: 1,
      header: "Checkin  ",
      header2: "Checkout Report ",
      headerClasses: "text-black  ",
      linkTo: "/in-and-out",
      permissionId: 1010,
    },
    {
      id: 2,
      header: "Summary",
      header2: "Report",
      headerClasses: "text-black",
      linkTo: "/summary-report",
      permissionId: 1012,
    },
    {
      id: 3,
      header: "Over Time",
      header2: "(OT)",
      headerClasses: "text-black",
      linkTo: "/over-time",
      permissionId: 1013,
    },
    {
      id: 4,
      header: "Absense ",
      header2: "Report",
      headerClasses: "text-black",
      linkTo: "/absense-report",
      permissionId: 1014,
    },
    {
      id: 4,
      header: "Time Table",
      header2: "Management",
      headerClasses: "text-black",
      linkTo: "/timetable_management",
      permissionId: 1014,
    },

    {
      id: 5,
      header: "Overtime ",
      header2: "Assigning",
      headerClasses: "text-black",
      linkTo: "/overtime-assigning-nav",
      permissionId: 1014,
    },
  ];

  const API_URL = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch attendance stats
        const response = await fetch(
          `${API_URL}/v1/hris/employees/getAttendanceStats`
        );
        const result = await response.json();

        if (result.success) {
          const { totalWorkforce, presentWorkforce, inLeave } = result.data;

          // Update the state with fetched data
          setData((prevData) => ({
            ...prevData,
            totalWorkforce,
            presentWorkforce,
            inLeave,
          }));
        } else {
          console.error(
            "Error fetching attendance stats:",
            result.error || result
          );
        }

        // Fetch absent workforce count with today's date as startDate and endDate
        const absentResponse = await fetch(
          `${API_URL}/v1/hris/attendence/getNotAttendCount?startDate=${today}&endDate=${today}`
        );
        const absentResult = await absentResponse.json();

        if (absentResult.not_attended_count !== undefined) {
          setData((prevData) => ({
            ...prevData,
            absentWorkforce: absentResult.not_attended_count,
          }));
        } else {
          console.error(
            "Error fetching absent workforce count:",
            absentResult.error || absentResult
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [API_URL]);

  useEffect(() => {
    const fetchLateInCount = async () => {
      try {
        const today = moment().format("YYYY-MM-DD");

        // Fetch late in count with today's date
        const response = await fetch(
          `${API_URL}/v1/hris/attendence/getLateInCount?date=${today}`
        );
        const result = await response.json();

        if (result.success) {
          const { late_in_count } = result;

          // Update the state with fetched late in count
          setData((prevData) => ({
            ...prevData,
            lateArrivals: late_in_count,
          }));
        } else {
          console.error(
            "Error fetching late in count:",
            result.error || result
          );
        }
      } catch (error) {
        console.error("Error fetching late in count:", error);
      }
    };
    fetchLateInCount();
  }, [API_URL]);

  return (
    <div className="bg-background overflow-y-auto h-screen">
      <p className="text-[40px] font-header ml-6 mt-6 font-bold text-[#797C80] ">
        Time & Attendance Management
      </p>

      <div className="mt-10 mx-8">
        <div className="grid grid-cols-5 grid-flow-rows gap-5">
          {/* card 1 */}
          
            <div className=" p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Users} className="w-[70px]" />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <p className="mt-5 font-semibold">Total Workforce</p>
                <p className="text-[50px] font-bold">{data.totalWorkforce}</p>
              </div>
            </div>
         
          {/* card 2 */}
         
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Work} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Present Workforce</p>
                <p className="text-[50px] font-bold">{data.presentWorkforce}</p>
              </div>
            </div>
         
          {/* card 3 */}
          
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Absense} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Absent Workforce</p>
                <p className="text-[50px] font-bold">{data.absentWorkforce}</p>
              </div>
            </div>
         
          {/* card 4 */}
         
            <div className="p-3 rounded-xl border border-gray-100  bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Late} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">Late Arrivals</p>
                <p className="text-[50px] font-bold">{data.lateArrivals}</p>
              </div>
            </div>
        
          {/* card 5 */}
          
            <div className="p-3 border border-gray-100 rounded-xl bg-white shadow-md">
              <div className="flex justify-between items-center mt-5">
                <div>
                  <img src={Leave} className="w-[70px]" />
                </div>
              </div>
              <div className="flex justify-between items-center">
                <p className="mt-5 font-semibold">In Leave</p>
                <p className="text-[50px] font-bold">{data.inLeave}</p>
              </div>
            </div>
         
        </div>
      </div>
      {/* Cards with "Click Here" buttons */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-12 mt-[2.5%] mx-6 font-sans ">
        {attendance.map((card) =>
          hasPermission(card.permissionId) ? (
            <Link key={card.id} to={card.linkTo}>
              <div
                key={card.id}
                className="bg-white rounded-[32px] h-[200px] w-[550px] p-4 shadow-md flex flex-col justify-between"
              >
                <div>
                  {/* Conditional rendering based on header words */}
                  <h2 className="text-[25px] font-bold mb-1 mt-1 ml-4 font-sans">
                    {card.header}{" "}
                  </h2>
                  <h2 className="text-[25px] font-bold mb-1 ml-4 font-sans">
                    {card.header2}{" "}
                  </h2>
                </div>
                <div className="flex justify-between items-center">
                  <button className="bg-primary w-[151px] mt-2 text-white font-bold py-2 px-4 rounded-[20px]">
                    Click Here
                  </button>

                  <div className="bg-primary w-[13px] h-[37px] rounded-xl"></div>
                </div>
              </div>
            </Link>
          ) : null
        )}
      </div>
    </div>
  );
}

export default Attendance;
