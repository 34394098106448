import React, { useState, useEffect } from "react";
import { IoIosCloseCircleOutline, IoIosCloudUpload } from "react-icons/io";
import axios from "axios";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Notification from "../../../components/notification/notification";


function Current_vehicle_dispatches_edit({ toggleEditPopup, dispatchesData }) {
  console.log(dispatchesData);
  const backendUrl = process.env.REACT_APP_VEHICLE_FLEET_BACKEND_URL;
  const [DispatchesEdit, setDispatchesEdit] = useState({
    vehicle_id: "",
    driver_name: "",
    departure_location: "",
    destination_location: "",
    departure_date: new Date().toISOString().slice(0, 10), // For date format 'YYYY-MM-DD'
    departure_time: "10:00",
    dispatch_id: "",
    status: "",
    schedule_id: "",
    arrived_date: new Date().toISOString().slice(0, 10),
    arrived_time: "", // Initializing time correctly
  });

  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isResetConfirmationOpen, setIsResetConfirmationOpen] = useState(false);
  const [isSaveConfirmationOpen, setIsSaveConfirmationOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]); // Define state for uploaded files
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleResetForm = () => {
    setDispatchesEdit({
      vehicle_id: "",
      driver_name: "",
      departure_location: "",
      destination_location: "",
      departure_date: new Date(),
      departure_time: "",
      dispatch_id: "",
      status: "",
      schedule_id: "",
      arrived_date: "",
      arrived_time: "",
    });
    setUploadedFiles([]);
    setIsResetConfirmationOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenResetConfirmation = () => {
    setIsResetConfirmationOpen(true);
  };

 


  const handleCloseResetConfirmation = () => {
    setIsResetConfirmationOpen(false);
  };

  const handleOpenSaveConfirmation = () => {
    setDispatchesEdit((prevDetails) => ({
      ...prevDetails,
      files: uploadedFiles,
    }));
    setIsSaveConfirmationOpen(true);
  };

  const handleCloseSaveConfirmation = () => {
    setIsSaveConfirmationOpen(false);  // Close the confirmation dialog
  };
  

  const handleSaveForm = async () => {
    try {
      // Prepare data with arrived_date and arrived_time
      const data = {
        arrived_date: DispatchesEdit?.arrived_date || "", // Default to empty string if undefined
        arrived_time: DispatchesEdit?.arrived_time || "", // Default to empty string if undefined
      };
  
      // Log data for debugging
      console.log("Data to be sent:", data);
  
      // Send PUT request with schedule_id in the query string
      const response = await axios.put(
        `${backendUrl}/dailyRoutesDispatch/releaseDispatch`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            schedule_id: DispatchesEdit?.schedule_id, // Ensure schedule_id is defined
          },
        }
      );
  
      // Handle successful response
      if (response.status === 200) {
        setNotification({
          message: "Updated the dispatch records successfully",
          type: "success",
        });
        console.log("Dispatch released successfully:", response.data);
  
        // Close the confirmation dialog after successful save
        handleCloseSaveConfirmation();
      } else {
        // Handle unexpected successful status codes
        console.warn("Unexpected response:", response);
        setNotification({
          message: "Unexpected response received",
          type: "warning",
        });
      }
    } catch (error) {
      // Log error details for debugging
      console.error("Error updating dispatch:", error.response?.data || error.message);
      
      // Display error notification
      setNotification({
        message: error.response?.data?.error || "Failed to update the dispatch",
        type: "error",
      });
  
      // Close the confirmation dialog even in case of error
      handleCloseSaveConfirmation();
    }
  };
  

  // Handle Notification Close
  const handleCloseNotification = () => {
    setNotification({ message: "", type: "" });
  };


  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDispatchesEdit((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };


  useEffect(() => {
    setDispatchesEdit(dispatchesData);
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
      <div className="w-[80%] h-[80%] overflow-y-auto p-8 bg-white rounded-lg">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold">Edit Current Vehicles Dispatch Details</h2>
          <button
            type="button"
            onClick={toggleEditPopup}
            className="p-4 text-4xl text-gray-500 transition duration-300 hover:text-gray-700"
          >
            <IoIosCloseCircleOutline />
          </button>
        </div>

        {/* Notification Alert */}
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />

        <form>
          <div className="items-center gap-8 mt-5 ">
            <div className="grid grid-cols-2 gap-y-[30px] gap-x-[60px] text-[20px] ">
              {/* Vehicle ID */}
              <div>
                <label className="block text-gray-700">Vehicle ID</label>
                <input
                  type="text"
                  name="vehicle_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.vehicle_id}
                  readOnly
                />
              </div>

              {/* Dispatch ID */}
              <div>
                <label className="block text-gray-700">Dispatch ID</label>
                <input
                  type="text"
                  name="schedule_id"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.schedule_id}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              {/* Driver Name */}
              <div>
                <label className="block text-gray-700">Driver Name</label>
                <input
                  type="text"
                  name="driver_name"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.driver_name}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              {/* Departure Location */}

              <div>
                <label className="block text-gray-700">
                  Departure Location
                </label>
                <input
                  type="text"
                  name="departure_location"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.departure_location}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              {/* <div>
                <label className="block text-gray-700">
                  Departure Location
                </label>
                <div className="mt-2">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyAhMWAvLOlSqUyR5mIayE0akgf62pwmZKY"
                    selectProps={{
                      onChange: (place) =>
                        handleLocationChange(place, "departure_location"), // Call the function with the correct key
                      value: { label: DispatchesEdit.departure_location }, // Set the current state as the value
                    }}
                  />
                </div>
              </div> */}

              {/* Destination Location */}

              <div>
                <label className="block text-gray-700">
                  Destination Location
                </label>
                <input
                  type="text"
                  name="destination_location"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.destination_location}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              {/* <div>
                <label className="block text-gray-700">
                  Destination Location
                </label>
                <div className="mt-2">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyAhMWAvLOlSqUyR5mIayE0akgf62pwmZKY"
                    selectProps={{
                      onChange: (place) =>
                        handleLocationChange(place, "destination_location"), // Call the function with the correct key
                      value: { label: DispatchesEdit.destination_location }, // Set the current state as the value
                    }}
                  />
                </div>
              </div> */}

              {/* Departure Date*  */}
              <div>
                <label className="block text-gray-700">Departure Date</label>
                <input
                  type="text"
                  name="departure_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.departure_date}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/* Departure Time  */}

              <div>
                <label className="block text-gray-700">Departure Time</label>
                <input
                  type="text"
                  name="departure_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.departure_time} // Ensures fallback
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/* Satus  */}
              <div>
                <label className="block text-gray-700">Status</label>
                <input
                  type="text"
                  name="status"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.status}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>

              {/* Arrival Time */}
              <div>
                <label className="block text-gray-700">Arrival Time</label>
                <input
                  type="time"
                  name="arrived_time"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.arrived_time} // Ensures fallback
                  onChange={handleInputChange}
                />
              </div>

              {/* Arrival Date */}
              <div>
                <label className="block text-gray-700">Arrival Date</label>
                <input
                  type="date"
                  name="arrived_date"
                  className="w-full p-2 mt-2 border border-gray-300 rounded"
                  value={DispatchesEdit.arrived_date || ""} // Fallback value for safety
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </form>

        <div className="mt-5">
          <div className="flex items-center justify-between px-4 py-2">
            <div></div>
            <div className="flex gap-4">
              <button
                type="button"
                className="px-4 py-2 text-green-600 bg-white border border-black rounded-xl"
                onClick={handleOpenResetConfirmation}
              >
                Reset
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-green-600 border border-black rounded-xl"
                onClick={handleOpenSaveConfirmation}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        {isResetConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Reset</h2>
              <p>
                Are you sure you want to reset the form? This action cannot be
                undone.
              </p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-red-500 rounded-xl"
                  onClick={handleResetForm}
                >
                  Yes, Reset
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseResetConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}

        {isSaveConfirmationOpen && (
          <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-gray-200 bg-opacity-80">
            <div className="p-8 bg-white rounded-lg">
              <h2 className="mb-4 text-xl font-bold">Confirm Save</h2>
              <p>Are you sure you want to save the form data?</p>
              <div className="flex justify-end gap-4 mt-6">
                <button
                  className="px-4 py-2 text-white bg-green-500 rounded-xl"
                  onClick={handleSaveForm}
                >
                  Yes, Save
                </button>
                <button
                  className="px-4 py-2 text-gray-600 bg-white border border-gray-300 rounded-xl"
                  onClick={handleCloseSaveConfirmation}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Current_vehicle_dispatches_edit;
