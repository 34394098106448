import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

const JournalEntryForm = ({ isOpen, togglePopup, onSubmit, initialData }) => {
    const entryTypes = [
        "Journal Entry",
        "Inter Company Journal Entry",
        "Bank Entry",
        "Cash Entry",
        "Credit Card Entry",
        "Debit Note",
        "Credit Note",
        "Contra Entry",
        "Excise Entry",
        "Write Off Entry",
        "Opening Entry",
        "Depreciation Entry",
        "Exchange Rate Revaluation",
        "Exchange Gain Or Loss",
        "Deferred Revenue",
        "Deferred Expense",
        "Utility Bill"
    ];

    const backendUrl = process.env.REACT_APP_BACKEND_URL_FINANCE;
    const paymentMethods = ["Cash", "Card", "Credit", "Cheque"];
    const [accounts, setAccounts] = useState([]);
    const [partyTypes, setPartyTypes] = useState([]);
    const [parties, setParties] = useState([]);

    const [totalDebit, setTotalDebit] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);

    const [formData, setFormData] = useState({
        entry_type: "",
        reference_number: "",
        reference_date: "",
        payment_method: "",
        description: "",
        draft_by: "",
        journal_entry_details: [
            { account_id: "", amount: "", type: "DEBIT", party_details: { party_type_id: null, party_id: null }, parties: [] },
        ],
    });

    useEffect(() => {
        if (initialData) {
            const { entry_date, status, ...cleanedData } = initialData;
            setFormData({
                ...cleanedData,
                draft_by: 'user-2342',
                journal_entry_details: initialData.journal_entry_details || [
                    { account_id: "", amount: "", type: "DEBIT", party_details: { party_type_id: null, party_id: null }, parties: [] },
                ],
            });
            recalculateTotals(initialData?.journal_entry_details);
        }
    }, [initialData]);

    useEffect(() => {
        console.log("effect calls")
        const fetchAccounts = async () => {
            try {
                const response = await axios.get(`${backendUrl}/account/getAllAccountsByFilters`);
                setAccounts(response.data.data);
                console.log("response.data", response.data.data)
            } catch (error) {
                console.error('Error fetching accounts:', error);
            }
        };

        fetchAccounts();
    }, []);

    // Fetch party types when component mounts
    useEffect(() => {
        const fetchPartyTypes = async () => {
            try {
                const response = await axios.get(`${backendUrl}/account/getAllPartyTypes`);
                setPartyTypes(response.data);
            } catch (error) {
                console.error('Error fetching party types:', error);
            }
        };
        fetchPartyTypes();
    }, []);

    // Fetch parties based on selected party type
    const fetchPartiesByType = async (partyTypeId, index) => {
        try {
            const responsePT = await axios.get(`${backendUrl}/journalEntry/getPartyTypeById/${partyTypeId}`);
            const response = await axios.get(`${backendUrl}/paymentEntry/getPartyByPartyType`, {
                params: { party_type: responsePT.data.party_type },
            });
            const updatedDetails = [...formData.journal_entry_details];
            updatedDetails[index].parties = response.data.data; // Update parties for the specific row
            setFormData((prevData) => ({ ...prevData, journal_entry_details: updatedDetails }));
            console.log("dgg",response.data.data)
        } catch (error) {
            console.error('Error fetching parties:', error);
        }
    };

    // Check if the selected account requires a party type
    const checkPartyTypeForAccount = async (accountId, index) => {
        try {
            const response = await axios.get(`${backendUrl}/journalEntry/getAssignedPartyTypeForSelectedAcc/${accountId}`);
            console.log("response", response.data)
            const isRequired = !!response.data?.party_type;

            // Update only the affected row
            const updatedDetails = [...formData.journal_entry_details];
            updatedDetails[index].isPartyTypeRequired = isRequired;
            if (!isRequired) {
                updatedDetails[index].party_details.party_type_id = null;
                updatedDetails[index].party_details.party_id = null;
                updatedDetails[index].parties = [];
            }
            setFormData((prevData) => ({ ...prevData, journal_entry_details: updatedDetails }));

        } catch (error) {
            console.error('Error checking party type for account:', error);
        }
    };

    // Handle party type change and trigger fetching of related parties
    const handlePartyTypeChange = (index, partyTypeId) => {
        const updatedDetails = [...formData.journal_entry_details];
        updatedDetails[index].party_details.party_type_id = partyTypeId;
        setFormData((prevData) => ({ ...prevData, journal_entry_details: updatedDetails }));
        fetchPartiesByType(partyTypeId, index);  // Fetch parties when a party type is selected
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEntryDetailChange = (index, field, value) => {
        const updatedDetails = [...formData.journal_entry_details];

        // Handle the party_details.party_id separately
        if (field === "party_details.party_id") {
            updatedDetails[index].party_details.party_id = value;
        } else {
            updatedDetails[index][field] = value;
        }

        setFormData((prevData) => ({ ...prevData, journal_entry_details: updatedDetails }));

        // Check for party type association when the account is selected
        if (field === "account_id") {
            checkPartyTypeForAccount(value, index);
        }
        if (field === "amount" || field === "type") {
            recalculateTotals(updatedDetails);
        }
    };

    const addEntryDetail = () => {
        setFormData((prevData) => ({
            ...prevData,
            journal_entry_details: [
                ...prevData.journal_entry_details,
                { account_id: "", amount: "", type: "DEBIT", party_details: { party_type_id: null, party_id: null }, parties: [] },
            ],
        }));
    };

    const removeEntryDetail = (index) => {
        const updatedDetails = formData.journal_entry_details.filter((_, i) => i !== index);
        setFormData((prevData) => ({ ...prevData, journal_entry_details: updatedDetails }));
        recalculateTotals(updatedDetails);
    };

    const recalculateTotals = (details) => {
        let debitTotal = 0;
        let creditTotal = 0;

        details.forEach(entry => {
            const amount = parseFloat(entry.amount) || 0;
            if (entry.type === "DEBIT") {
                debitTotal += amount;
            } else if (entry.type === "CREDIT") {
                creditTotal += amount;
            }
        });

        setTotalDebit(debitTotal);
        setTotalCredit(creditTotal);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Skip the validation if the entry type is "Opening Entry"
        if (formData.entry_type !== "Opening Entry" && totalDebit !== totalCredit) {
            alert("Debit and Credit totals must match!");
            return;
        }
        // Create a clean version of formData to remove unnecessary fields
        const cleanedFormData = {
            ...formData,
            journal_entry_details: formData.journal_entry_details.map((entry) => {
                const { isPartyTypeRequired, parties, ...cleanedEntry } = entry; // Remove redundant fields
                return cleanedEntry;
            }),
            draft_by: Cookies.get("employee_no")
        };

        onSubmit(cleanedFormData); // Send the cleaned data
        togglePopup();
    };

    return (
        isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-7xl h-auto max-h-screen overflow-y-auto">
                    <h2 className="text-2xl font-semibold mb-4">Journal Entry Form</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="mb-4">
                                <label className="block mb-1">Entry Type</label>
                                <select
                                    name="entry_type"
                                    value={formData.entry_type}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded"
                                    required
                                >
                                    <option value=""></option>
                                    {entryTypes.map((type) => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="mb-6">
                            <h3 className="text-lg font-semibold">Accounting Entries</h3>
                            {formData.journal_entry_details.map((entry, index) => (
                                <div key={index} className="flex items-center gap-4 mb-2">
                                    <select
                                        value={entry.account_id}
                                        onChange={(e) => handleEntryDetailChange(index, "account_id", e.target.value)}
                                        className="w-1/4 p-2 border rounded"
                                        required
                                    >
                                        <option value="">Select Account</option>
                                        {accounts.map((account) => (
                                            <option key={account.account_id} value={account.account_id}>
                                                {`${account.account_code} - ${account.account_name}`}
                                            </option>
                                        ))}
                                    </select>
                                    <select
                                        value={entry.party_details?.party_type_id}
                                        onChange={(e) => handlePartyTypeChange(index, e.target.value)}
                                        className="w-1/4 p-2 border rounded"
                                        disabled={!entry.isPartyTypeRequired || formData.entry_type === "Opening Entry"}
                                        required={entry.isPartyTypeRequired && formData.entry_type !== "Opening Entry"}
                                    >
                                        <option value="">Select Party Type</option>
                                        {partyTypes.map((partyType) => (
                                            <option key={partyType.id} value={partyType.id}>
                                                {partyType.party_type}
                                            </option>
                                        ))}
                                    </select>

                                    {/* Party Dropdown (depends on selected Party Type) */}
                                    <select
                                        value={entry.party_details?.party_id}
                                        onChange={(e) => handleEntryDetailChange(index, "party_details.party_id", e.target.value)}
                                        className="w-1/4 p-2 border rounded"
                                        disabled={!entry.isPartyTypeRequired || formData.entry_type === "Opening Entry"}
                                        required={entry.isPartyTypeRequired && formData.entry_type !== "Opening Entry"}
                                    >
                                        <option value="">Select Party</option>
                                        {(entry?.parties || []).map((party) => (
                                            <option key={party._id}
                                                    value={party?.customer_id ? party.customer_id : party.supplier_id}>
                                                {party?.customer_name ? party.customer_name : party.supplier_name}
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        type="number"
                                        placeholder="Amount"
                                        value={entry.amount}
                                        onChange={(e) => handleEntryDetailChange(index, "amount", e.target.value)}
                                        className="w-1/4 p-2 border rounded ml-2"
                                        required
                                        step="0.01"
                                        min="0.01"
                                    />
                                    <select
                                        value={entry.type}
                                        onChange={(e) => handleEntryDetailChange(index, "type", e.target.value)}
                                        className="w-1/4 p-2 border rounded ml-2"
                                    >
                                        <option value="DEBIT">DEBIT</option>
                                        <option value="CREDIT">CREDIT</option>
                                    </select>
                                    <button
                                        type="button"
                                        onClick={() => removeEntryDetail(index)}
                                        className="ml-2 text-red-500"
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))}
                            <button
                                type="button"
                                onClick={addEntryDetail}
                                className="text-blue-500"
                            >
                                Add Entry
                            </button>
                        </div>

                        <div className="flex justify-between items-center mb-4">
                            <div>
                                <h4 className="font-semibold">Total Debit: {totalDebit}</h4>
                                <h4 className="font-semibold">Total Credit: {totalCredit}</h4>
                            </div>

                        </div>
                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div className="mb-4">
                                <label className="block mb-1">Reference Number</label>
                                <input
                                    type="text"
                                    name="reference_number"
                                    value={formData.reference_number}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block mb-1">Reference Date</label>
                                <input
                                    type="date"
                                    name="reference_date"
                                    value={formData.reference_date}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded"
                                />
                            </div>
                        </div>

                        <div className="mt-4">
                            <label className="block mb-1">Description</label>
                            <textarea
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                className="w-full p-2 border rounded"
                                rows="3"
                            />
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-4">
                            <div>
                                <label className="block mb-1">Payment Method</label>
                                <select
                                    name="payment_method"
                                    value={formData.payment_method}
                                    onChange={handleChange}
                                    className="w-full p-2 border rounded"
                                >
                                    <option value=""></option>
                                    {paymentMethods.map((method) => (
                                        <option key={method} value={method}>
                                            {method}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="flex justify-end mt-4">
                            <button
                                type="button"
                                onClick={togglePopup}
                                className="px-4 py-2 mr-2 text-gray-600 border rounded"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                className="px-4 py-2 text-white bg-blue-600 rounded hover:bg-blue-700"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        )
    );
};

export default JournalEntryForm;
